.product-tags {

    .product-tag {
        display: inline-block;
        background: $white;
        color: $gray;
        border: 1px solid $gray;
        border-radius: 2px;
        font-size: .85em;
        padding: 4px 8px;
        margin: 0 3px 8px;
        font-weight: 400;
        transition: all 0.4s ease;

        &:after {
            content: "x";
            margin-left: 8px;
            font-size: .9em;
        }

        &:hover {
            border-color: $secondary;
            // background: $primary;
            color: $secondary;
        }
    }
}