$items: 6;

.product-related {
    background: $light-gray;

    .carousel {
        background: $white;
        display: flex;
        max-width: 290px;
        margin: auto;
        padding: 20px 0;
        border: 7px solid $black;
        box-shadow: -7px 11px $black;

        @include media-breakpoint-up(sm) {
            max-width: none;
        }
    }

    [class^="carousel-control"]:not(.carousel-control-icon) {
        position: static;
        width: auto;
        color: $black;
        padding: 0 7px;

        @media (pointer: coarse) { opacity: 1; }
    }

    .carousel-control-icon {
        width: 12px;
    }

    .carousel-inner {

        @media (pointer: fine) {

            &:hover {

                .carousel-inner-item {
                    opacity: 0.6;
                }
            }

            .carousel-inner-item {
                transition: opacity 0.4s ease;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}