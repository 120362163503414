$blue: #0066cc;
$blue2: #0858A8;
$darkblue: #032F70;
$darkblue2: #071935;
$pink: #ff0099;
$darkpink: #A01167;
$yellow: #FEE609;
$darkyellow: #CDBC1F;
$green: #25D366;
$darkgreen: #42B838;
$red: #FF8989;
$darkred: #FF3636;
$darkred2: #D81A1A;
$magenta-new: #D54AB7;
$white: #fff;
$blue: #2B6DCA;
$magenta: #D54AB7;
$gray: #B0B0B0;
$lightgray: #F2F2F2;
$darkgray: #4D4D4D;
$yellow: #FCEE21;
$red: #FC214E;
// $green: #25F278;
$black: #000;
$white: #FFF;

@font-face { unicode-range: U+0020-007F; font-family: 'Allstar'; src: url('../../fonts/master/Allstar.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'BALLW___'; src: url('../../fonts/master/BALLW___.TTF'); }
@font-face { unicode-range: U+0020-007F; font-family: 'FV_Almelo'; src: url('../../fonts/master/FV_Almelo.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Cairo'; src: url('../../fonts/master/Cairo.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'college'; src: url('../../fonts/master/college.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Freshman'; src: url('../../fonts/master/Freshman.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Gladifil'; src: url('../../fonts/master/Gladifil.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'GOODGIRL'; src: url('../../fonts/master/GOODGIRL.TTF'); }
@font-face { unicode-range: U+0020-007F; font-family: 'HirukoBlackAlternate'; src: url('../../fonts/master/HirukoBlackAlternate.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Hipsteria'; src: url('../../fonts/master/Hipsteria.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Holtwood'; src: url('../../fonts/master/Holtwood.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'LAWSON'; src: url('../../fonts/master/LAWSON.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'MARIDAVID'; src: url('../../fonts/master/MARIDAVID.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Molot'; src: url('../../fonts/master/Molot.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Oce'; src: url('../../fonts/master/oce.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'PRICEDOW'; src: url('../../fonts/master/PRICEDOW.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Roboto-Black'; src: url('../../fonts/master/Roboto-Black.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Roboto-Regular'; src: url('../../fonts/master/Roboto-Regular.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'SimpleLife'; src: url('../../fonts/master/SimpleLife.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'Snickles'; src: url('../../fonts/master/Snickles.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'typwrng'; src: url('../../fonts/master/typwrng.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'vexler-slip'; src: url('../../fonts/master/vexler-slip.ttf'); }
@font-face { unicode-range: U+0020-007F; font-family: 'typewriter'; src: url('../../fonts/master/typewriter.ttf'); }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#preloader
{
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - #{map-get($navbar-height, xs)});
    @include media-breakpoint-up(lg) {
        height: 700px;
        min-height: 0;
        max-height: calc(100vh - #{map-get($navbar-height, lg)});
    }
    position: absolute;
    z-index: 99999;
    background: white;
    #status
    {
        width:150px;
        height:200px;
        background-image: url('../../img/master/loading.gif');
        background-size: 150px 150px;
        background-repeat: no-repeat;
        margin:-75px 0 0 -75px;
        position: absolute;
        top: 50%;
        left: 50%;
        em
        {
            top: 150px;
            font-size: 1em !important;
            display: block;
            position: absolute;
            text-align: center;
            width: 100%;
        }
    }
}

#msg-wrapper
{
    position: fixed;
    z-index: 99999999;
    top: 75px;
    width: 100%;
    display: none;
    .msg
    {
        float: right;
        padding: 2px 10px;
        color: #fff;
        margin-right: 5px;
        font-weight: bold;
        &.green { background: $green; }
        &.red { background: $red; }
    }
}
.modal-master-bg
{
    position: absolute;
    z-index: 99999999;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: none;
    .master-modal
    {
        position: absolute;
        top: 50%;
        padding: 10px;
        left: 50%;
        margin-left: -170px;
        width: 340px;
        margin-top: -60px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
        border: 1px solid #ccc;
        .modal-buttons
        {
            text-align: center;
            a {
                font-size: 1.2em;
                margin: 0;
                padding: 5px 10px;
            }
        }
    }
}
#reset-confirm-wrapper
{
    h4
    {
        font: 1.3em;
        margin-bottom: 1em;
        text-align: center;
    }
}
#share-box-wrapper
{
    #share-box
    {
        border: none;
        width: 320px;
        margin-left: -160px;
        position: relative;
        .close.abs
        {
            position: absolute;
            top: 0;
            right: 0;
            border: 2px solid black;
            color: black;
            padding: 0 5px;
            cursor: pointer;
        }
        padding-top: 10px;
        .copy-wrapper
        {
            clear: both;
            overflow: hidden;
            zoom: 1;
            margin-bottom: 20px;
            .url-box {
                padding: 5px;
                background: #fff;
                border: 1px solid black;
                -moz-user-select: text;
                -khtml-user-select: text;
                -webkit-user-select: text;
                cursor: text;
                user-select: text;
                font-size: 0.9em;
                text-align: left;
                float: left;
                width: 268px;
            }
            a.copy
            {
                display: block;
                float: left;
                width: 30px;
                margin: 0;
                background: $blue;
                color: $white;
                text-align: center;
                height: 24px;
                line-height: 24px;
                font-size: 17px;
                cursor: pointer;
            }
        }
        .dialog {
            color: #000;
            background: url('../../img/master/dialog.png') no-repeat;
            text-align: center;
            width: 239px;
            font-size: 1em;
            line-height: 1.1em;
            height: 60px;
            margin: 0;
            padding-top: 4px;
        }
        .new-lb-button {
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            background: $magenta-new;
            color: #fff;
            display: inline-block;
            &.blue {
                background: $blue;
            }
        }
        .close {
            color: #999;
            text-decoration: underline;
            display: inline-block;
        }
    }
}

#loading-wrapper
{
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    #builder-loading
    {
        position: absolute;
        top: 50%;
        margin-top: -80px;
        color: #fff;
        text-align: center;
        height: 120px;
        padding: 20px;
        font-weight: bold;
        width: 100%;
        font-size: 1.5em;
        line-height: 2em;
        i {
            display: block;
            font-size: 2em;
            -webkit-transform-origin: 50% 50%;
            transform-origin:50% 50%;
            -ms-transform-origin:50% 50%; /* IE 9 */
            -webkit-animation: spin 1s infinite linear;
            -moz-animation: spin 1s infinite linear;
            -o-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
        }
    }
}

#desktop-builder-menu
{
    display: block;
    float: left;
    width: 50%;
    height: 100%;
}
#build-wrapper
{
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    #ghost-menu {display: none;}
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - #{map-get($navbar-height, xs)});
    @include media-breakpoint-up(lg) {
        height: 700px;
        min-height: 0;
        max-height: calc(100vh - #{map-get($navbar-height, lg)});
    }
    &.wizard
    {
        width: 50%;
        float: left;
        #canvas-wrapper {
            #help-wrapper, .total-price-wrapper {
                display: none !important;
            }
        }
    }
    #canvas-wrapper
    {
        #help-wrapper
        {
            position: absolute;
            z-index: 9999999;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            button {
                width: 130px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -65px;
                margin-top: -40px;
                font-size: 2em;
                text-align: center;
                cursor: pointer;
                z-index: 3;
            }
            .help
            {
                position: absolute;
                top: 0;
                left: 0;
                overflow: visible;
                width: 100%;
                height: 100%;
                img {
                    position: absolute;
                    display: block;
                    width: 60%;
                    max-width:400px;
                    &.h01 {
                        bottom: 80px !important;
                        right: 33px !important;
                    }
                    &.h02 {
                        bottom: 80px !important;
                        left: 44px !important;
                    }
                    &.h03 {
                        top: 44px !important;
                        left: 90px !important;
                    }
                }
            }
        }
        #fullprint-assets { display: none; }
        #tomerge-assets { display: none; }
        position: relative;
        width: 100%;
        height: 100%;
        overflow: visible;
        #icons-wrapper { display: none; }
        >canvas, >img
        {
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 1;
            &.back
            {
                display: none !important;
            }
        }
        .overlay
        {
            z-index: 98;
            margin-left: 10%;
        }
        .toggle-view
        {
            z-index: 999;
            position: absolute;
            top: 20px;
            left: 20px;
            width: 61px;
            height: 61px;
            cursor: pointer;
            background: url('../../img/master/switch.png') 0 0 no-repeat;
            transition: 0.6s;
            transform-style: preserve-3d;
            &.toggle
            {
                background: url('../../img/master/switch2.png') 0 0 no-repeat;
                transform: rotateY(360deg);
            }
        }
        .reset
        {
            z-index: 999999;
            position: absolute;
            bottom: 20px;
            left: 34px;
            font-size: 4em;
            color: #ccc;
            cursor: pointer;
        }
        .help-trigger
        {
            z-index: 9998;
            position: absolute;
            top: 34px;
            right: 24px;
            font-size: 4em;
            color: #ccc;
            cursor: pointer;
            @media(min-width:767px)
            {
                display: none;
            }
        }
        #price-detail
        {
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            display: none;
            z-index: 9999;
            position: absolute;
            bottom: 13px;
            width: 300px;
            margin-left: -151px;
            background: #fff;
            padding: 10px;
            position: absolute;
            left: 50%;
            border: 1px solid #ccc;
            @media(min-width:767px)
            {
                bottom: inherit;
                top: 18px;
                left: inherit;
                right: 20px;
                margin-left: 0;
            }
            span.close
            {
                font-size: 1.5em;
                color: #aaa;
                position: absolute;
                z-index: 2;
                top: 11px;
                right: 10px;
                cursor: pointer;
            }
            table {
                text-shadow: none;
                width: 100%;
                tbody {
                    tr {
                        &.odd td { background: #f1f1f1;}
                        &.subtotal td:last-child { background: #ccc;}
                        &.off td {
                            background: $yellow;
                            font-weight: bold;
                            border-top-color: $yellow;
                            border-bottom-color: $yellow;
                            &:first-child { border-right-color: $yellow; }
                            &:last-child { border-left-color: $yellow; }
                        }
                        &.odd td { background: #f1f1f1;}
                        td, th {
                            padding: 5px;
                        }
                        td.blue {
                            background-color: $pink;
                            border-color: $pink;
                            color: white;
                            font-weight: bold;
                        }
                    }
                }
            }
            .button-lb {
                font-size: 1.2em;
                display: block;
                width: 170px;
                margin: 0 auto;
                margin-bottom: 20px;
                text-align: center;
            }
            small {
                display: block;
                line-height: 1em;
            }
        }
        .total-price-wrapper
        {
            z-index: 9999;
            position: absolute;
            bottom: 17px;
            left: 50%;
            width: 200px;
            margin-left: -100px;
            height: 90px;
            position: absolute;
            .fa-angle-down { display: none; }
            @media(min-width:767px)
            {
                bottom: inherit;
                top: 20px;
                left: inherit;
                right: 20px;
                margin-left: 0;
                .fa-angle-up { display: none; }
                .fa-angle-down { display: inline; }
            }
            .total-price
            {
                color: #fff;
                background: $blue;
                text-align: left;
                width: 100%;
                height: 60px;
                clear: both;
                zoom: 1;
                overflow: hidden;
                cursor: pointer;
                .price {
                    line-height: 60px;
                    font-size: 30px;
                    font-weight: bold;
                    width: 130px;
                    padding: 0 10px;
                    float: left;
                }
                .open-detail {
                    float: right;
                    width: 50px;
                    text-align: center;
                    font-size: 30px;
                    line-height: 60px;
                    background: $pink;
                    .blink {
                      animation: blinker 1s linear infinite;
                    }
                    @keyframes blinker {
                      50% { opacity: 0.0; }
                    }
                }
            }
            .price-msg
            {
                width: 100%;
                cursor: pointer;
                height: 30px;
                text-align: center;
                background: $yellow;
                color: black;
                line-height: 30px;
                font-weight: bold;
                text-transform: uppercase;
                animation: blinker 1s linear infinite;
                @keyframes blinker {
                  50% { opacity: 0.7; }
                }
            }
        }
        .upload-wrapper
        {
            z-index: 9999;
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 150px;
            height: 32px;
            button
            {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                cursor: pointer;
                font-size: 1em;
                &:hover { color: $pink !important; }
            }
            input
            {
                filter: alpha(opacity=0);
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                cursor: pointer;
                z-index: 2;
            }
        }
    }
    .disabled { color: #999; }
    #menu-partes
    {
        position: absolute;
        z-index: 9999;
    }
}

#builder-menu-wrapper
{
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display: none;
    position: absolute;
    overflow: auto;
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    z-index: 9999999; // fuck you header
    // background: rgba(255, 0, 153, 0.8);
    background: rgba(0, 102, 204, 0.8);
    // background: rgba(3, 47, 112, 0.9);
    // background: rgba(0, 0, 0, 0.9);
    #builder-menu { display: block; }
    &.wizard
    {
        width: 50%;
        left: 50%;
        z-index: 9;
        display: block !important;
        background: url('../../img/master/wizardbg.png');
    }
}

#builder-menu
{
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display: none;
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    z-index: 3;
    padding-top: 15px;
    padding: 20px;
    width: 100%;
    .step {
        display: none;
        // &.builder { text-align: center; }
        @media(min-width:767px)
        {
            width: 500px;
            margin: 0 auto;
        }
        .opt-wrapper { overflow:visible; }
    }
    .step-header { display: none; }
    &.wizard
    {
        padding: 0;
        span.close { display: none; }
        .step-header {
            display: block;
            width: 100%;
            padding: 10px;
            background: $gray-200;
            font-size: 1.2em;
            color: $darkgray;
            cursor: pointer !important;
            border-bottom: 1px solid $gray;
            font-family: 'Roboto';
            i { margin-right: 5px ;}
        }
        .step
        {
            &.builder, &.colors, &.sas, &.prints-list
            {
                display: none;
                padding: 10px;
                margin: 0;
                clear: both;
                zoom: 1;
                overflow: hidden;
                width: 100%;
                .opt-wrapper {
                    float: left;
                    border: 1px solid $gray;
                    margin: 0 5px 5px 0;
                    padding: 0 10px;
                    h3 {
                        font-family: 'Roboto';
                        font-weight: normal;
                        text-transform: none;
                        font-size: 1.3em;
                        text-align: center;
                        color: $darkgray;
                    }
                    .structure-modifier {
                        text-align: center;
                        margin-left: 2px;
                        margin-right: 2px;
                        border-color: $gray;
                        &.active {
                            border-color: $pink;
                        }
                        &.active.extra,&.active.sa  {
                            border-color: $magenta;
                        }
                        span {
                            color: $darkgray;
                            font-family: 'Roboto';
                            font-weight: normal;
                            text-transform: none;
                        }
                    }
                }
                &.color-wrapper {
                    h3 {
                        font-family: 'Roboto';
                        font-weight: normal;
                        text-transform: none;
                        font-size: 1.3em;
                        color: $darkgray;
                        margin-bottom: 0;
                    }
                }
                .dash {
                    font-size: 1.2em;
                    cursor: pointer;
                    line-height: 1.8em;
                    position: static;
                    &.active {
                        color: $magenta;
                        font-weight: bold;
                    }
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
        h3 { color: black; }
        .options {
            .structure-modifier {
                span { color: black; }
            }
        }
        #color-lists-wrapper
        {
            #color-lists-menu
            {
                h4 { color: black; }
            }
        }
        #bye-wizard
        {
            display: none;
            position: static;
            .wizard-controls
            {
                width: 100%;
                padding: 10px;
                .control {
                    font-size: 1.4em;
                    font-weight: lighter;
                    color: $darkgray;
                    cursor: pointer;
                    display: inline-block;
                    padding-top: 14px;
                    &.reset { margin-right: 10px; }
                    &.list { display: inline-block !important; }
                }
            }
            .wizard-step
            {
                text-align: center;
                display: none;
                h3 {
                    font-family: 'Roboto';
                    text-transform: none;
                    color: $darkgray;
                }
                p
                {
                    font-size: 1.4em;
                    color: $darkgray;
                    line-height: 1.3em;
                    max-width: 500px;
                    margin: 0 auto;
                    margin-bottom: 20px;
                }

                .colorpicker
                {
                    color: $darkgray !important;
                }
                .font-wrapper
                {
                    .font
                    {
                        color: $darkgray !important;
                    }
                }
                .choices {
                    margin-top: 30px;
                    // &.builder { text-align: center; }
                    @media(min-width:767px)
                    {
                        width: 600px;
                        margin: 0 auto;
                    }
                    .choice, .option.img
                    {
                        &.hidden {
                            opacity: 0.3;
                            cursor: default;
                        }
                        cursor: pointer;
                        list-style-type: none;
                        display: inline-block;
                        width: 72px;
                        height: 72px;

                        margin: 0 15px 40px 15px;
                        border-radius: 72px;
                        position: relative;
                        overflow: visible;
                        border: 2px solid $gray;
                        img
                        {
                            width: 68px;
                            height: 68px;
                            border-radius: 68px;
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            border: none;
                        }
                        span
                        {
                            display: block;
                            width: 100%;
                            text-align: center;
                            font-weight: bold;
                            color: $darkgray;
                            text-transform: uppercase;
                            position: absolute;
                            top: 77px;
                            font-size: 1em;
                            line-height: 1em;
                        }
                    }
                    .option:not(.img)
                    {
                        position: relative;
                        font-size: 1.2em;
                        padding: 10px 0;
                        color: $darkgray;
                        line-height: 1em;
                        font-weight: bold;
                        width: 80%;
                        text-align: left;
                        padding-left: 23px;
                        cursor: pointer;
                        span {
                            display: inline-block;
                            width: 13px;
                            height: 13px;
                            border: 1px solid $darkgray;
                            position: absolute;
                            top: 10px;
                            left: 0;
                        }
                        &.active span { background: $pink; }
                    }
                }
            }
        }
    }
    span.close
    {
        font-size: 2em;
        font-weight: lighter;
        color: white;
        position: fixed;
        z-index: 2;
        top: 29px;
        right: 20px;
        cursor: pointer;

    }
    h3
    {
        font-family: 'Lilita One';
        font-size: 2em;
        line-height: 1em;
        margin-top: 10px;
        color: white;
        font-weight: normal;
        text-transform: uppercase;

    }
    select
    {
        font-size: 1.2em;
        background: #fff;
        margin-bottom: 30px;
        padding: 10px;
        color: #4b4b4b;
        border: 2px solid #dedede;
        -webkit-appearance: menulist-button;
        -moz-appearance: menulist-button;
        appearance: menulist-button;
    }
    .options
    {
        width: 100%;
        .structure-modifier
        {
            cursor: pointer;
            list-style-type: none;
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 15px 30px 0;
            border-radius: 60px;
            position: relative;
            overflow: visible;
            border: 2px solid white;
            &.active
            {
                border-color: $pink;
            }
            &.active.extra,&.active.sa
            {
                border-color: $yellow;
            }
            img, .noicon
            {
                width: 56px;
                height: 56px;
                border-radius: 56px;
                position: absolute;
                z-index: 2;
                border: none;
            }
            .noicon
            {
                border-radius: 60px;
            }
            span
            {
                display: block;
                width: 62px;
                text-align: center;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                position: absolute;
                top: 65px;
                left: -3px;
                font-size: 1em;
                line-height: 1em;
            }
        }
    }
    #color-lists-wrapper
    {
        #color-lists-menu
        {
            margin-bottom: 20px;
            h4
            {
                font-family: 'Source Sans Pro';
                color: white;
                text-transform: uppercase;
                font-size: 1.3em;
                line-height: 1.2em;
                display: inline-block;
                cursor: pointer;
                margin-right: 5px;
                padding: 5px;
                border: 1px solid white;
                margin-bottom: 5px;
                &.active
                {
                    color: $pink;
                    border: none;
                    background-color: #fff;
                }
            }
        }
        position: relative;
        .color-list
        {
            margin-bottom: 30px;
            display: none;
            line-height: 0.8em;
            clear: both;
            zoom: 1;
            overflow: hidden;
            &.active
            {
                display: block;
            }
            .color
            {
                width: 36px;
                height: 36px;
                display: block;
                float: left;
                cursor: pointer;
                margin: 0;
                padding: 0;
                border: 1px solid #979797;
                &.selected
                {
                    border: 2px solid #fff;
                }
                img
                {
                    width: 100%;
                    height: 100%;
                }
                &.print
                {
                    border:none;
                    margin: 1px;
                    width: 34px;
                    height: 34px;
                    &.selected
                    {
                        border: 2px solid #fff;
                        margin: 0;
                        width: 36px;
                        height: 36px;
                    }
                }
                &.progress
                {
                    background: #979797;
                    border: none;
                    .bar {
                        width: 0%;
                        background-color: $pink;
                        height: 100%;
                    }
                    &.hide { display: none; }
                }
                &.upload-button
                {
                    width: 108px;
                    background: $pink url('../../img/master/upload.png') right 4px no-repeat;
                    border: none;
                    text-align: left;
                    color: #fff;
                    font-family: 'Lilita One';
                    font-size: 1.7em;
                    padding: 3px 10px;
                    line-height: 32px;
                    text-transform: uppercase;
                }
            }
            h4.unmerged
            {
                margin: 10px 0;
                line-height: 1.8em;
                margin-left: 12px;
                font-size: 2em;
                font-weight: bold;
            }
        }
    }
    #bye-wizard
    {
        position: relative;
        width: 100%;
        height: 100%;
        .wizard-controls
        {
            width: 100%;
            height: 50px;
            .control {
                font-size: 1.4em;
                font-weight: lighter;
                color: white;
                cursor: pointer;
                display: inline-block;
                padding-top: 14px;
                &.reset { margin-right: 10px; }
                &.list { display: none; }
            }
        }
        .wizard-step
        {
            text-align: center;
            display: none;
            p
            {
                font-size: 1.4em;
                color: #fff;
                line-height: 1.3em;
                max-width: 500px;
                margin: 0 auto;
                margin-bottom: 20px;
            }
            .choices {
                margin-top: 30px;
                // &.builder { text-align: center; }
                @media(min-width:767px)
                {
                    width: 600px;
                    margin: 0 auto;
                }
                .choice, .option.img
                {
                    &.hidden {
                        opacity: 0.3;
                        cursor: default;
                    }
                    cursor: pointer;
                    list-style-type: none;
                    display: inline-block;
                    width: 72px;
                    height: 72px;
                    margin: 0 15px 40px 15px;
                    border-radius: 72px;
                    position: relative;
                    overflow: visible;
                    border: 2px solid white;
                    img
                    {
                        width: 68px;
                        height: 68px;
                        top: 0;
                        left: 0;
                        border-radius: 68px;
                        position: absolute;
                        z-index: 2;
                        border: none;
                    }
                    span
                    {
                        display: block;
                        width: 100%;
                        text-align: center;
                        font-weight: bold;
                        color: #fff;
                        text-transform: uppercase;
                        position: absolute;
                        top: 77px;
                        font-size: 1em;
                        line-height: 1em;
                    }
                }
                .option:not(.img)
                {
                    font-size: 1.2em;
                    padding: 10px 0;
                    color: #fff;
                    line-height: 1em;
                    position: relative;
                    font-weight: bold;
                    width: 80%;
                    text-align: left;
                    padding-left: 23px;
                    cursor: pointer;
                    span {
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        border: 1px solid white;
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }
                    &.active span { background: $pink; }
                }
            }
        }
    }
}

#font-modal-wrapper
{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: rgba(0,0,0,0.8);
    padding: 10px;
    padding-top: 90px;
    width: 100%;
    min-height: 100%;
    span.close
    {
        font-size: 2em;
        font-weight: lighter;
        color: white;
        position: fixed;
        z-index: 2;
        top: 28px;
        right: 20px;
        cursor: pointer;
    }
    #font-modal
    {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        color: white;
        .font
        {
            font-size: 2em;
            line-height: 2em;
            display: none;
            margin-right: 30px;
            cursor: pointer;
            &:hover { color: $pink; }
        }
    }
}
#color-modal-wrapper
{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: rgba(0,0,0,0.8);
    padding: 10px;
    padding-top: 80px;
    width: 100%;
    min-height: 100%;
    span.close
    {
        font-size: 2em;
        font-weight: lighter;
        color: white;
        position: fixed;
        z-index: 2;
        top: 28px;
        right: 20px;
        cursor: pointer;
    }
    #color-modal
    {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        line-height: 0.8em;
        color: white;
        h3
        {
            color: white;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 30px;
            text-transform: uppercase;
        }
        p
        {
            line-height: 1em;
        }
        .color
        {
            width: 36px;
            height: 36px;
            display: inline-block;
            cursor: pointer;
            margin: 0;
            padding: 0;
            border: 1px solid #979797;
            &.selected
            {
                border: 2px solid #fff;
            }
            img
            {
                width: 100%;
                height: 100%;
            }
            &.print
            {
                border:none;
                margin: 1px;
                width: 34px;
                height: 34px;
                &.selected
                {
                    border: 2px solid #fff;
                    margin: 0;
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
}
.bye-tool
{
    .button-lb
    {
        cursor: pointer;
        font-size: 2em;
        display: block;
        margin: 0 auto;
    }
    .color-wrapper
    {
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
        .label
        {
            display: inline-block;
            font-weight: bold;
            font-size: 12px;
            line-height: 34px;
            text-transform: uppercase;
        }
        .color
        {
            display: inline-block;
            width: 36px;
            height: 36px;
            cursor: pointer;
            // border: 1px solid black;
            background: white;
            img
            {
                width: 100%;
                height: 100%;
            }
        }
        &.c1 .color { background: white; }
        &.c2 .color { background: black; }
        .colorpicker
        {
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            margin-left: 5px;
            font-size: 20px;
            text-decoration: none;
        }
    }
}
.bye-tool
{
    .preview-wrapper
    {
        width: 220px;
        height: 220px;
        line-height: 220px;
        padding: 10px;
        margin: 0 auto;
        margin-bottom: 20px;
        .preview
        {
            font-size: 4em;
            white-space: pre;
            display: inline-block;
            text-align: center;
            line-height: 1.4em;
        }
    }
    &#text-tool .preview-wrapper
    {
        width: auto;
        height: auto;
        line-height: 36px;
        display: inline-block;
        .preview {
            vertical-align: middle;
            display: inline;
            padding: 10px;
        }
    }
}
#text-tool
{
    color: white;
    font-size: 1em;
    h3.title
    {
        margin-bottom: 10px;
    }
    label {
        display: block;
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 5px;
    }
    input {
        padding: 10px;
        font-size: 1em;
        margin-bottom: 2px;
        width: 300px;
        margin: 0 auto;
    }
    textarea{
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        padding: 10px;
        min-width: 200px;
        font-size: 16px;
    }
    small {
        display: block;
        font-size: 0.9em;
        font-style: italic;
        margin-bottom: 20px;
        &.example { margin-bottom: 10px; }
        &.count
        {
            margin-top: -25px;
            font-style: normal;
            font-weight: bold;
        }
    }
    select {
        display: block;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #dedede;
        margin-bottom: 10px;
        -webkit-appearance: menulist-button;
            -moz-appearance:    menulist-button;
            appearance:         menulist-button;
    }
    .font-wrapper
    {
        .label
        {
            display: inline-block;
            font-weight: bold;
        }
        .font
        {
            display: inline-block;
            cursor: pointer;
            color: #fff;
            font-size: 24px;
            line-height: 36px;
            text-decoration: none;
        }
        .fontpicker
        {
            display: inline-block;
            text-decoration: underline;
            color: $pink;
        }
    }
}

.prints-wrapper
{
    position: relative;
    margin: 0 auto;
    z-index: 999;
    .wizard-trigger
    {
        &.dot
        {
            opacity: 0;
            position: absolute;
            cursor: pointer;
            background: $blue;
            border: 2px solid white;
            color: #fff;
            border-radius: 50%;
            z-index: 999;
            text-align: center;
            font-weight: bold;
            &.back { display: none; }
            &.active {
                background: $pink;
                opacity: 0.2;
            }
            &:hover {
                opacity: 0.7 !important;
            }
        }
    }
}


.mdb-wrapper:not(.desktop)
{
    z-index: 10000;
    pointer-events: none;
    width: 100%;
    height: 80px;
    position: absolute;
    right: 0;
    bottom: 0;
    &.wizard
    {
        display: none;
    }
    *
    {
        pointer-events: default;
    }
    &.mdb-show
    {
        height: 100%;
        .mdb-menu-wrapper
        {
            display: block;
            .mdb-btn
            {
                margin-left: 0;
            }
        }
    }
    .mdb-menu-wrapper
    {
        display: none;
        right: 0;
        bottom: 75px;
        position: absolute;
        .mdb-btn
        {
            margin-left: 100%;
            position: relative;
            padding-left: 300px;
            button
            {
                cursor: pointer;
                width:40px;
                height:40px;
                border-radius:100%;
                background:$white;
                margin-bottom:12px;
                margin-right:26px;
                padding:0;
                border:none;
                outline:none;
                color:#2b2b2b;
                font-size: 19px;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                transition:.3s;
                pointer-events:all;
                &:hover, &:focus
                {
                    background: lighten($white, 20);
                }
            }
            &.mdb-btn-4
            {
                button, label
                {
                    background-color: $blue;
                    color: #fff;
                    &:hover, &:focus
                    {
                        background: lighten($blue, 20);
                    }
                }
            }
            &.mdb-btn-5
            {
                button, label
                {
                    background-color: $pink;
                    color: #fff;
                    &:hover, &:focus
                    {
                        background: lighten($pink, 20);
                    }
                }
            }
            &.mdb-btn-6
            {
                button, label
                {
                    background-color: $green;
                    color: #fff;
                    &:hover, &:focus
                    {
                        background: lighten($green, 20);
                    }
                }
            }
            label
            {
                position: absolute;
                right: 70px;
                top: 8px;
                font-family: 'Lilita One';
                // background: rgba(254, 230, 9, 0.8);
                background: $lightgray;
                color: #2b2b2b;
                padding: 4px;
                font-family: Lilita One;
                text-transform: uppercase;
                font-size: 18px;
                pointer-events: all;
                cursor: pointer;
                &:hover {
                    background: darken($lightgray, 20);
                }
            }
        }
    }
    button.mdb-btn-trigger
    {
        width:60px;
        height:60px;
        border-radius:100%;
        background: $pink;
        right:16px;
        bottom:16px;
        position:absolute;
        margin-right:0;
        margin-bottom:0;
        padding:0;
        border:none;
        outline:none;
        color:#FFF;
        font-size:36px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition:.3s;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        pointer-events:all;
        &:hover, &:focus
        {
            background: lighten($pink, 20);
        }
        span
        {
            display: inline-block;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            transition:all 250ms ease;
        }
        &.active span
        {
            -ms-transform: rotate(45deg)!important;
            -webkit-transform: rotate(45deg)!important;
            transform: rotate(45deg)!important;
            transition:all 250ms ease;
        }
    }
}

.mdb-wrapper.desktop
{
    padding: 10px;
    .mdb-btn {
        display: inline-block;
        line-height: 2em;
        font-size: 1.6em;
        margin-right: 20px;
        cursor: pointer;
        background:$pink;
        padding: 0 10px;
        border:none;
        outline:none;
        color:#fff;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition:.3s;
        pointer-events:all;
        &.mdb-btn-3 {
            background: $blue;
            &:hover, &:focus
            {
                background: lighten($blue, 20);
            }
        }
        &:hover, &:focus
        {
            background: lighten($pink, 20);
        }
    }
}
#reset-confirm-wrapper
{
    h4
    {
        font: 1.3em;
        margin-bottom: 1em;
        text-align: center;
    }
}

.button
{
    &.button-lb
    {
        font-family: 'Lilita One';
        font-size: 1em;
        line-height: 1em;
        padding: 10px;
        text-transform: uppercase;
        i
        {
            font-size: 0.8em;
            margin-right: 15px;
        }
        &.pink
        {
            background-color: $pink;
            border-bottom: solid 4px $darkpink;
            border-right: solid 4px $darkpink + #222;
            color: white;
            &:hover { background-color: $pink + #111; }
        }
        &.yellow
        {
            background-color: $yellow;
            border-bottom: solid 4px $darkyellow;
            border-right: solid 4px $darkyellow + #222;
            color: white;
            &:hover { background-color: $yellow + #111; }
        }
        &.green
        {
            background-color: $green;
            border-bottom: solid 4px $darkgreen;
            border-right: solid 4px $darkgreen + #222;
            color: white;
            &:hover { background-color: $green + #111; }
        }
        &.blue
        {
            background-color: $blue;
            border-bottom: solid 4px $blue2;
            border-right: solid 4px $blue2 + #222;
            color: white;
            &:hover { background-color: $blue + #111; }
        }
        &.darkblue
        {
            background-color: $darkblue;
            border-bottom: solid 4px $darkblue2;
            border-right: solid 4px $darkblue2 + #222;
            color: white;
            &:hover { background-color: $darkblue + #111; }
        }
        &.gray
        {
            background-color: $gray;
            border-bottom: solid 4px $darkgray;
            border-right: solid 4px $darkgray + #222;
            color: white;
            &:hover { background-color: $gray + #111; }
            &.active {
                background-color: $pink;
                border-bottom: solid 4px $darkpink;
                border-right: solid 4px $darkpink + #222;
                color: white;
                &:hover { background-color: $pink + #111; }
            }
        }
        &.white
        {
            background-color: white;
            border-bottom: solid 4px white;
            border-right: solid 4px white + #222;
            color: #7b7b7b;
            &:hover {
                background-color: white + #111;
                color: #999 !important;
            }
        }
        &.red
        {
            background-color: $darkred;
            border-bottom: solid 4px $darkred2;
            border-right: solid 4px $darkred2 + #222;
            color: white;
            &:hover { background-color: $darkred + #111; }
        }
    }
}

.hide
{
    visibility: hidden;
    display: none;
}

.sizepicker {
    .button-lb {
        display: inline-block !important;
        margin: 0 5px 10px !important;
    }
}