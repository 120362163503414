.home-info-bg {
    background: url(../../img/animated-crosses-colored.gif) center center space repeat;
    background-attachment: fixed;
}

.home-info-animation {
    @include media-breakpoint-up(lg) {
        margin-top: -4.5rem;
        max-height: 150px;
    }
}

.home-info-icon {
    height: 25px;
    margin-bottom: 10px;
}