$pacman-size: 80;
$pacman-size-lg: 20;

.lb-spinner {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    color: $blue;
    width: $pacman-size + px;
    height: $pacman-size + px;

    @include media-breakpoint-up(lg) {
        position: relative;
        color: currentColor;
        width: $pacman-size-lg + px;
        height: $pacman-size-lg + px;
    }

    > span {
        position: relative;
        display: inline-block;
        background-color: currentColor;

        &:nth-child(1),
        &:nth-child(2) {
            display: block;
            width: 0;
            height: 0;
            background: transparent;
            border: ($pacman-size / 2) + px solid currentColor;
            border-right-color: transparent;
            border-radius: 100%;
            animation: pacman-rotate-half-up .5s 0s infinite;

            @include media-breakpoint-up(lg) {
                border-width: ($pacman-size-lg / 2) + px;
            }
        }

        &:nth-child(2) {
            margin-top: -$pacman-size + px;
            animation-name: pacman-rotate-half-down;

            @include media-breakpoint-up(lg) {
                margin-top: -$pacman-size-lg + px;
            }
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            position: absolute;
            top: 50%;
            left: 200%;
            width: 25%;
            height: 25%;
            border-radius: 100%;
            opacity: 0;
            animation: pacman-balls 2s 0s infinite linear;
        }
        &:nth-child(3) {
            animation-delay: -1.44s;
        }
        &:nth-child(4) {
            animation-delay: -1.94s;
        }
        &:nth-child(5) {
            animation-delay: -2.44s;
        }
        &:nth-child(6) {
            animation-delay: -2.94s;
        }
    }
}

@keyframes pacman-rotate-half-up {
    0%,
    100% {
        transform: rotate(270deg);
    }
    50% {
        transform: rotate(360deg);
    }
}
@keyframes pacman-rotate-half-down {
    0%,
    100% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(0deg);
    }
}
@keyframes pacman-balls {
    0% {
        left: 200%;
        opacity: 0;
        transform: translateY(-50%);
    }
    5% {
        opacity: .5;
    }
    66% {
        opacity: 1;
    }
    67% {
        opacity: 0;
    }
    100% {
        left: 0;
        transform: translateY(-50%);
    }
}