@import "../modules/clients/clients-top";

#client-options {

    .icon {
        display: inline-block;
        height: 1em;
    }

    .icon,
    h3 span {
        vertical-align: middle;
    }
}