html {
    font-size: 14px;
    @include media-breakpoint-up(md) { font-size: 15px; }
    @include media-breakpoint-up(lg) { font-size: 15px; }
}

body {
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4 { text-transform: uppercase; }
h5, h6 { font-family: $font-family-base; }

b, strong { font-weight: 700; }

.font-title { font-family: $headings-font-family; }

svg.icon-default { height: 0.7em; }

.underline { text-decoration: underline; }

a {
    *:not(u) { text-decoration: inherit; }

    @media (pointer: coarse) {

        &:not(.btn) { text-decoration: underline; }
    }

    &.tdn { text-decoration: none; }
}

.steelfish {
    font-family: $steelfish;
    letter-spacing: 0.05em;
    line-height: 1;
}

h1.steelfish { font-size: 4.2rem; }
h2.steelfish { font-size: 3.3rem; }
.display-3.steelfish { font-size: 5.5rem; }