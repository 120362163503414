@import "../modules/home/hero";
@import "../modules/home/promo";
@import "../modules/home/info";
@import "../modules/home/instagram";
@import "../modules/home/experience";

.home-social-links {
    background: url(../../img/animated-crosses-colored.gif) center center repeat-y;
    background-color: $black;
    background-attachment: fixed;
}