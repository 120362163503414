.app-landing-wrapper {
    min-height: calc(100vh - 150px);

    @include media-breakpoint-up(lg) {
        min-height: calc(100vh - 475px);
    }
}

h1.app-landing-title {
    letter-spacing: 0;
    line-height: 1.12;

    @include media-breakpoint-down(sm) {
        font-size: 3.8em;
    }
}

.rayito {
    fill: $primary;
    position: absolute;
    top: 76px;
    left: 256px;
    width: 40px;
}

.app-source-logo {
    width: 140px;
}

.list-wrapper {
    background-color: $gray;
    color: $white;

    @include media-breakpoint-up(md) {
        background-color: transparent;
        color: $gray;
    }

    li {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        position: relative;

        .icon {
            position: absolute;
            top: 0.85rem;
            left: 0;
            height: 1.2rem;
        }
    }

    h4 {
        padding-left: 1.5rem;
        letter-spacing: 0;
    }

    p { margin: 0; }

    @include media-breakpoint-up(md) {
        li {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            .icon {
                top: 0.7rem;
                height: 0.8rem;
            }
        }

        h4 {
            display: none;
        }

        p { padding-left: 1.2rem; }
    }
}

.app-desktop-image {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 36vw;
}

.app-wave-me {
    background: url('../../img/app/wave.png') 100% 90% no-repeat;
}