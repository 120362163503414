@import "../modules/inspire/colors";
@import "../modules/inspire/fullprints";
@import "../modules/inspire/fabrics";

.close.inspire-close {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    padding: 10px 13px;
    opacity: 1;
    color: $white;
    text-shadow: 0 1px 0 $black;
    font-size: 2rem;
}

.inspire-btn-back {
    position: fixed;
    top: 50%;
    left: 0;
    width: 65px;
    height: 65px;
    transform: translate(-50%, -50%);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    z-index: 1;

    .icon {
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
    }
}