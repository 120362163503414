body.colors-menu-open {
    @include media-breakpoint-down(md) { overflow: hidden;}
}

.inspire-btn-plus {
    width: 50px;
    height: 50px;
    font-size: 2.4rem;
    line-height: 50px;
    text-align: center;
    padding: 0;
}

.inspire-colors {
    @include media-breakpoint-up(lg) {
        overflow: hidden;
    }
}

.inspire-colors-chart {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: $zindex-fixed;
    opacity: 0;
    transform: translateX(-100vw);
    pointer-events: none;
    transition: opacity 0.4s ease;
    padding-top: 48px;

    @include media-breakpoint-up(lg) {
        position: relative;
        overflow: visible;
        transform: none;
        pointer-events: auto;
        opacity: 1;
        z-index: 1;
        margin: -11px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 0;
    }

    &.is-active {
        transform: none;
        pointer-events: auto;
        opacity: 1;
    }

    .category-name {
        position: fixed;
        color: $white;
        text-transform: uppercase;
        font-family: $oswald;
        font-size: 1.6rem;
        top: 0;
        left: 0;
        right: 0;
        text-align: left;
        padding-left: 40px;
        background: $black;
        line-height: 48px;
        z-index: 1;
    }

    ul {
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
    }

    .inspire-color {
        position: relative;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            padding: 15px 15px 15px 40px;
            font-size: 1.3rem;
            display: flex;
            align-items: flex-end;
            min-height: 0;
            transition: all 0.4s ease;
            border-top: 5px solid $white;

            &.is-dark { color: $white; }
            &.is-active { min-height: calc(100% + 48px); }
        }

        @include media-breakpoint-only(md) {
            padding-left: 50px;
        }

        @include media-breakpoint-up(lg) {
            width: 98px;
            height: 98px;
            margin: 11px;
            border-radius: 50%;
            border: 4px solid $white;
        }

        .color-info {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 20px;
                text-transform: uppercase;
                text-align: center;
                position: absolute;
                bottom: calc(50% + 10px);
                left: 50%;
                transform: translateX(-50%);
                height: 190px;
                width: 190px;
                cursor: auto;
                background: $white;
                border: 4px solid $black;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.4s ease, bottom 0.4s ease;
                z-index: $zindex-tooltip;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -12px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-left: 11px solid transparent;
                    border-right: 11px solid transparent;
                    border-top: 9px solid $black;
                }
            }

            .image {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: url('../../img/inspire/buzoblanco.png') center center no-repeat;
                        background-size: contain;
                    }
                }
            }
        }

        &.jaspeado {
            .color-info {
                .image {
                    @include media-breakpoint-up(lg) {
                        &:before {
                            background-image: url('../../img/inspire/buzoblancojaspeado.png');
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                .color-info {
                    opacity: 1;
                    bottom: 50%;
                    pointer-events: auto;
                }
            }
        }
    }
}