$navbar-height: (
    xs: 58px,
    lg: 70px
);
$submenu-height: 42px;

$blue: #2b6dca; // $primary
$magenta: #d54ab7; // $secondary
$magenta-new: #d54ab7; // $secondary
$white: #fff;
$gray-100: #fafafa;
$gray-200: #efefef;
$gray-300: #e6e6e6;
$gray-400: #dadada;
$gray-500: #bdbdbd;
$gray-600: #969696;
$gray-700: #575757;
$gray-800: #404040;
$gray-900: #292929;
$lightgray: #F2F2F2;
$yellow: #FCEE21;
$darkgray: #4D4D4D;
$red: #FC214E;
$black: #000;
// $red: #fc214e;
$whatsapp: #1a8768;
$light-gray: $gray-200;
$gray: $gray-600;
$dark-gray: $gray-700;

$theme-colors: (
    "light-gray": $light-gray,
    "gray": $gray,
    "dark-gray": $dark-gray,
    "black": $black
);

// $div-box-shadow: 0 36px 55px -36px $gray;

$oswald: 'Oswald', sans-serif;
$roboto: 'Roboto', sans-serif;
$steelfish: 'Steelfish', sans-serif;

// Bootstrap reset
$secondary: $magenta;

$body-color: $black;

$font-family-base: $roboto;
$font-size-base: 1rem;
$font-weight-base: 300;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 3.4;
$headings-font-family: $oswald;
$headings-font-weight: 400;
$headings-line-height: 1.15;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-font-weight: 300;