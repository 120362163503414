.products-breadcrumb-wrapper {
    font-family: $oswald;
    color: $gray;
}

.products-breadcrumb {
    background: transparent;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
    }

    .breadcrumb-item {
        color: inherit;

        &:before { color: inherit; }
        h1 {
            font-size: inherit;
            font-family: inherit;
            text-transform: inherit;
            margin: 0;
            font-weight: inherit;
        }
    }
}

.products-select {
    .dropdown {
        .btn,
        .dropdown-menu {
            text-transform: uppercase;
            font-size: 0.9rem;
        }

        .btn {
            color: $blue;
            background: $white;
        }

        .dropdown-menu { border-radius: 0; }

        a { text-decoration: none; }

        .dropdown-item {
            font-weight: 300;

            &.active,
            &:active {
                background: none;
                color: $blue;
            }
        }
    }
}

.filter-by-tags-form {
    max-width: 400px;
    position: relative;
    padding-bottom: 2rem;

    @include media-breakpoint-up(md) {
        max-width: none;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-bottom: 0;
    }

    .input-group {
        position: static;

        .input-group-text {
            background: none;
            padding-top: 4px;
            padding-bottom: 4px;

            @include media-breakpoint-down(sm) {
                border: none;
                padding: 0;
            }
        }

        .floating-checkbox {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0.5rem;
            left: 50%;
            transform: translateX(-50%);

            @include media-breakpoint-up(md) {
                position: relative;
                bottom: 0;
                left: 0;
                transform: none;
                background: $white;
            }
        }

        input[type="search"] {
            @include media-breakpoint-down(sm) {
                width: 100%;
                border-radius: 0.25rem;
            }
        }
    }

    [type="submit"] {
        width: 35px;
        height: 35px;
        padding: 10px 0;
        flex: 0 0 auto;
        margin-left: 10px;

        svg {
            display: inline-block;
            width: 33.33%;
            transform: scaleX(-1);
        }
    }
}