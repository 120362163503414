$max-items: 12;

.carousel-item-next,
.carousel-item-prev {
    z-index: 2;
}

[class^="carousel-control"] {
    z-index: 3;
}

.carousel-multiple-items {
    // .carousel-control {
    //     width: 4%;
    //     background-image: none;
    //     margin: 0 2%;
    //     font-size: 4em;
    //     color: $blue;
    //     opacity: 1;
    //     i {
    //         position: absolute;
    //         top: 0;
    //         bottom: 0;
    //         left:0;
    //         right:0;
    //         margin: auto;
    //         width:50%;
    //         height: 30%;
    //     }
    // }
    @for $i from 1 through ($max-items - 1) {
        .cloneditem-#{$i} {
            display: none;
        }
    }
    .carousel-inner {
        margin: 0 8%;
        width: auto;
        .carousel-item {
            > div {
                padding: 0;
                transition: all .5s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }

            &.active,
            &.carousel-item-next,
            &.carousel-item-prev { display: flex; }
        }
    }

// ITEMS XS

    @for $i from 2 through ($max-items) {
        &.items-xs-#{$i} .carousel-inner {
            > .carousel-item.active.carousel-item-right, > .carousel-item.carousel-item-next {
                transform: translate3d(100% / $i, 0, 0);
                left: 0;
            }
            > .carousel-item.active.carousel-item-left,
            > .carousel-item.carousel-item-prev {
                transform: translate3d(-100% / $i, 0, 0);
                left: 0;
            }
            > .carousel-item.carousel-item-left,
            > .carousel-item.carousel-item-prev.carousel-item-right,
            > .carousel-item.active {
                transform: translate3d(0, 0, 0);
                left: 0;
            }
            > .active.carousel-item-left,
            > .carousel-item-prev {
                left: -100% / $i;
            }
            > .active.carousel-item-right,
            > .carousel-item-next {
                left: 100% / $i;
            }
            > .carousel-item-left,
            > .carousel-item-prev.carousel-item-right,
            > .active {
                left: 0;
            }
            @for $j from 1 through ($i - 1) {
                .cloneditem-#{$j} {
                    display: block;
                }
            }
        }
    }

// /ITEMS XS

// ITEMS SM

    @include media-breakpoint-up(sm) {
        @for $i from 2 through ($max-items) {
            &.items-sm-#{$i} .carousel-inner {
                > .carousel-item.active.carousel-item-right, > .carousel-item.carousel-item-next {
                    transform: translate3d(100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.active.carousel-item-left,
                > .carousel-item.carousel-item-prev {
                    transform: translate3d(-100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.carousel-item-left,
                > .carousel-item.carousel-item-prev.carousel-item-right,
                > .carousel-item.active {
                    transform: translate3d(0, 0, 0);
                    left: 0;
                }
                > .active.carousel-item-left,
                > .carousel-item-prev {
                    left: -100% / $i;
                }
                > .active.carousel-item-right,
                > .carousel-item-next {
                    left: 100% / $i;
                }
                > .carousel-item-left,
                > .carousel-item-prev.carousel-item-right,
                > .active {
                    left: 0;
                }
                @for $j from 1 through ($i - 1) {
                    .cloneditem-#{$j} {
                        display: block;
                    }
                }
            }
        }
    }

// /ITEMS SM

// ITEMS MD

    @include media-breakpoint-up(lg) {
        @for $i from 2 through ($max-items) {
            &.items-md-#{$i} .carousel-inner {
                > .carousel-item.active.carousel-item-right,
                > .carousel-item.carousel-item-next {
                    transform: translate3d(100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.active.carousel-item-left,
                > .carousel-item.carousel-item-prev {
                    transform: translate3d(-100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.carousel-item-left,
                > .carousel-item.carousel-item-prev.carousel-item-right,
                > .carousel-item.active {
                    transform: translate3d(0, 0, 0);
                    left: 0;
                }
                > .active.carousel-item-left,
                > .carousel-item-prev {
                    left: -100% / $i;
                }
                > .active.carousel-item-right,
                > .carousel-item-next {
                    left: 100% / $i;
                }
                > .carousel-item-left,
                > .carousel-item-prev.carousel-item-right,
                > .active {
                    left: 0;
                }
                @for $j from 2 through ($i - 1) {
                    .cloneditem-#{$j} {
                        display: block;
                    }
                }
            }
        }
    }

// /ITEMS MD

// ITEMS LG

    @include media-breakpoint-up(xl) {
        @for $i from 2 through ($max-items) {
            &.items-lg-#{$i} .carousel-inner {
                > .carousel-item.active.carousel-item-right,
                > .carousel-item.carousel-item-next {
                    transform: translate3d(100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.active.carousel-item-left,
                > .carousel-item.carousel-item-prev {
                    transform: translate3d(-100% / $i, 0, 0);
                    left: 0;
                }
                > .carousel-item.carousel-item-left,
                > .carousel-item.carousel-item-prev.carousel-item-right,
                > .carousel-item.active {
                    transform: translate3d(0, 0, 0);
                    left: 0;
                }
                > .active.carousel-item-left,
                > .carousel-item-prev {
                    left: -100% / $i;
                }
                > .active.carousel-item-right,
                > .carousel-item-next {
                    left: 100% / $i;
                }
                > .carousel-item-left,
                > .carousel-item-prev.carousel-item-right,
                > .active {
                    left: 0;
                }
                @for $j from 2 through ($i - 1) {
                    .cloneditem-#{$j} {
                        display: block;
                    }
                }
            }
        }
    }

// /ITEMS LG

}


.carousel-inner > .carousel-item > img { margin: 0 auto; }