.btn {
    border: none;

    &:focus,
    &.focus,
    &:active,
    &.active { box-shadow: none !important; }

    &.btn-with-icon { font-size: 1.4rem; }

    .icon-wrapper {
        width: 2.6em;
        height: 2.6em;
        line-height: 1;
        font-size: 2.1rem;
        box-shadow: -5px 3px 7px rgba($black, 0.4);

        .icon {
            display: inline-block;
            width: 1.3em;
        }

        @include media-breakpoint-up(lg) { font-size: 2.15rem; }

        @include media-breakpoint-up(xl) { font-size: 2.5rem; }
    }
}

.btn-black {
    color: $white;
    background-color: $black;
    border-color: $black;
}

.btn-white {
    color: $body-color;
    background-color: $white;
    border-color: $white;
}

.btn-rectangle {
    font-family: $steelfish;
    line-height: 1.43;
    text-transform: uppercase;
    font-weight: 400;
    font-style: italic;
    font-size: 2rem;
    padding: 0.1em 0.4em 0.1em 0.2em;
}

.btn.with-shadow {
    box-shadow: -0.1em 0.1em 1px rgba($black, 0.3);
}
.btn.with-large-shadow {
    box-shadow: -0.2em 0.2em 1px rgba($black, 0.3);
}