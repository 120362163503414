@include media-breakpoint-down(md) {
    .custom-modal-open { overflow: hidden; }
}

body {
    // padding-top: map-get($navbar-height, xs);

    @include media-breakpoint-up(lg) {
        // padding-top: $submenu-height;
    }
}

.navbar-main {
    padding: 0;
    height: map-get($navbar-height, xs);
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {

        &:not(.no-submenu) {
            margin-bottom: $submenu-height;
        }

        &.at-home {
            margin-bottom: 0;
        }
    }

    .navbar-brand-wrapper {
        display: flex;
        background: $primary;
        align-items: center;
        height: 100%;
        // padding-left: 20px;

        @include media-breakpoint-up(lg) { background: transparent; }

        .navbar-brand {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 1.33rem;
            padding-right: 1.33rem;
            margin: 0px;
            height: 100%;
            color: $white;

            @include media-breakpoint-up(lg) { color: $blue; }
        }

        .logo {
            display: block;
            width: 120px;
            max-width: 100%;

            @include media-breakpoint-up(lg) { width: 145px; }
        }

        @include media-breakpoint-down(md) {
            flex: 1;
        }
    }

    .main-menu {

        .navbar-nav {
            color: $white;
        }

        .nav-item {
            color: $gray;
        }
    }

    @include media-breakpoint-down(md) {

        &.at-home {

            .main-menu {

                .nav-item {
                    color: $white;
                }
            }

            .nav-item:hover {
                transition: none !important;
                color: $magenta;
            }
        }
    }

    .navbar-nav {
        padding-top: 3em;

        @include media-breakpoint-up(lg) {
            width: 100%;
            text-align: center;
            padding: 0;
        }

        .nav-item {
            opacity: 0;
            transform: translateX(30px);
            font-size: 1.3rem;

            @include media-breakpoint-up(lg) {
                width: 100%;
                white-space: nowrap;

                &.whatsapp-item {
                    max-width: map-get($navbar-height, lg);
                }
            }

            .nav-link {
                font-size: 1.22rem;
                padding-top: 0.9rem;
                padding-bottom: 0.9rem;
                padding-left: 1.45em;
                color: inherit;

                &:hover { color: inherit; }

                @include media-breakpoint-up(lg) {
                    font-size: 1.29rem;
                    padding: 0 1rem;
                    line-height: map-get($navbar-height, lg);
                    transition: color 0.4s ease;

                    &:hover { color: $body-color; }
                }

                @include media-breakpoint-up(xl) {
                    font-size: 1.4rem;
                }
            }

            .whatsapp-link {

                @include media-breakpoint-up(lg) {
                    color: rgba($whatsapp, 0.7);

                    .icon {
                        margin: 0;

                        .stroke-me { stroke: currentColor; }
                    }

                    &:hover {
                        color: $whatsapp;
                    }
                }
            }

            .submenu {
                font-weight: $font-weight-light;
                display: none;
                padding: 0;
                font-size: 0.98rem;
                list-style: none;

                @include media-breakpoint-down(md) {
                    &.d-xs-none { display: none !important; }
                }

                .submenu-item {

                    .submenu-link {
                        text-decoration: none;
                        display: block;
                        padding: 1em 0 1em 4.8em;
                        border-bottom: 1px solid $dark-gray;

                        &:first-of-type {
                            border-top: 1px solid $dark-gray;
                        }
                    }

                    &.active {
                        .submenu-link {
                            background: $dark-gray;

                            @include media-breakpoint-up(lg) {
                                text-decoration: underline;
                            }
                        }
                    }

                    &:hover {
                        .submenu-link {
                            @media (pointer: fine) {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                a { color: $white; }
            }

            &.active {
                color: $white;
                font-weight: 500;

                .submenu {
                    display: block;
                }
            }

            &.active-lg {
                @include media-breakpoint-up(lg) {
                    color: $white;
                    font-weight: 500;

                    .submenu {
                        display: block;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                opacity: 1;
                transform: none;
                color: $gray;
                font-family: $headings-font-family;

                .submenu {
                    position: fixed;
                    top: map-get($navbar-height, lg);
                    left: 0;
                    right: 0;
                    background: $blue;
                    height: $submenu-height;

                    .submenu-item {
                        width: 100%;
                        max-width: 200px;

                        .submenu-link {
                            padding: 0.8em 1.4em;

                            &:first-of-type {
                                border: none;
                            }
                        }

                        &.active {

                            .submenu-link {
                                background: none;
                                font-weight: 500;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.active,
                &.active-lg {
                    color: $white;
                    background: $black;

                    .nav-link:hover { color: $gray; }

                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .icon {
            margin-right: 0.9em;
            vertical-align: bottom;
            width: 1.2em;

            @include media-breakpoint-up(lg) {
                width: 1.4em;
                vertical-align: text-top;
            }
        }
    }

    .lightning-icon {

        svg {
            height: 35px;
            fill: $white;
        }
    }

    .btns-wrapper {
        display: flex;
        height: 100%;

        .btn {
            font-size: 1rem;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 1em;
            padding-left: 1em;
            line-height: map-get($navbar-height, xs);
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.navbar-toggler .menu-icon {
                position: relative;
                width: 22px;
                height: 3px;
                background: $white;
                transition: all 0.4s ease;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: $white;
                    transition: all 0.4s ease;
                    transform-origin: center center;
                }

                &:before {
                    top: -7px;
                }
                &:after {
                    bottom: -7px;
                }
            }

            @include media-breakpoint-up(sm) { font-size: 1.1rem; }
            @include media-breakpoint-up(lg) {
                font-size: 0.95rem;
                line-height: 1;
                padding-right: 0.5em;
                padding-left: 0.5em;
            }
        }

        .mcb-icon {
            width: 39px;
            margin-bottom: -2px;
        }

        .whatsapp-btn {
            display: inline-flex;
            align-items: center;
            color: $whatsapp;
            padding: 0 1em;

            .icon { width: 18px; }

            @include media-breakpoint-up(lg) {
                background: $primary;
            }
        }
    }

    &.is-active {
        .btns-wrapper .btn.navbar-toggler {
            .menu-icon {
                background: none;

                &:before {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &:after {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        height: map-get($navbar-height, lg);
    }

    @include media-breakpoint-down(md) {

        .main-menu {
            position: fixed;
            overflow: auto;
            top: map-get($navbar-height, xs);
            right: 0;
            bottom: 0;
            left: 0;
            background: $black;
            opacity: 0;
            transform: translateX(-100vw);
            pointer-events: none;
            transition: opacity 0.4s ease;
        }

        &.is-active {

            .main-menu {
                transform: none;
                pointer-events: auto;
                opacity: 1;
            }

            .nav-item {
                opacity: 1;
                transform: none;

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition: opacity 0.4s ease 0.2s * $i, transform 0.4s ease 0.2s * $i, color 0.4s ease;
                    }
                }
            }
        }
    }
}