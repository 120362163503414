.promo-week {
    font-size: 1.3rem;

    a {
        &:hover {
            color: rgba($white, 0.8) !important;
            text-decoration: none;
        }
    }
}

.promo-modal {
    .modal-dialog {
        @include media-breakpoint-up(md) {
            max-width: 600px;
        }
    }

    .close { font-size: 2.5rem; }

    .btn-main {
        margin-top: -10px;
        line-height: 1.5;
        font-size: 1.2rem;
        margin-top: -1.4rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }
    }

    .promo-share-item {
        padding: 10px 0;

        .icon {
            width: 20px;
            vertical-align: bottom;
            margin-right: 0.7em;
        }

        .whatsapp-icon { color: $whatsapp; }
        .messenger-icon { color: #0682fb; }
        .mail-icon { color: #ef4736; }
    }
}