.share-bgs-wrapper {
    position: relative;

    > .row {
        position: relative;
        z-index: 2;
    }
}


.share-bg {
    background: url('../../img/animated-crosses.gif') center center no-repeat;
    background-size: contain;
    width: 163px;
    height: 85px;
    position: absolute;
    z-index: 1;

    &.bg-01 {
        top: -40px;
        left: 0;
        transform: scaleX(-1);
    }
    &.bg-02 {
        top: 60%;
        left: 0;
    }
    &.bg-03 {
        top: 40%;
        right: 0;
    }
    &.bg-04 {
        top: 80%;
        right: 0;
    }
    &.bg-05 {
        top: 62%;
        right: 30%;
        transform: scaleX(-1);
    }
}

.template-img-wrapper {
    display: block;
    position: relative;
    padding-bottom: 54.63%;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.template-social-btns {
    justify-content: center;

    > div {
        margin-left: 5px;
        margin-right: 5px;
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        min-height: 47px;
        margin-top: -47px;
    }
}

.clipboard-btn-icon {
    width: 20px;
    margin-right: 7px;
}

.template-share-btn {
    max-width: 170px;
    font-size: 1.25rem;

    .icon {
        box-shadow: -5px -5px rgba($black, 0.2);
    }

    svg {
        width: 60%;
    }
}