.home-experience {
    position: relative;
    background: url('../../img/office.jpg') center center no-repeat;
    background-size: cover;
    min-height: 56.25vw;
    cursor: pointer;

    @media (min-width: 1440px) {
        min-height: 0;
        height: 810px;
    }

    span { text-decoration: inherit; }

    @include media-breakpoint-up(md) {
        padding-top: 0 !important;
        display: flex;
        align-items: center;
    }

    .title-wrapper {
        @media (min-width: 1440px) {
            width: 1410px;
            margin: auto;
        }
    }

    .experience-title {
        font-size: 10.5vw;

        @include media-breakpoint-up(md) {
            font-size: 13.5vw;
            width: calc(50vw - 100px);

            .big {
                font-size: 1.3em;
            }
        }

        @media (min-width: 1440px) {
            width: 620px;
            font-size: 181px;
        }
    }

    .play-vid {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .icon {
            display: inline-block;
            width: 40px;

            @include media-breakpoint-up(sm) { width: 50px; }
            @include media-breakpoint-up(md) { width: 70px; }
            @include media-breakpoint-up(lg) { width: 100px; }
        }
    }

    @media (pointer: fine) {
        .play-vid {
            transition: opacity 0.4s ease;
            opacity: 0.4;
        }

        &:hover {
            .play-vid {
                transition-delay: 0.4s;
                opacity: 1;
            }
        }
    }
}