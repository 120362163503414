.social-links {

    a {
        text-decoration: none;

        .icon {
            width: 44px;
            margin: 0 5px;
            transition: transform 0.4s ease;

            &:hover {
                transform: scale(1.1);

                .white-fill { fill: $gray; }
            }
        }

        &:first-of-type .icon { margin-left: 0; }
        &:last-of-type .icon { margin-right: 0; }
    }
}