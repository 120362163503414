@keyframes heroFadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
        pointer-events: auto;
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
        pointer-events: none;
    }
}

@keyframes bounce-right {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40%, 60% {
        transform: translateX(-10px);
    }
}