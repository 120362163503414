.form-smaller-title {
    @include media-breakpoint-down(xs) { font-size: 60%; }
}

.large-form-toggler {
    cursor: pointer;
    padding: 10px;

    .icon {
        height: 0.8em;
        vertical-align: middle;
        animation: bounce-right 2s ease infinite;
    }
    span {
        padding-left: 10px;
        vertical-align: middle;
    }
}

.large-form-content-wrapper {
    overflow: hidden;

    &.bg-light-gray {
        .large-form-content {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .large-form-content {
        display: none;
    }

    &.active {
        .large-form-toggler {
            .icon {
                animation: none;
                transform: rotate(90deg);
            }
        }

        .large-form-content {
            display: flex;
        }
    }

    #file-upload {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        width: 0;
        height: 0;
    }

    .file-upload-btn {
        width: 50px;
        height: 50px;

        .icon {
            height: 25px;
        }
    }
}