.title-wrapper {

    h1 {
        font-size: 4.3rem;

        &.steelfish { font-size: 5.6rem; }
    }

    .subtitle {
        @include media-breakpoint-up(sm) { font-size: 1.54rem; }
    }
}

.create-items {

    .item-img-wrapper {
        display: block;
        max-width: 211px;
        margin: auto;
    }

    .item-img {
        position: relative;
        overflow: hidden;
        display: block;
        padding-bottom: 116%;

        img {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    .item-title-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        br {
            display: none;

            @include media-breakpoint-up(lg) { display: block; }
        }

        @include media-breakpoint-up(md) {
            bottom: 20%;
            left: 15%;
            right: 15%;
            top: auto;
        }
    }

    .item-title {
        font-size: 1.1rem;
        padding: 0px 4px;
        background: $blue;

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
    }

    .create-item {
        display: block;
        position: relative;
    }

    &.randomized {
        .create-item.active {
            .item-img {
                img { transform: translateX(-50%); }
            }

            .item-title { background: $magenta; }
        }
    }

    &:not(.randomized) .create-item {
        @media (pointer: fine) {
            &:hover {
                .item-img {
                    img { transform: translateX(-50%); }
                }

                .item-title { background: $magenta; }
            }
        }
    }
}

.master-iframe {
    display: block;
    width: 100%;
    min-height: calc(100vh - #{map-get($navbar-height, xs)});
    border: none;

    @include media-breakpoint-up(lg) {
        height: 700px;
        min-height: 0;
        max-height: calc(100vh - #{map-get($navbar-height, lg)});
    }
}