#raffles {
    #full-form {
        display: none;
    }
    &.validated #full-form {
        display: block;
    }
    p.help-block {
        padding-top: 5px;
        color: $gray-600;
        font-style: italic;
        font-style: 0.8em;
    }
    button.disabled {
        background: $gray-500 !important;
    }
}

#raffles-success {
    display: none;
    .font-blue { color: $blue; }
    .font-magenta { color: $magenta; }
    img.pointer {
        width: 40px;
    }
    p {
        color: $gray-800;
        text-align: center;
        font-size: 1.3em;
    }
    .raffle {
        position: relative;
        #raffle-number {
            position: absolute;
            top: 45px;
            left: 30%;
            font-size: 150%;
            font-weight: bold;
        }
        #raffle-number-sm {
            position: absolute;
            top: 10px;
            left: 30%;
            font-weight: bold;
        }
    }
}

#raffles-msg {
    display: none;
}

#raffles-loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    h1 {
        text-align: center;
        padding-top: 200px;
        color: #fff;
    }
}