body.fullprints-menu-open {
    @include media-breakpoint-down(sm) { overflow: hidden;}
}

.inspire-fullprints {
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding: 25px 0;
}

.fullprints-navigation {
    position: absolute;
    top: 0;
    height: calc(100% - 30px);
    z-index: 10;

    .icon { width: 10px; }
    &:focus { box-shadow: none; }
    &.fullprints-btn-prev { left: calc(-4vw - 30px); }
    &.fullprints-btn-next { right: calc(-4vw - 30px); }
    &.disabled {
        z-index: 1;
        opacity: 0.3;
    }

    @include media-breakpoint-up(sm) {
        height: calc(100% - 40px);

        .icon { width: 15px; }
        &.fullprints-btn-prev { left: calc(-4vw - 40px); }
        &.fullprints-btn-next { right: calc(-4vw - 40px); }
    }
}

.inspire-fullprint {
    position: absolute;
    cursor: pointer;
    margin: 0 -4vw;
    opacity: 0;
    pointer-events: none;

    &.mob-prev,
    &.mob-next,
    &.mob-prev-prev,
    &.mob-next-next,
    &.mob-current {
        transition: opacity 0.4s ease;
        position: relative;
        opacity: 1;
        pointer-events: auto;
    }

    &.mob-prev,
    &.mob-current { z-index: 2; }
    &.mob-next { z-index: 1;}

    @include media-breakpoint-up(md) {
        position: relative;
        z-index: 1 !important;
        margin: 0 -25px;
        opacity: 1;
        pointer-events: auto;

        &:nth-of-type(4) {
            z-index: 4 !important;

            $sel: '';
            @for $i from 1 to 4 {
                $sel: $sel + ' + .inspire-fullprint';
                #{$sel} {
                    z-index: #{4 - $i} !important;
                }
            }
        }
    }

    .image {
        background: $white;
        border: 3px solid $gray;
        border-radius: 50%;
        padding: 5px;
        overflow: hidden;
        transition: all 0.4s ease;

        img {
            opacity: 0.8;
            border-radius: 50%;
            max-width: 29vw;

            @include media-breakpoint-up(md) {
                max-width: 16vw;
            }
        }
    }

    .text {
        display: inline-block;
        font-size: 0.75rem;
        color: $gray;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 15px;
        line-height: 15px;

        @include media-breakpoint-up(sm) {
            font-size: 1rem;
            margin-top: 20px;
            line-height: 20px;
        }

        @include media-breakpoint-up(md) {
            font-size: 0.85rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1rem;
        }
    }

    &.current {
        @include media-breakpoint-up(md) {
            .image {
                border-color: $black;

                img {
                    opacity: 1;
                }
            }

            .text {
                color: $black;

                @include media-breakpoint-up(lg) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &:hover {
        .image {
            border-color: $dark-gray;

            img {
                opacity: 1;
            }
        }

        .text { color: $black }
    }
}

.inspire-fullprints-show {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: $zindex-fixed;
    opacity: 0;
    transform: translateX(-100vw);
    pointer-events: none;
    transition: opacity 0.4s ease;
    color: $white;
    background: rgba($black, 0.8);
    padding-top: 48px;

    &.is-active {
        opacity: 1;
        transform: none;
        pointer-events: auto;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        transform: none;
        pointer-events: auto;
        z-index: 1;
        padding-top: 0;
        height: 0;
        background:$light-gray;

        &.is-active {
            height: auto;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .category-name,
    .name {
        position: absolute;
        text-transform: uppercase;
        font-family: $oswald;
        font-size: 1.6rem;
        top: 0;
        left: 0;
        right: 0;
        text-align: left;
        padding-left: 40px;
        background: $black;
        line-height: 48px;
        z-index: 1;
    }

    .category-name { position: fixed; }

    .inspire-fullprints-show-fullprint {
        position: relative;
        height: 25%;
        border-top: 5px solid $white;
        min-height: 125px;
        transition: all 0.4s ease;

        &.is-active {
            min-height: calc(100% + 48px);
            border: none;

            .name { display: block; }
        }

        .img {
            background-size: cover;
            background-position: center center;
            height: 100%;
        }

        .name {
            display: none;
        }

        @include media-breakpoint-up(md) {
            min-height: 0;
            padding: 20px 25px;
            border: none;

            .img {
                width: 210px;
                height: 210px;
            }

            .name {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                font-family: $roboto;
                padding: 0;
                display: block;
                background: none;
                color: $black;
                text-transform: uppercase;
                text-align: center;
                font-size: 1.25rem;
                line-height: inherit;
                width: 210px;
                padding-bottom: 10px;
            }
        }
    }
}