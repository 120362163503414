#ecommerce.landing-tarjeta {
    #ecommerce-header {
        min-height: calc(100vh - 150px);

        @include media-breakpoint-up(lg) { min-height: calc(100vh - 475px); }

        h1 {
            padding-right: 0;
            padding-left: 0;

            &:after { z-index: -1; }
        }
    }

    .tarjeta-form {
        margin: auto;
        max-width: 500px;
    }

    .btn[type="submit"] {
        font-size: 2.2rem;
        padding-right: 1rem;
    }
}