.team-card {
    padding: 10px;
    margin-bottom: 40px;
    border: 2px solid $light-gray;
    box-shadow: -3px 3px $light-gray;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(lg) {
        padding: 20px 20px 10px;
    }

    small { color: $gray; }

    &:before,
    &:after {
        content: '';
        position: absolute;
    }

    &.card-style-1 {
        &:before {
            height: 6%;
            width: 22%;
            top: -4%;
            left: 50%;
            margin-left: -10%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(-4deg);
        }
    }

    &.card-style-2 {
        &:before {
            top: -2px;
            right: -2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 0 0 20px;
            border-color: $white $white $white $light-gray;
        }
        &:after {
            top: 2px;
            right: 2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 0 0px 14px;
            border-color: transparent transparent transparent $white;
        }
    }

    &.card-style-3 {
        &:before {
            height: 12%;
            width: 6.5%;
            top: -7%;
            left: 20%;
            margin-left: -3%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(-4deg);
        }
    }

    &.card-style-4 {
        &:before {
            height: 12%;
            width: 6.5%;
            top: -7%;
            left: 20%;
            margin-left: -3%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(-4deg);
        }
        &:after {
            height: 12%;
            width: 6.5%;
            top: -7%;
            right: 20%;
            margin-left: 3%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(4deg);
        }
    }

    &.card-style-5 {
        &:before {
            height: 12%;
            width: 6.5%;
            top: -7%;
            left: 20%;
            margin-left: -3%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(-4deg);
        }
        &:after {
            height: 12%;
            width: 6.5%;
            top: -7%;
            right: 20%;
            margin-left: 3%;
            background: $white;
            border: 2px solid $light-gray;
            transform: rotate(4deg);
            z-index: -1;
        }
    }
}