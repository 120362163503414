$exp-height-lg: map-get($navbar-height, lg) + $submenu-height;

.experience-section {
    overflow: hidden;
    position: relative;
    background: $white;
    background-size: cover;
    background-position: center center;
    color: $white;

    &.bg-white { color: $body-color; }

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(100vh - #{map-get($navbar-height, xs)});
        padding-top: 80px;
        padding-bottom: 80px;

        .exp-content {
            position: relative;
            z-index: 2;
        }
    }

    @include media-breakpoint-up(lg) {
        min-height: calc(100vh - #{$exp-height-lg});
    }

    .experience-title,
    h1.experience-title {
        @extend .steelfish;
        font-size: 3.9rem;
        line-height: 1.35;
        // letter-spacing: -2px;
        margin-bottom: 18px;
        margin-top: -42px;

        @include media-breakpoint-up(md) {
            margin-top: 18px;
            font-size: 5.1rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 6.75rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 8.35rem;
        }

        span[class^="bg-"],
        span[class*=" bg-"] {
            display: inline-block;
            padding: 0 5px;
            line-height: 1.25;

            @include media-breakpoint-up(md) {
                padding: 0 8px;
            }
        }

        .icon {
            fill: currentColor;
            height: 0.8em;
        }
    }

    p {
        @include media-breakpoint-up(md) {
            font-size: 1.4rem;
            line-height: 1.43;
            font-weight: 400;
        }
    }

    .scroll {
        position: absolute;
        z-index: 2;
        display: none;
        margin: auto;
        color: inherit;
        border: 3px solid;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        left: calc(50% - 25px);
        bottom: 20px;

        @include media-breakpoint-up(md) { display: block; }

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 15px;
            height: 3px;
            background: currentColor;
            top: 27px;
        }
        &:before {
            right: 50%;
            transform-origin: right bottom;
            transform: rotate(45deg);
        }
        &:after {
            left: 50%;
            transform-origin: left bottom;
            transform: rotate(-45deg);
        }
    }

    @for $i from 1 through 10 {

        &:nth-child(#{$i}) {

            .exp-overlay {
                background-image: url('../../img/experience/' + $i + '.jpg');
            }

            @include media-breakpoint-up(md) {
                background-image: url('../../img/experience/' + $i + '.jpg');

                .exp-overlay { background-image: none; }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .exp-animate {
            opacity: 0;
        }
        .exp-left { transform: translateX(-70px); }
        .exp-right { transform: translateX(70px); }
        .exp-top { transform: translateY(-70px); }
        .exp-bottom { transform: translateY(70px); }

        &.active {
            .exp-animate {
                opacity: 1;
                transform: none;
                transition: opacity 0.4s ease, transform 0.4s ease;
            }

            @for $i from 1 through 6 {
                .delay-#{$i} {
                    transition-delay: $i * 0.2s;
                }
            }
        }
    }
}

#experience-section-11 {

    @include media-breakpoint-down(sm) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .experience-title { margin-top: 0; }

    .rse-link {
        color: inherit;
        font-weight: 400;
        transition: all 0.4s ease;

        &.active {
            color: $magenta;
        }
    }

    .rse-icon {
        display: inline-block;
        border: 3px solid $magenta;
        border-radius: 50%;
        margin: 5px 10px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.4s ease;

        &:hover,
        &.active {
            background: $magenta;
            padding: 15px;
            margin: 0 5px;
        }
    }

    p {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}

.exp-overlay {
    height: 40vh;
    background-size: cover;
    background-position: center center;

    @media (orientation: landscape) { height: 40vw; }

    @include media-breakpoint-up(md) {
        position: absolute;
        height: auto;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: inherit;
        opacity: 0.7;
        transition: all 0.6s ease;

        .bg-white & { opacity: 0.8; }

        &.left { left: 0; }
        &.right { right: 0; }
        .active & { width: 100%; }
    }
}