.inspire-fabric {
    padding-top: 15px;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .img {
        display: inline-block;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
            border-top: 7px solid $white;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
        }
    }

    img {
        display: block;
        border: 3px solid $white;
        border-radius: 50%;
    }

    .name {
        padding-top: 10px;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1.35;
        @include media-breakpoint-up(sm) { font-size: 0.85em; }
    }
}

.fabric-modal {
    .carousel-caption {
        position: static;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($black, 0.5);
        padding-left: 45px;
        padding-right: 45px;
        text-align: left;

        @include media-breakpoint-up(md) { position: absolute; }

        ul { padding-left: 1.2rem; }
    }

    @include media-breakpoint-down(sm) {
        .carousel-inner {
            width: 100%;
            max-width: none;
            height: auto;
        }

        .carousel-item-content {
            height: 100vh;
            flex-direction: column;

            @media (orientation: landscape) {
                flex-direction: row;
            }
        }

        .carousel-caption {
            background: $black;
            height: 100%;
        }
    }
}

// .fabric-modal {

//     @include media-breakpoint-down(sm) {
//         .modal-dialog {
//             margin: 0;
//             max-width: none;
//             height: 100%;
//         }

//         .modal-content {
//             position: absolute;
//             top: 0;
//             right: 0;
//             bottom: 0;
//             left: 0;
//             background: rgba($black, 0.4);
//         }

//         .image-wrapper {
//             height: 100%;

//             img {
//                 object-fit: cover;
//                 width: 100%;
//                 height: 100%;
//             }
//         }
//     }

//     .name {
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         padding-left: 10px;
//         background: $black;
//         line-height: 2;
//         z-index: 1;
//     }

//     .modal-content { border: 0; }
//     .image-wrapper {

//         @include media-breakpoint-up(md) { height: 500px; }
//         @include media-breakpoint-up(lg) { height: 800px; }
//     }

//     .floating-content {
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         right: 0;
//         padding: 20px;
//         background: rgba($black, 0.5);
//     }
// }