.footer-main {
    background: $dark-gray;
    color: $gray;
    font-size: 0.9rem;

    @include media-breakpoint-up(lg) {
        font-size: 0.8rem;
    }

    ul { margin: 0; }

    a { color: $gray; }

    .footer-legal-images {
        img { vertical-align: bottom; }
    }

    .footer-sitemap {

        a { text-decoration: none; }

        h5 {
            font-weight: 800;
            font-size: 1em;
            text-transform: uppercase;
            margin-bottom: 0.2rem;
        }
    }

    .footer-social {
        h5 {
            font-size: 1.1rem;
        }
    }

    .border-bottom {
        border-bottom: 1px solid currentColor;
    }

    .lb-badge {
        text-transform: uppercase;
        font-size: 0.75rem;

        span {
            width: 90px;
            vertical-align: middle;
        }
    }
}