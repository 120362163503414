@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.instagram-wrapper { padding: 0; }

.instagram-item {
    position: relative;

    .item {
        display: block;
        position: relative;
        background-position: center center;
        background-size: cover;
        padding-bottom: 100%;
        color: $white;
    }

    img {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .loader {
        background: $light-gray;
        padding-bottom: 100%;

        &:before,
        &:after {
            content: '';
            position: absolute;
        }

        &:before {
            border: 2px solid $dark-gray;
            border-radius: 50%;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            width: 20px;
            height: 20px;
        }

        &:after {
            background: inherit;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform-origin: top left;
            animation: rotate 0.7s ease infinite;
        }
    }

    .likes-wrapper {
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
        justify-content: center;
        background: linear-gradient(to top, $black, transparent);
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        @include media-breakpoint-up(md) { font-size: 1.4rem; }

        @media (pointer: fine) {
            align-items: center;
            padding-bottom: 0;
            background: rgba($black, 0.3);
            top: 0;
            opacity: 0;
            transition: opacity 0.4s ease;

            &:hover { opacity: 1; }
        }

        svg {
            margin-right: 0.4em;
            height: 1em;
        }
    }
}

.d-xxl-block {
    @media (min-width: 1440px) {
        display: block !important;
    }
}