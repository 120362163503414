.fadeimg {
    position: relative;
    background-color: $black;
    background-size: 0;
    background-repeat: no-repeat;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:before {
        z-index: 1;
        background-color: inherit;
    }

    &:after {
        z-index: 2;
        background: inherit;
        background-size: cover;
        opacity: 1;
    }

    > * {
        position: relative;
        z-index: 3;
    }

    &:after {
        opacity: 0;
        transition: opacity 0.4s ease;
    }

    &.bg-loaded {
        &:after { opacity: 1; }
    }
}