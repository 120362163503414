/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2b6dca;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #FC214E;
  --orange: #fd7e14;
  --yellow: #FCEE21;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #969696;
  --gray-dark: #404040;
  --primary: #2b6dca;
  --secondary: #d54ab7;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #FCEE21;
  --danger: #FC214E;
  --light: #fafafa;
  --dark: #404040;
  --light-gray: #efefef;
  --gray: #969696;
  --dark-gray: #575757;
  --black: #000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2b6dca;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #1e4b8b;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #969696;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  line-height: 1.15;
  color: inherit; }

h1, .h1 {
  font-size: 3.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.15; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.15; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.15; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.15; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #969696; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #969696; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #292929;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #292929; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e6e6e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e6e6e6; }
  .table tbody + tbody {
    border-top: 2px solid #e6e6e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e6e6e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e6e6e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4d6f0; }

.table-hover .table-primary:hover {
  background-color: #b0c8eb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b0c8eb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f3cceb; }

.table-hover .table-secondary:hover {
  background-color: #eeb7e3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #eeb7e3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fefac1; }

.table-hover .table-warning:hover {
  background-color: #fef8a8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fef8a8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fec1cd; }

.table-hover .table-danger:hover {
  background-color: #fea8b9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fea8b9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cacaca; }

.table-hover .table-dark:hover {
  background-color: #bdbdbd; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbdbd; }

.table-light-gray,
.table-light-gray > th,
.table-light-gray > td {
  background-color: #fbfbfb; }

.table-hover .table-light-gray:hover {
  background-color: #eeeeee; }
  .table-hover .table-light-gray:hover > td,
  .table-hover .table-light-gray:hover > th {
    background-color: #eeeeee; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #e2e2e2; }

.table-hover .table-gray:hover {
  background-color: #d5d5d5; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #d5d5d5; }

.table-dark-gray,
.table-dark-gray > th,
.table-dark-gray > td {
  background-color: #d0d0d0; }

.table-hover .table-dark-gray:hover {
  background-color: #c3c3c3; }
  .table-hover .table-dark-gray:hover > td,
  .table-hover .table-dark-gray:hover > th {
    background-color: #c3c3c3; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #292929;
  border-color: #3c3c3c; }

.table .thead-light th {
  color: #575757;
  background-color: #efefef;
  border-color: #e6e6e6; }

.table-dark {
  color: #fff;
  background-color: #292929; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3c3c3c; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.99px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.99px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.99px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.99px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #575757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dadada;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #575757;
    background-color: #fff;
    border-color: #8eb3e7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.25); }
  .form-control::placeholder {
    color: #969696;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #efefef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2rem + 2px); }

select.form-control:focus::-ms-value {
  color: #575757;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.25;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #969696; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FC214E; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(252, 33, 78, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #FC214E; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #FC214E;
    box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FC214E; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FC214E; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fe9fb2; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #fd5376; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(252, 33, 78, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FC214E; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not([disabled]):not(.disabled) {
    cursor: pointer; }
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2b6dca;
  border-color: #2b6dca; }
  .btn-primary:hover {
    color: #fff;
    background-color: #245caa;
    border-color: #2256a0; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #2b6dca;
    border-color: #2b6dca; }
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2256a0;
    border-color: #205195; }
    .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #d54ab7;
  border-color: #d54ab7; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #ca2fa8;
    border-color: #c02ca0; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 74, 183, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #d54ab7;
    border-color: #d54ab7; }
  .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c02ca0;
    border-color: #b52a97; }
    .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 74, 183, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not([disabled]):not(.disabled):active:focus, .btn-info:not([disabled]):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #292929;
  background-color: #FCEE21;
  border-color: #FCEE21; }
  .btn-warning:hover {
    color: #292929;
    background-color: #f3e403;
    border-color: #e7d803; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 238, 33, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #FCEE21;
    border-color: #FCEE21; }
  .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #292929;
    background-color: #e7d803;
    border-color: #dacd03; }
    .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 238, 33, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #FC214E;
  border-color: #FC214E; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f30335;
    border-color: #e70332; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #FC214E;
    border-color: #FC214E; }
  .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e70332;
    border-color: #da032f; }
    .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.5); }

.btn-light {
  color: #292929;
  background-color: #fafafa;
  border-color: #fafafa; }
  .btn-light:hover {
    color: #292929;
    background-color: #e7e7e7;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #292929;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-light:not([disabled]):not(.disabled):active:focus, .btn-light:not([disabled]):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #404040;
  border-color: #404040; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #272727; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #404040;
    border-color: #404040; }
  .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #272727;
    border-color: #202020; }
    .btn-dark:not([disabled]):not(.disabled):active:focus, .btn-dark:not([disabled]):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-light-gray {
  color: #292929;
  background-color: #efefef;
  border-color: #efefef; }
  .btn-light-gray:hover {
    color: #292929;
    background-color: gainsboro;
    border-color: #d6d6d6; }
  .btn-light-gray:focus, .btn-light-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }
  .btn-light-gray.disabled, .btn-light-gray:disabled {
    background-color: #efefef;
    border-color: #efefef; }
  .btn-light-gray:not([disabled]):not(.disabled):active, .btn-light-gray:not([disabled]):not(.disabled).active,
  .show > .btn-light-gray.dropdown-toggle {
    color: #292929;
    background-color: #d6d6d6;
    border-color: #cfcfcf; }
    .btn-light-gray:not([disabled]):not(.disabled):active:focus, .btn-light-gray:not([disabled]):not(.disabled).active:focus,
    .show > .btn-light-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }

.btn-gray {
  color: #292929;
  background-color: #969696;
  border-color: #969696; }
  .btn-gray:hover {
    color: #fff;
    background-color: #838383;
    border-color: #7d7d7d; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    background-color: #969696;
    border-color: #969696; }
  .btn-gray:not([disabled]):not(.disabled):active, .btn-gray:not([disabled]):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #767676; }
    .btn-gray:not([disabled]):not(.disabled):active:focus, .btn-gray:not([disabled]):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }

.btn-dark-gray {
  color: #fff;
  background-color: #575757;
  border-color: #575757; }
  .btn-dark-gray:hover {
    color: #fff;
    background-color: #444444;
    border-color: #3e3e3e; }
  .btn-dark-gray:focus, .btn-dark-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5); }
  .btn-dark-gray.disabled, .btn-dark-gray:disabled {
    background-color: #575757;
    border-color: #575757; }
  .btn-dark-gray:not([disabled]):not(.disabled):active, .btn-dark-gray:not([disabled]):not(.disabled).active,
  .show > .btn-dark-gray.dropdown-toggle {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #373737; }
    .btn-dark-gray:not([disabled]):not(.disabled):active:focus, .btn-dark-gray:not([disabled]):not(.disabled).active:focus,
    .show > .btn-dark-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5); }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    background-color: #000;
    border-color: #000; }
  .btn-black:not([disabled]):not(.disabled):active, .btn-black:not([disabled]):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-black:not([disabled]):not(.disabled):active:focus, .btn-black:not([disabled]):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-primary {
  color: #2b6dca;
  background-color: transparent;
  background-image: none;
  border-color: #2b6dca; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2b6dca;
    border-color: #2b6dca; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2b6dca;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #292929;
    background-color: #2b6dca;
    border-color: #2b6dca;
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.5); }

.btn-outline-secondary {
  color: #d54ab7;
  background-color: transparent;
  background-image: none;
  border-color: #d54ab7; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #d54ab7;
    border-color: #d54ab7; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 74, 183, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #d54ab7;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #292929;
    background-color: #d54ab7;
    border-color: #d54ab7;
    box-shadow: 0 0 0 0.2rem rgba(213, 74, 183, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #292929;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #292929;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #FCEE21;
  background-color: transparent;
  background-image: none;
  border-color: #FCEE21; }
  .btn-outline-warning:hover {
    color: #292929;
    background-color: #FCEE21;
    border-color: #FCEE21; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 238, 33, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FCEE21;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #292929;
    background-color: #FCEE21;
    border-color: #FCEE21;
    box-shadow: 0 0 0 0.2rem rgba(252, 238, 33, 0.5); }

.btn-outline-danger {
  color: #FC214E;
  background-color: transparent;
  background-image: none;
  border-color: #FC214E; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #FC214E;
    border-color: #FC214E; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FC214E;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #292929;
    background-color: #FC214E;
    border-color: #FC214E;
    box-shadow: 0 0 0 0.2rem rgba(252, 33, 78, 0.5); }

.btn-outline-light {
  color: #fafafa;
  background-color: transparent;
  background-image: none;
  border-color: #fafafa; }
  .btn-outline-light:hover {
    color: #292929;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #fafafa;
    border-color: #fafafa;
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #404040;
  background-color: transparent;
  background-image: none;
  border-color: #404040; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #404040;
    border-color: #404040; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #404040;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #292929;
    background-color: #404040;
    border-color: #404040;
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-outline-light-gray {
  color: #efefef;
  background-color: transparent;
  background-image: none;
  border-color: #efefef; }
  .btn-outline-light-gray:hover {
    color: #292929;
    background-color: #efefef;
    border-color: #efefef; }
  .btn-outline-light-gray:focus, .btn-outline-light-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }
  .btn-outline-light-gray.disabled, .btn-outline-light-gray:disabled {
    color: #efefef;
    background-color: transparent; }
  .btn-outline-light-gray:not([disabled]):not(.disabled):active, .btn-outline-light-gray:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light-gray.dropdown-toggle {
    color: #292929;
    background-color: #efefef;
    border-color: #efefef;
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }

.btn-outline-gray {
  color: #969696;
  background-color: transparent;
  background-image: none;
  border-color: #969696; }
  .btn-outline-gray:hover {
    color: #292929;
    background-color: #969696;
    border-color: #969696; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #969696;
    background-color: transparent; }
  .btn-outline-gray:not([disabled]):not(.disabled):active, .btn-outline-gray:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #292929;
    background-color: #969696;
    border-color: #969696;
    box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }

.btn-outline-dark-gray {
  color: #575757;
  background-color: transparent;
  background-image: none;
  border-color: #575757; }
  .btn-outline-dark-gray:hover {
    color: #fff;
    background-color: #575757;
    border-color: #575757; }
  .btn-outline-dark-gray:focus, .btn-outline-dark-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5); }
  .btn-outline-dark-gray.disabled, .btn-outline-dark-gray:disabled {
    color: #575757;
    background-color: transparent; }
  .btn-outline-dark-gray:not([disabled]):not(.disabled):active, .btn-outline-dark-gray:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark-gray.dropdown-toggle {
    color: #292929;
    background-color: #575757;
    border-color: #575757;
    box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5); }

.btn-outline-black {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-black:not([disabled]):not(.disabled):active, .btn-outline-black:not([disabled]):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #292929;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2b6dca;
  background-color: transparent; }
  .btn-link:hover {
    color: #1e4b8b;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #969696; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #efefef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292929;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #1c1c1c;
    text-decoration: none;
    background-color: #fafafa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2b6dca; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #969696;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #969696;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control,
  .input-group .custom-select,
  .input-group .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus,
    .input-group .custom-select:focus,
    .input-group .custom-file:focus {
      z-index: 3; }
    .input-group .form-control + .form-control,
    .input-group .custom-select + .form-control,
    .input-group .custom-file + .form-control {
      margin-left: -1px; }
  .input-group .form-control:not(:last-child),
  .input-group .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group .form-control:not(:first-child),
  .input-group .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group .custom-file {
    display: flex;
    align-items: center; }
    .input-group .custom-file:not(:last-child) .custom-file-control,
    .input-group .custom-file:not(:last-child) .custom-file-control::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group .custom-file:not(:first-child) .custom-file-control,
    .input-group .custom-file:not(:first-child) .custom-file-control::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex;
  align-items: center; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #575757;
  text-align: center;
  white-space: nowrap;
  background-color: #efefef;
  border: 1px solid #dadada;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.25rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #2b6dca; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(43, 109, 202, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b8cff0; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #969696; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #efefef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.125rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #e6e6e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.125rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2b6dca; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #2b6dca; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2b6dca; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #575757;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23404040' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #dadada;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #8eb3e7;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(43, 109, 202, 0.25); }
    .custom-select:focus::-ms-value {
      color: #575757;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #969696;
    background-color: #efefef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #8eb3e7;
    box-shadow: 0 0 0 0.2rem rgba(43, 109, 202, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #8eb3e7; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.25;
  color: #575757;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.25;
    color: #575757;
    content: "Browse";
    background-color: #efefef;
    border-left: 1px solid #dadada;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #969696; }

.nav-tabs {
  border-bottom: 1px solid #e6e6e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #efefef #efefef #e6e6e6; }
    .nav-tabs .nav-link.disabled {
      color: #969696;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #575757;
    background-color: #fff;
    border-color: #e6e6e6 #e6e6e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2b6dca; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.34375rem;
  padding-bottom: 0.34375rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:not([disabled]):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.99px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.99px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.99px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.99px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #efefef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #969696;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #969696; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2b6dca;
  background-color: #fff;
  border: 1px solid #e6e6e6; }
  .page-link:focus, .page-link:hover {
    color: #1e4b8b;
    text-decoration: none;
    background-color: #efefef;
    border-color: #e6e6e6; }
  .page-link:not([disabled]):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2b6dca;
  border-color: #2b6dca; }

.page-item.disabled .page-link {
  color: #969696;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e6e6e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2b6dca; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #2256a0; }

.badge-secondary {
  color: #fff;
  background-color: #d54ab7; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #c02ca0; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #292929;
  background-color: #FCEE21; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #292929;
    text-decoration: none;
    background-color: #e7d803; }

.badge-danger {
  color: #fff;
  background-color: #FC214E; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #e70332; }

.badge-light {
  color: #292929;
  background-color: #fafafa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #292929;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-dark {
  color: #fff;
  background-color: #404040; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #272727; }

.badge-light-gray {
  color: #292929;
  background-color: #efefef; }
  .badge-light-gray[href]:focus, .badge-light-gray[href]:hover {
    color: #292929;
    text-decoration: none;
    background-color: #d6d6d6; }

.badge-gray {
  color: #292929;
  background-color: #969696; }
  .badge-gray[href]:focus, .badge-gray[href]:hover {
    color: #292929;
    text-decoration: none;
    background-color: #7d7d7d; }

.badge-dark-gray {
  color: #fff;
  background-color: #575757; }
  .badge-dark-gray[href]:focus, .badge-dark-gray[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #3e3e3e; }

.badge-black {
  color: #fff;
  background-color: #000; }
  .badge-black[href]:focus, .badge-black[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: black; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #efefef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #163969;
  background-color: #d5e2f4;
  border-color: #c4d6f0; }
  .alert-primary hr {
    border-top-color: #b0c8eb; }
  .alert-primary .alert-link {
    color: #0d223f; }

.alert-secondary {
  color: #6f265f;
  background-color: #f7dbf1;
  border-color: #f3cceb; }
  .alert-secondary hr {
    border-top-color: #eeb7e3; }
  .alert-secondary .alert-link {
    color: #49193e; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #837c11;
  background-color: #fefcd3;
  border-color: #fefac1; }
  .alert-warning hr {
    border-top-color: #fef8a8; }
  .alert-warning .alert-link {
    color: #56510b; }

.alert-danger {
  color: #831129;
  background-color: #fed3dc;
  border-color: #fec1cd; }
  .alert-danger hr {
    border-top-color: #fea8b9; }
  .alert-danger .alert-link {
    color: #560b1b; }

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #212121;
  background-color: #d9d9d9;
  border-color: #cacaca; }
  .alert-dark hr {
    border-top-color: #bdbdbd; }
  .alert-dark .alert-link {
    color: #080808; }

.alert-light-gray {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-light-gray hr {
    border-top-color: #eeeeee; }
  .alert-light-gray .alert-link {
    color: #636363; }

.alert-gray {
  color: #4e4e4e;
  background-color: #eaeaea;
  border-color: #e2e2e2; }
  .alert-gray hr {
    border-top-color: #d5d5d5; }
  .alert-gray .alert-link {
    color: #353535; }

.alert-dark-gray {
  color: #2d2d2d;
  background-color: #dddddd;
  border-color: #d0d0d0; }
  .alert-dark-gray hr {
    border-top-color: #c3c3c3; }
  .alert-dark-gray .alert-link {
    color: #141414; }

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-black hr {
    border-top-color: #ababab; }
  .alert-black .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #efefef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #2b6dca;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #575757;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #575757;
    text-decoration: none;
    background-color: #fafafa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #efefef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #969696;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2b6dca;
    border-color: #2b6dca; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #163969;
  background-color: #c4d6f0; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #163969; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #163969;
    background-color: #b0c8eb; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #163969;
    border-color: #163969; }

.list-group-item-secondary {
  color: #6f265f;
  background-color: #f3cceb; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #6f265f; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #6f265f;
    background-color: #eeb7e3; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #6f265f;
    border-color: #6f265f; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #837c11;
  background-color: #fefac1; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #837c11; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #837c11;
    background-color: #fef8a8; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #837c11;
    border-color: #837c11; }

.list-group-item-danger {
  color: #831129;
  background-color: #fec1cd; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #831129; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #831129;
    background-color: #fea8b9; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #831129;
    border-color: #831129; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #828282; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #828282;
    background-color: #f1f1f1; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #212121;
  background-color: #cacaca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #212121; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #212121;
    background-color: #bdbdbd; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }

.list-group-item-light-gray {
  color: #7c7c7c;
  background-color: #fbfbfb; }

a.list-group-item-light-gray,
button.list-group-item-light-gray {
  color: #7c7c7c; }
  a.list-group-item-light-gray:focus, a.list-group-item-light-gray:hover,
  button.list-group-item-light-gray:focus,
  button.list-group-item-light-gray:hover {
    color: #7c7c7c;
    background-color: #eeeeee; }
  a.list-group-item-light-gray.active,
  button.list-group-item-light-gray.active {
    color: #fff;
    background-color: #7c7c7c;
    border-color: #7c7c7c; }

.list-group-item-gray {
  color: #4e4e4e;
  background-color: #e2e2e2; }

a.list-group-item-gray,
button.list-group-item-gray {
  color: #4e4e4e; }
  a.list-group-item-gray:focus, a.list-group-item-gray:hover,
  button.list-group-item-gray:focus,
  button.list-group-item-gray:hover {
    color: #4e4e4e;
    background-color: #d5d5d5; }
  a.list-group-item-gray.active,
  button.list-group-item-gray.active {
    color: #fff;
    background-color: #4e4e4e;
    border-color: #4e4e4e; }

.list-group-item-dark-gray {
  color: #2d2d2d;
  background-color: #d0d0d0; }

a.list-group-item-dark-gray,
button.list-group-item-dark-gray {
  color: #2d2d2d; }
  a.list-group-item-dark-gray:focus, a.list-group-item-dark-gray:hover,
  button.list-group-item-dark-gray:focus,
  button.list-group-item-dark-gray:hover {
    color: #2d2d2d;
    background-color: #c3c3c3; }
  a.list-group-item-dark-gray.active,
  button.list-group-item-dark-gray.active {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d; }

.list-group-item-black {
  color: black;
  background-color: #b8b8b8; }

a.list-group-item-black,
button.list-group-item-black {
  color: black; }
  a.list-group-item-black:focus, a.list-group-item-black:hover,
  button.list-group-item-black:focus,
  button.list-group-item-black:hover {
    color: black;
    background-color: #ababab; }
  a.list-group-item-black.active,
  button.list-group-item-black.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not([disabled]):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #efefef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.25; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #efefef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2b6dca !important; }

a.bg-primary:focus, a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #2256a0 !important; }

.bg-secondary {
  background-color: #d54ab7 !important; }

a.bg-secondary:focus, a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #c02ca0 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #FCEE21 !important; }

a.bg-warning:focus, a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e7d803 !important; }

.bg-danger {
  background-color: #FC214E !important; }

a.bg-danger:focus, a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e70332 !important; }

.bg-light {
  background-color: #fafafa !important; }

a.bg-light:focus, a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #404040 !important; }

a.bg-dark:focus, a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #272727 !important; }

.bg-light-gray {
  background-color: #efefef !important; }

a.bg-light-gray:focus, a.bg-light-gray:hover,
button.bg-light-gray:focus,
button.bg-light-gray:hover {
  background-color: #d6d6d6 !important; }

.bg-gray {
  background-color: #969696 !important; }

a.bg-gray:focus, a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #7d7d7d !important; }

.bg-dark-gray {
  background-color: #575757 !important; }

a.bg-dark-gray:focus, a.bg-dark-gray:hover,
button.bg-dark-gray:focus,
button.bg-dark-gray:hover {
  background-color: #3e3e3e !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:focus, a.bg-black:hover,
button.bg-black:focus,
button.bg-black:hover {
  background-color: black !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #efefef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2b6dca !important; }

.border-secondary {
  border-color: #d54ab7 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #FCEE21 !important; }

.border-danger {
  border-color: #FC214E !important; }

.border-light {
  border-color: #fafafa !important; }

.border-dark {
  border-color: #404040 !important; }

.border-light-gray {
  border-color: #efefef !important; }

.border-gray {
  border-color: #969696 !important; }

.border-dark-gray {
  border-color: #575757 !important; }

.border-black {
  border-color: #000 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2b6dca !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #2256a0 !important; }

.text-secondary {
  color: #d54ab7 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #c02ca0 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #FCEE21 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #e7d803 !important; }

.text-danger {
  color: #FC214E !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #e70332 !important; }

.text-light {
  color: #fafafa !important; }

a.text-light:focus, a.text-light:hover {
  color: #e1e1e1 !important; }

.text-dark {
  color: #404040 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #272727 !important; }

.text-light-gray {
  color: #efefef !important; }

a.text-light-gray:focus, a.text-light-gray:hover {
  color: #d6d6d6 !important; }

.text-gray {
  color: #969696 !important; }

a.text-gray:focus, a.text-gray:hover {
  color: #7d7d7d !important; }

.text-dark-gray {
  color: #575757 !important; }

a.text-dark-gray:focus, a.text-dark-gray:hover {
  color: #3e3e3e !important; }

.text-black {
  color: #000 !important; }

a.text-black:focus, a.text-black:hover {
  color: black !important; }

.text-muted {
  color: #969696 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

@keyframes heroFadeLeft {
  from {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto; }
  to {
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none; } }

@keyframes bounce-right {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0); }
  40%, 60% {
    transform: translateX(-10px); } }

.btn {
  border: none; }
  .btn:focus, .btn.focus, .btn:active, .btn.active {
    box-shadow: none !important; }
  .btn.btn-with-icon {
    font-size: 1.4rem; }
  .btn .icon-wrapper {
    width: 2.6em;
    height: 2.6em;
    line-height: 1;
    font-size: 2.1rem;
    box-shadow: -5px 3px 7px rgba(0, 0, 0, 0.4); }
    .btn .icon-wrapper .icon {
      display: inline-block;
      width: 1.3em; }
    @media (min-width: 992px) {
      .btn .icon-wrapper {
        font-size: 2.15rem; } }
    @media (min-width: 1200px) {
      .btn .icon-wrapper {
        font-size: 2.5rem; } }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000; }

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff; }

.btn-rectangle {
  font-family: "Steelfish", sans-serif;
  line-height: 1.43;
  text-transform: uppercase;
  font-weight: 400;
  font-style: italic;
  font-size: 2rem;
  padding: 0.1em 0.4em 0.1em 0.2em; }

.btn.with-shadow {
  box-shadow: -0.1em 0.1em 1px rgba(0, 0, 0, 0.3); }

.btn.with-large-shadow {
  box-shadow: -0.2em 0.2em 1px rgba(0, 0, 0, 0.3); }

.carousel-item-next,
.carousel-item-prev {
  z-index: 2; }

[class^="carousel-control"] {
  z-index: 3; }

.carousel-multiple-items .cloneditem-1 {
  display: none; }

.carousel-multiple-items .cloneditem-2 {
  display: none; }

.carousel-multiple-items .cloneditem-3 {
  display: none; }

.carousel-multiple-items .cloneditem-4 {
  display: none; }

.carousel-multiple-items .cloneditem-5 {
  display: none; }

.carousel-multiple-items .cloneditem-6 {
  display: none; }

.carousel-multiple-items .cloneditem-7 {
  display: none; }

.carousel-multiple-items .cloneditem-8 {
  display: none; }

.carousel-multiple-items .cloneditem-9 {
  display: none; }

.carousel-multiple-items .cloneditem-10 {
  display: none; }

.carousel-multiple-items .cloneditem-11 {
  display: none; }

.carousel-multiple-items .carousel-inner {
  margin: 0 8%;
  width: auto; }
  .carousel-multiple-items .carousel-inner .carousel-item > div {
    padding: 0;
    transition: all .5s ease; }
    .carousel-multiple-items .carousel-inner .carousel-item > div:hover {
      transform: scale(1.1); }
  .carousel-multiple-items .carousel-inner .carousel-item.active, .carousel-multiple-items .carousel-inner .carousel-item.carousel-item-next, .carousel-multiple-items .carousel-inner .carousel-item.carousel-item-prev {
    display: flex; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(50%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-50%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item-prev {
  left: -50%; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item-next {
  left: 50%; }

.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-2 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-2 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-2 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(33.3333333333%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-33.3333333333%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item-prev {
  left: -33.3333333333%; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item-next {
  left: 33.3333333333%; }

.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-3 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-3 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-3 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-3 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(25%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-25%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item-prev {
  left: -25%; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item-next {
  left: 25%; }

.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-4 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-4 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-4 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-4 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-4 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(20%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-20%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item-prev {
  left: -20%; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item-next {
  left: 20%; }

.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-5 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-5 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-5 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-5 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-5 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-5 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(16.6666666667%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-16.6666666667%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item-prev {
  left: -16.6666666667%; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item-next {
  left: 16.6666666667%; }

.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-6 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-6 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-6 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-6 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-6 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-6 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-6 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(14.2857142857%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-14.2857142857%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item-prev {
  left: -14.2857142857%; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item-next {
  left: 14.2857142857%; }

.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-7 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-7 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-7 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(12.5%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-12.5%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item-prev {
  left: -12.5%; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item-next {
  left: 12.5%; }

.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-8 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-8 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-8 .carousel-inner .cloneditem-7 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(11.1111111111%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-11.1111111111%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item-prev {
  left: -11.1111111111%; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item-next {
  left: 11.1111111111%; }

.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-9 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-9 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-7 {
  display: block; }

.carousel-multiple-items.items-xs-9 .carousel-inner .cloneditem-8 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(10%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-10%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item-prev {
  left: -10%; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item-next {
  left: 10%; }

.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-10 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-10 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-7 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-8 {
  display: block; }

.carousel-multiple-items.items-xs-10 .carousel-inner .cloneditem-9 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(9.0909090909%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-9.0909090909%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item-prev {
  left: -9.0909090909%; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item-next {
  left: 9.0909090909%; }

.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-11 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-11 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-7 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-8 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-9 {
  display: block; }

.carousel-multiple-items.items-xs-11 .carousel-inner .cloneditem-10 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.carousel-item-next {
  transform: translate3d(8.3333333333%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.active.carousel-item-left,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.carousel-item-prev {
  transform: translate3d(-8.3333333333%, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.carousel-item-left,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item.active {
  transform: translate3d(0, 0, 0);
  left: 0; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .active.carousel-item-left,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item-prev {
  left: -8.3333333333%; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .active.carousel-item-right,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item-next {
  left: 8.3333333333%; }

.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item-left,
.carousel-multiple-items.items-xs-12 .carousel-inner > .carousel-item-prev.carousel-item-right,
.carousel-multiple-items.items-xs-12 .carousel-inner > .active {
  left: 0; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-1 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-2 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-3 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-4 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-5 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-6 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-7 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-8 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-9 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-10 {
  display: block; }

.carousel-multiple-items.items-xs-12 .carousel-inner .cloneditem-11 {
  display: block; }

@media (min-width: 576px) {
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-2 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item-prev {
    left: -50%; }
  .carousel-multiple-items.items-sm-2 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item-next {
    left: 50%; }
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-2 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-2 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item-prev {
    left: -33.3333333333%; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item-next {
    left: 33.3333333333%; }
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-3 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-3 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-3 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item-prev {
    left: -25%; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item-next {
    left: 25%; }
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-4 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-4 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-4 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-4 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item-prev {
    left: -20%; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item-next {
    left: 20%; }
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-5 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-5 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-5 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-5 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-5 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item-prev {
    left: -16.6666666667%; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item-next {
    left: 16.6666666667%; }
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-6 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-6 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-6 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-6 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-6 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-6 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item-prev {
    left: -14.2857142857%; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item-next {
    left: 14.2857142857%; }
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-7 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-7 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item-prev {
    left: -12.5%; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item-next {
    left: 12.5%; }
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-8 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-8 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item-prev {
    left: -11.1111111111%; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item-next {
    left: 11.1111111111%; }
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-9 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-sm-9 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item-prev {
    left: -10%; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item-next {
    left: 10%; }
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-10 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-sm-10 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item-prev {
    left: -9.0909090909%; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item-next {
    left: 9.0909090909%; }
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-11 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-sm-11 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.active.carousel-item-right, .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item-prev {
    left: -8.3333333333%; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item-next {
    left: 8.3333333333%; }
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-sm-12 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-sm-12 .carousel-inner .cloneditem-11 {
    display: block; } }

@media (min-width: 992px) {
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-2 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item-prev {
    left: -50%; }
  .carousel-multiple-items.items-md-2 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item-next {
    left: 50%; }
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-2 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-2 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-2 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-2 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item-prev {
    left: -33.3333333333%; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item-next {
    left: 33.3333333333%; }
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-3 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-3 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-3 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item-prev {
    left: -25%; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item-next {
    left: 25%; }
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-4 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-4 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-4 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-4 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item-prev {
    left: -20%; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item-next {
    left: 20%; }
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-5 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-5 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-5 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-5 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-5 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item-prev {
    left: -16.6666666667%; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item-next {
    left: 16.6666666667%; }
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-6 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-6 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-6 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-6 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-6 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-6 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item-prev {
    left: -14.2857142857%; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item-next {
    left: 14.2857142857%; }
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-7 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-7 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-7 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-7 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-7 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-7 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-7 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item-prev {
    left: -12.5%; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item-next {
    left: 12.5%; }
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-8 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-8 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-8 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item-prev {
    left: -11.1111111111%; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item-next {
    left: 11.1111111111%; }
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-9 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-9 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-md-9 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item-prev {
    left: -10%; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item-next {
    left: 10%; }
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-10 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-10 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-md-10 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item-prev {
    left: -9.0909090909%; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item-next {
    left: 9.0909090909%; }
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-11 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-11 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-md-11 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item-prev {
    left: -8.3333333333%; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item-next {
    left: 8.3333333333%; }
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-md-12 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-md-12 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-md-12 .carousel-inner .cloneditem-11 {
    display: block; } }

@media (min-width: 1200px) {
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-50%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-2 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item-prev {
    left: -50%; }
  .carousel-multiple-items.items-lg-2 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item-next {
    left: 50%; }
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-2 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-2 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-2 .carousel-inner .cloneditem-1 {
    display: block; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-33.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item-prev {
    left: -33.3333333333%; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item-next {
    left: 33.3333333333%; }
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-3 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-3 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-25%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item-prev {
    left: -25%; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item-next {
    left: 25%; }
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-4 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-4 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-4 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-20%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item-prev {
    left: -20%; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item-next {
    left: 20%; }
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-5 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-5 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-5 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-5 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-16.6666666667%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item-prev {
    left: -16.6666666667%; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item-next {
    left: 16.6666666667%; }
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-6 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-6 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-6 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-6 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-6 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-14.2857142857%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item-prev {
    left: -14.2857142857%; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item-next {
    left: 14.2857142857%; }
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-7 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-7 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-7 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-7 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-7 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-7 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-12.5%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item-prev {
    left: -12.5%; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item-next {
    left: 12.5%; }
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-8 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-8 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-11.1111111111%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item-prev {
    left: -11.1111111111%; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item-next {
    left: 11.1111111111%; }
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-9 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-lg-9 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-10%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item-prev {
    left: -10%; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item-next {
    left: 10%; }
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-10 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-lg-10 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-9.0909090909%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item-prev {
    left: -9.0909090909%; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item-next {
    left: 9.0909090909%; }
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-11 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-lg-11 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.active.carousel-item-right,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.carousel-item-next {
    transform: translate3d(8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.active.carousel-item-left,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.carousel-item-prev {
    transform: translate3d(-8.3333333333%, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.carousel-item-left,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item.active {
    transform: translate3d(0, 0, 0);
    left: 0; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .active.carousel-item-left,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item-prev {
    left: -8.3333333333%; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .active.carousel-item-right,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item-next {
    left: 8.3333333333%; }
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item-left,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .carousel-item-prev.carousel-item-right,
  .carousel-multiple-items.items-lg-12 .carousel-inner > .active {
    left: 0; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-2 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-3 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-4 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-5 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-6 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-7 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-8 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-9 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-10 {
    display: block; }
  .carousel-multiple-items.items-lg-12 .carousel-inner .cloneditem-11 {
    display: block; } }

.carousel-inner > .carousel-item > img {
  margin: 0 auto; }

.modal-carousel .modal-dialog {
  max-width: none;
  margin: 0;
  height: 100%;
  overflow-y: scroll; }

.modal-carousel .modal-content,
.modal-carousel .modal-body {
  height: 100%; }

.modal-carousel .modal-content {
  pointer-events: none; }

.modal-carousel .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  pointer-events: auto;
  text-shadow: 0 1px 0 #000;
  z-index: 4;
  color: #fff;
  font-size: 3rem;
  opacity: 1; }

.modal-carousel .carousel {
  height: 100%;
  display: flex; }
  .modal-carousel .carousel [class^="carousel-control"],
  .modal-carousel .carousel img,
  .modal-carousel .carousel .carousel-indicators,
  .modal-carousel .carousel .carousel-item-content {
    pointer-events: auto; }

.modal-carousel .carousel-indicators {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), transparent);
  bottom: 0; }
  .modal-carousel .carousel-indicators li {
    cursor: pointer; }

.modal-carousel .carousel-inner {
  width: 720px;
  max-width: 88vh;
  height: calc(100vh - 3.5rem);
  margin: auto; }

.modal-carousel [class^="carousel-control"]:not(.carousel-control-icon) {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  top: 50%;
  transform: translateY(-50%);
  opacity: 1; }
  @media (min-width: 576px) {
    .modal-carousel [class^="carousel-control"]:not(.carousel-control-icon) {
      width: 50px;
      height: 50px; } }
  @media (min-width: 992px) {
    .modal-carousel [class^="carousel-control"]:not(.carousel-control-icon) {
      width: 75px;
      height: 75px; } }
  .modal-carousel [class^="carousel-control"]:not(.carousel-control-icon) svg {
    width: 28%; }

.modal-carousel .carousel-control-prev {
  left: 10px; }

.modal-carousel .carousel-control-next {
  right: 10px; }
  .modal-carousel .carousel-control-next svg {
    transform: rotate(180deg); }

.modal-carousel .carousel-item.active,
.modal-carousel .carousel-item-next,
.modal-carousel .carousel-item-prev {
  display: flex; }

.modal-carousel .carousel-item {
  height: 100%; }
  .modal-carousel .carousel-item .mh-100,
  .modal-carousel .carousel-item img {
    max-height: 100%; }

.form-message-wrapper {
  color: #fff;
  font-weight: 700; }
  .form-message-wrapper .modal-body {
    padding: 30px 10px; }
    .form-message-wrapper .modal-body p:last-child {
      margin-bottom: 0; }
  .form-message-wrapper .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 11px;
    color: inherit;
    opacity: 1; }

input.form-control,
select.form-control:not([size]):not([multiple]) {
  height: 48px;
  padding: 0 15px; }
  input.form-control.form-control-sm, .input-group-sm > input.form-control,
  .input-group-sm > .input-group-prepend > input.form-control.input-group-text,
  .input-group-sm > .input-group-append > input.form-control.input-group-text,
  .input-group-sm > .input-group-prepend > input.form-control.btn,
  .input-group-sm > .input-group-append > input.form-control.btn,
  select.form-control:not([size]):not([multiple]).form-control-sm,
  .input-group-sm > select.form-control:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.form-control.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.form-control.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.form-control.btn:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.form-control.btn:not([size]):not([multiple]) {
    height: 28px; }

textarea.form-control {
  padding: 15px;
  resize: none; }

.submit-ok[type="submit"] {
  box-shadow: -4px -2px 2px rgba(87, 87, 87, 0.3);
  width: 80px;
  height: 80px; }
  @media (min-width: 768px) {
    .submit-ok[type="submit"] {
      width: 80px;
      height: 80px; } }
  @media (min-width: 992px) {
    .submit-ok[type="submit"] {
      width: auto;
      height: auto;
      border-radius: 0 !important;
      font-size: 2rem; }
      .submit-ok[type="submit"] * {
        vertical-align: middle; } }
  .submit-ok[type="submit"] svg {
    position: absolute;
    width: 50%;
    top: 50%;
    left: calc(50% + 3px);
    transform: translate(-50%, -50%); }
  @media (max-width: 991.99px) {
    .submit-ok[type="submit"].spin-me {
      background-color: transparent !important; } }
  .submit-ok[type="submit"].spin-me svg {
    display: none; }
  .submit-ok[type="submit"].spin-me .lb-spinner {
    display: inline-block; }

.fadeimg {
  position: relative;
  background-color: #000;
  background-size: 0;
  background-repeat: no-repeat; }
  .fadeimg:before, .fadeimg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .fadeimg:before {
    z-index: 1;
    background-color: inherit; }
  .fadeimg:after {
    z-index: 2;
    background: inherit;
    background-size: cover;
    opacity: 1; }
  .fadeimg > * {
    position: relative;
    z-index: 3; }
  .fadeimg:after {
    opacity: 0;
    transition: opacity 0.4s ease; }
  .fadeimg.bg-loaded:after {
    opacity: 1; }

.lb-spinner {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  color: #2b6dca;
  width: 80px;
  height: 80px; }
  @media (min-width: 992px) {
    .lb-spinner {
      position: relative;
      color: currentColor;
      width: 20px;
      height: 20px; } }
  .lb-spinner > span {
    position: relative;
    display: inline-block;
    background-color: currentColor; }
    .lb-spinner > span:nth-child(1), .lb-spinner > span:nth-child(2) {
      display: block;
      width: 0;
      height: 0;
      background: transparent;
      border: 40px solid currentColor;
      border-right-color: transparent;
      border-radius: 100%;
      animation: pacman-rotate-half-up .5s 0s infinite; }
      @media (min-width: 992px) {
        .lb-spinner > span:nth-child(1), .lb-spinner > span:nth-child(2) {
          border-width: 10px; } }
    .lb-spinner > span:nth-child(2) {
      margin-top: -80px;
      animation-name: pacman-rotate-half-down; }
      @media (min-width: 992px) {
        .lb-spinner > span:nth-child(2) {
          margin-top: -20px; } }
    .lb-spinner > span:nth-child(3), .lb-spinner > span:nth-child(4), .lb-spinner > span:nth-child(5), .lb-spinner > span:nth-child(6) {
      position: absolute;
      top: 50%;
      left: 200%;
      width: 25%;
      height: 25%;
      border-radius: 100%;
      opacity: 0;
      animation: pacman-balls 2s 0s infinite linear; }
    .lb-spinner > span:nth-child(3) {
      animation-delay: -1.44s; }
    .lb-spinner > span:nth-child(4) {
      animation-delay: -1.94s; }
    .lb-spinner > span:nth-child(5) {
      animation-delay: -2.44s; }
    .lb-spinner > span:nth-child(6) {
      animation-delay: -2.94s; }

@keyframes pacman-rotate-half-up {
  0%,
  100% {
    transform: rotate(270deg); }
  50% {
    transform: rotate(360deg); } }

@keyframes pacman-rotate-half-down {
  0%,
  100% {
    transform: rotate(90deg); }
  50% {
    transform: rotate(0deg); } }

@keyframes pacman-balls {
  0% {
    left: 200%;
    opacity: 0;
    transform: translateY(-50%); }
  5% {
    opacity: .5; }
  66% {
    opacity: 1; }
  67% {
    opacity: 0; }
  100% {
    left: 0;
    transform: translateY(-50%); } }

html {
  font-size: 14px; }
  @media (min-width: 768px) {
    html {
      font-size: 15px; } }
  @media (min-width: 992px) {
    html {
      font-size: 15px; } }

body {
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4 {
  text-transform: uppercase; }

h5, h6 {
  font-family: "Roboto", sans-serif; }

b, strong {
  font-weight: 700; }

.font-title {
  font-family: "Oswald", sans-serif; }

svg.icon-default {
  height: 0.7em; }

.underline {
  text-decoration: underline; }

a *:not(u) {
  text-decoration: inherit; }

@media (pointer: coarse) {
  a:not(.btn) {
    text-decoration: underline; } }

a.tdn {
  text-decoration: none; }

.steelfish, .experience-section .experience-title,
.experience-section h1.experience-title {
  font-family: "Steelfish", sans-serif;
  letter-spacing: 0.05em;
  line-height: 1; }

h1.steelfish, .experience-section h1.experience-title {
  font-size: 4.2rem; }

h2.steelfish, .experience-section h2.experience-title {
  font-size: 3.3rem; }

.display-3.steelfish, .experience-section .display-3.experience-title {
  font-size: 5.5rem; }

@media (max-width: 991.99px) {
  .custom-modal-open {
    overflow: hidden; } }

.navbar-main {
  padding: 0;
  height: 58px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .navbar-main:not(.no-submenu) {
      margin-bottom: 42px; }
    .navbar-main.at-home {
      margin-bottom: 0; } }
  .navbar-main .navbar-brand-wrapper {
    display: flex;
    background: #2b6dca;
    align-items: center;
    height: 100%; }
    @media (min-width: 992px) {
      .navbar-main .navbar-brand-wrapper {
        background: transparent; } }
    .navbar-main .navbar-brand-wrapper .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1.33rem;
      padding-right: 1.33rem;
      margin: 0px;
      height: 100%;
      color: #fff; }
      @media (min-width: 992px) {
        .navbar-main .navbar-brand-wrapper .navbar-brand {
          color: #2b6dca; } }
    .navbar-main .navbar-brand-wrapper .logo {
      display: block;
      width: 120px;
      max-width: 100%; }
      @media (min-width: 992px) {
        .navbar-main .navbar-brand-wrapper .logo {
          width: 145px; } }
    @media (max-width: 991.99px) {
      .navbar-main .navbar-brand-wrapper {
        flex: 1; } }
  .navbar-main .main-menu .navbar-nav {
    color: #fff; }
  .navbar-main .main-menu .nav-item {
    color: #969696; }
  @media (max-width: 991.99px) {
    .navbar-main.at-home .main-menu .nav-item {
      color: #fff; }
    .navbar-main.at-home .nav-item:hover {
      transition: none !important;
      color: #d54ab7; } }
  .navbar-main .navbar-nav {
    padding-top: 3em; }
    @media (min-width: 992px) {
      .navbar-main .navbar-nav {
        width: 100%;
        text-align: center;
        padding: 0; } }
    .navbar-main .navbar-nav .nav-item {
      opacity: 0;
      transform: translateX(30px);
      font-size: 1.3rem; }
      @media (min-width: 992px) {
        .navbar-main .navbar-nav .nav-item {
          width: 100%;
          white-space: nowrap; }
          .navbar-main .navbar-nav .nav-item.whatsapp-item {
            max-width: 70px; } }
      .navbar-main .navbar-nav .nav-item .nav-link {
        font-size: 1.22rem;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        padding-left: 1.45em;
        color: inherit; }
        .navbar-main .navbar-nav .nav-item .nav-link:hover {
          color: inherit; }
        @media (min-width: 992px) {
          .navbar-main .navbar-nav .nav-item .nav-link {
            font-size: 1.29rem;
            padding: 0 1rem;
            line-height: 70px;
            transition: color 0.4s ease; }
            .navbar-main .navbar-nav .nav-item .nav-link:hover {
              color: #000; } }
        @media (min-width: 1200px) {
          .navbar-main .navbar-nav .nav-item .nav-link {
            font-size: 1.4rem; } }
      @media (min-width: 992px) {
        .navbar-main .navbar-nav .nav-item .whatsapp-link {
          color: rgba(26, 135, 104, 0.7); }
          .navbar-main .navbar-nav .nav-item .whatsapp-link .icon {
            margin: 0; }
            .navbar-main .navbar-nav .nav-item .whatsapp-link .icon .stroke-me {
              stroke: currentColor; }
          .navbar-main .navbar-nav .nav-item .whatsapp-link:hover {
            color: #1a8768; } }
      .navbar-main .navbar-nav .nav-item .submenu {
        font-weight: 300;
        display: none;
        padding: 0;
        font-size: 0.98rem;
        list-style: none; }
        @media (max-width: 991.99px) {
          .navbar-main .navbar-nav .nav-item .submenu.d-xs-none {
            display: none !important; } }
        .navbar-main .navbar-nav .nav-item .submenu .submenu-item .submenu-link {
          text-decoration: none;
          display: block;
          padding: 1em 0 1em 4.8em;
          border-bottom: 1px solid #575757; }
          .navbar-main .navbar-nav .nav-item .submenu .submenu-item .submenu-link:first-of-type {
            border-top: 1px solid #575757; }
        .navbar-main .navbar-nav .nav-item .submenu .submenu-item.active .submenu-link {
          background: #575757; }
          @media (min-width: 992px) {
            .navbar-main .navbar-nav .nav-item .submenu .submenu-item.active .submenu-link {
              text-decoration: underline; } }
        @media (pointer: fine) {
          .navbar-main .navbar-nav .nav-item .submenu .submenu-item:hover .submenu-link {
            text-decoration: underline; } }
        .navbar-main .navbar-nav .nav-item .submenu a {
          color: #fff; }
      .navbar-main .navbar-nav .nav-item.active {
        color: #fff;
        font-weight: 500; }
        .navbar-main .navbar-nav .nav-item.active .submenu {
          display: block; }
      @media (min-width: 992px) {
        .navbar-main .navbar-nav .nav-item.active-lg {
          color: #fff;
          font-weight: 500; }
          .navbar-main .navbar-nav .nav-item.active-lg .submenu {
            display: block; } }
      @media (min-width: 992px) {
        .navbar-main .navbar-nav .nav-item {
          opacity: 1;
          transform: none;
          color: #969696;
          font-family: "Oswald", sans-serif; }
          .navbar-main .navbar-nav .nav-item .submenu {
            position: fixed;
            top: 70px;
            left: 0;
            right: 0;
            background: #2b6dca;
            height: 42px; }
            .navbar-main .navbar-nav .nav-item .submenu .submenu-item {
              width: 100%;
              max-width: 200px; }
              .navbar-main .navbar-nav .nav-item .submenu .submenu-item .submenu-link {
                padding: 0.8em 1.4em; }
                .navbar-main .navbar-nav .nav-item .submenu .submenu-item .submenu-link:first-of-type {
                  border: none; }
              .navbar-main .navbar-nav .nav-item .submenu .submenu-item.active .submenu-link {
                background: none;
                font-weight: 500;
                text-decoration: underline; }
          .navbar-main .navbar-nav .nav-item.active, .navbar-main .navbar-nav .nav-item.active-lg {
            color: #fff;
            background: #000; }
            .navbar-main .navbar-nav .nav-item.active .nav-link:hover, .navbar-main .navbar-nav .nav-item.active-lg .nav-link:hover {
              color: #969696; }
            .navbar-main .navbar-nav .nav-item.active .submenu, .navbar-main .navbar-nav .nav-item.active-lg .submenu {
              display: flex;
              justify-content: center; } }
    .navbar-main .navbar-nav .icon {
      margin-right: 0.9em;
      vertical-align: bottom;
      width: 1.2em; }
      @media (min-width: 992px) {
        .navbar-main .navbar-nav .icon {
          width: 1.4em;
          vertical-align: text-top; } }
  .navbar-main .lightning-icon svg {
    height: 35px;
    fill: #fff; }
  .navbar-main .btns-wrapper {
    display: flex;
    height: 100%; }
    .navbar-main .btns-wrapper .btn {
      font-size: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 1em;
      padding-left: 1em;
      line-height: 58px;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .navbar-main .btns-wrapper .btn.navbar-toggler .menu-icon {
        position: relative;
        width: 22px;
        height: 3px;
        background: #fff;
        transition: all 0.4s ease; }
        .navbar-main .btns-wrapper .btn.navbar-toggler .menu-icon:before, .navbar-main .btns-wrapper .btn.navbar-toggler .menu-icon:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 3px;
          background: #fff;
          transition: all 0.4s ease;
          transform-origin: center center; }
        .navbar-main .btns-wrapper .btn.navbar-toggler .menu-icon:before {
          top: -7px; }
        .navbar-main .btns-wrapper .btn.navbar-toggler .menu-icon:after {
          bottom: -7px; }
      @media (min-width: 576px) {
        .navbar-main .btns-wrapper .btn {
          font-size: 1.1rem; } }
      @media (min-width: 992px) {
        .navbar-main .btns-wrapper .btn {
          font-size: 0.95rem;
          line-height: 1;
          padding-right: 0.5em;
          padding-left: 0.5em; } }
    .navbar-main .btns-wrapper .mcb-icon {
      width: 39px;
      margin-bottom: -2px; }
    .navbar-main .btns-wrapper .whatsapp-btn {
      display: inline-flex;
      align-items: center;
      color: #1a8768;
      padding: 0 1em; }
      .navbar-main .btns-wrapper .whatsapp-btn .icon {
        width: 18px; }
      @media (min-width: 992px) {
        .navbar-main .btns-wrapper .whatsapp-btn {
          background: #2b6dca; } }
  .navbar-main.is-active .btns-wrapper .btn.navbar-toggler .menu-icon {
    background: none; }
    .navbar-main.is-active .btns-wrapper .btn.navbar-toggler .menu-icon:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .navbar-main.is-active .btns-wrapper .btn.navbar-toggler .menu-icon:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
  @media (min-width: 992px) {
    .navbar-main {
      height: 70px; } }
  @media (max-width: 991.99px) {
    .navbar-main .main-menu {
      position: fixed;
      overflow: auto;
      top: 58px;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0;
      transform: translateX(-100vw);
      pointer-events: none;
      transition: opacity 0.4s ease; }
    .navbar-main.is-active .main-menu {
      transform: none;
      pointer-events: auto;
      opacity: 1; }
    .navbar-main.is-active .nav-item {
      opacity: 1;
      transform: none; }
      .navbar-main.is-active .nav-item:nth-child(1) {
        transition: opacity 0.4s ease 0.2s, transform 0.4s ease 0.2s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(2) {
        transition: opacity 0.4s ease 0.4s, transform 0.4s ease 0.4s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(3) {
        transition: opacity 0.4s ease 0.6s, transform 0.4s ease 0.6s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(4) {
        transition: opacity 0.4s ease 0.8s, transform 0.4s ease 0.8s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(5) {
        transition: opacity 0.4s ease 1s, transform 0.4s ease 1s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(6) {
        transition: opacity 0.4s ease 1.2s, transform 0.4s ease 1.2s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(7) {
        transition: opacity 0.4s ease 1.4s, transform 0.4s ease 1.4s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(8) {
        transition: opacity 0.4s ease 1.6s, transform 0.4s ease 1.6s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(9) {
        transition: opacity 0.4s ease 1.8s, transform 0.4s ease 1.8s, color 0.4s ease; }
      .navbar-main.is-active .nav-item:nth-child(10) {
        transition: opacity 0.4s ease 2s, transform 0.4s ease 2s, color 0.4s ease; } }

.footer-main {
  background: #575757;
  color: #969696;
  font-size: 0.9rem; }
  @media (min-width: 992px) {
    .footer-main {
      font-size: 0.8rem; } }
  .footer-main ul {
    margin: 0; }
  .footer-main a {
    color: #969696; }
  .footer-main .footer-legal-images img {
    vertical-align: bottom; }
  .footer-main .footer-sitemap a {
    text-decoration: none; }
  .footer-main .footer-sitemap h5 {
    font-weight: 800;
    font-size: 1em;
    text-transform: uppercase;
    margin-bottom: 0.2rem; }
  .footer-main .footer-social h5 {
    font-size: 1.1rem; }
  .footer-main .border-bottom {
    border-bottom: 1px solid currentColor; }
  .footer-main .lb-badge {
    text-transform: uppercase;
    font-size: 0.75rem; }
    .footer-main .lb-badge span {
      width: 90px;
      vertical-align: middle; }

.social-links a {
  text-decoration: none; }
  .social-links a .icon {
    width: 44px;
    margin: 0 5px;
    transition: transform 0.4s ease; }
    .social-links a .icon:hover {
      transform: scale(1.1); }
      .social-links a .icon:hover .white-fill {
        fill: #969696; }
  .social-links a:first-of-type .icon {
    margin-left: 0; }
  .social-links a:last-of-type .icon {
    margin-right: 0; }

.share-bgs-wrapper {
  position: relative; }
  .share-bgs-wrapper > .row {
    position: relative;
    z-index: 2; }

.share-bg {
  background: url("../../img/animated-crosses.gif") center center no-repeat;
  background-size: contain;
  width: 163px;
  height: 85px;
  position: absolute;
  z-index: 1; }
  .share-bg.bg-01 {
    top: -40px;
    left: 0;
    transform: scaleX(-1); }
  .share-bg.bg-02 {
    top: 60%;
    left: 0; }
  .share-bg.bg-03 {
    top: 40%;
    right: 0; }
  .share-bg.bg-04 {
    top: 80%;
    right: 0; }
  .share-bg.bg-05 {
    top: 62%;
    right: 30%;
    transform: scaleX(-1); }

.template-img-wrapper {
  display: block;
  position: relative;
  padding-bottom: 54.63%; }
  .template-img-wrapper img {
    position: absolute;
    top: 0;
    left: 0; }

.template-social-btns {
  justify-content: center; }
  .template-social-btns > div {
    margin-left: 5px;
    margin-right: 5px; }
  @media (min-width: 992px) {
    .template-social-btns {
      justify-content: flex-end;
      min-height: 47px;
      margin-top: -47px; } }

.clipboard-btn-icon {
  width: 20px;
  margin-right: 7px; }

.template-share-btn {
  max-width: 170px;
  font-size: 1.25rem; }
  .template-share-btn .icon {
    box-shadow: -5px -5px rgba(0, 0, 0, 0.2); }
  .template-share-btn svg {
    width: 60%; }

.hero {
  background-position: center center;
  background-size: cover; }
  .hero.hero-img-01 {
    background-image: url("../../img/hero/littleblue-buzos-01-sm.jpg"); }
    @media (min-width: 768px) {
      .hero.hero-img-01 {
        background-image: url("../../img/hero/littleblue-buzos-01.jpg"); } }
  .hero.hero-img-02 {
    background-image: url("../../img/hero/littleblue-buzos-02-sm.jpg"); }
    @media (min-width: 768px) {
      .hero.hero-img-02 {
        background-image: url("../../img/hero/littleblue-buzos-02.jpg"); } }
  .hero.hero-img-03 {
    background-image: url("../../img/hero/littleblue-buzos-03-sm.jpg"); }
    @media (min-width: 768px) {
      .hero.hero-img-03 {
        background-image: url("../../img/hero/littleblue-buzos-03.jpg"); } }
  .hero .hero-row {
    min-height: 30vw; }
  .hero h1 {
    margin: 0;
    padding: 4px;
    font-size: 7.3vw;
    animation: heroFadeLeft 1.5s ease 3s;
    animation-fill-mode: forwards; }
    @media (pointer: fine) {
      .hero h1 {
        animation: none; }
        .hero h1.fadeout {
          animation: heroFadeLeft 1.5s ease forwards; } }
    @media (min-width: 576px) {
      .hero h1 {
        font-size: 5.3vw; } }
    @media (min-width: 768px) {
      .hero h1 {
        font-size: 3.5em; } }
    @media (min-width: 992px) {
      .hero h1 {
        font-size: 4.4em; } }
  .hero .btn {
    padding: 0;
    width: 150px;
    max-width: 100%;
    text-shadow: 1px -1px 10px black; }
    @media (min-width: 768px) {
      .hero .btn {
        width: 160px; } }
    .hero .btn .icon-wrapper {
      transition: background 0.4s ease; }
    .hero .btn:hover .icon-wrapper {
      background: #575757 !important; }
    @media (min-width: 992px) {
      .hero .btn {
        width: auto;
        background: #000;
        padding: 0.7rem 0.9rem;
        font-size: 1.65rem; }
        .hero .btn * {
          vertical-align: middle;
          text-shadow: none;
          box-shadow: none; }
        .hero .btn .icon-wrapper {
          display: inline-block !important;
          font-size: 1em;
          width: auto;
          height: auto;
          margin: 0 !important; }
          .hero .btn .icon-wrapper .icon {
            display: inline-block;
            width: auto;
            height: 1em;
            margin-right: 5px; }
        .hero .btn .icon-wrapper,
        .hero .btn:hover .icon-wrapper {
          background: none !important; }
        .hero .btn .btn-text {
          background: none !important; }
        .hero .btn:hover {
          background: #575757; } }

.promo-week {
  font-size: 1.3rem; }
  .promo-week a:hover {
    color: rgba(255, 255, 255, 0.8) !important;
    text-decoration: none; }

@media (min-width: 768px) {
  .promo-modal .modal-dialog {
    max-width: 600px; } }

.promo-modal .close {
  font-size: 2.5rem; }

.promo-modal .btn-main {
  margin-top: -10px;
  line-height: 1.5;
  font-size: 1.2rem;
  margin-top: -1.4rem; }
  @media (min-width: 576px) {
    .promo-modal .btn-main {
      font-size: 1.5rem; } }

.promo-modal .promo-share-item {
  padding: 10px 0; }
  .promo-modal .promo-share-item .icon {
    width: 20px;
    vertical-align: bottom;
    margin-right: 0.7em; }
  .promo-modal .promo-share-item .whatsapp-icon {
    color: #1a8768; }
  .promo-modal .promo-share-item .messenger-icon {
    color: #0682fb; }
  .promo-modal .promo-share-item .mail-icon {
    color: #ef4736; }

.home-info-bg {
  background: url(../../img/animated-crosses-colored.gif) center center space repeat;
  background-attachment: fixed; }

@media (min-width: 992px) {
  .home-info-animation {
    margin-top: -4.5rem;
    max-height: 150px; } }

.home-info-icon {
  height: 25px;
  margin-bottom: 10px; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.instagram-wrapper {
  padding: 0; }

.instagram-item {
  position: relative; }
  .instagram-item .item {
    display: block;
    position: relative;
    background-position: center center;
    background-size: cover;
    padding-bottom: 100%;
    color: #fff; }
  .instagram-item img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .instagram-item .loader {
    background: #efefef;
    padding-bottom: 100%; }
    .instagram-item .loader:before, .instagram-item .loader:after {
      content: '';
      position: absolute; }
    .instagram-item .loader:before {
      border: 2px solid #575757;
      border-radius: 50%;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      width: 20px;
      height: 20px; }
    .instagram-item .loader:after {
      background: inherit;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform-origin: top left;
      animation: rotate 0.7s ease infinite; }
  .instagram-item .likes-wrapper {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: center;
    background: linear-gradient(to top, #000, transparent);
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0; }
    @media (min-width: 768px) {
      .instagram-item .likes-wrapper {
        font-size: 1.4rem; } }
    @media (pointer: fine) {
      .instagram-item .likes-wrapper {
        align-items: center;
        padding-bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        top: 0;
        opacity: 0;
        transition: opacity 0.4s ease; }
        .instagram-item .likes-wrapper:hover {
          opacity: 1; } }
    .instagram-item .likes-wrapper svg {
      margin-right: 0.4em;
      height: 1em; }

@media (min-width: 1440px) {
  .d-xxl-block {
    display: block !important; } }

.home-experience {
  position: relative;
  background: url("../../img/office.jpg") center center no-repeat;
  background-size: cover;
  min-height: 56.25vw;
  cursor: pointer; }
  @media (min-width: 1440px) {
    .home-experience {
      min-height: 0;
      height: 810px; } }
  .home-experience span {
    text-decoration: inherit; }
  @media (min-width: 768px) {
    .home-experience {
      padding-top: 0 !important;
      display: flex;
      align-items: center; } }
  @media (min-width: 1440px) {
    .home-experience .title-wrapper {
      width: 1410px;
      margin: auto; } }
  .home-experience .experience-title {
    font-size: 10.5vw; }
    @media (min-width: 768px) {
      .home-experience .experience-title {
        font-size: 13.5vw;
        width: calc(50vw - 100px); }
        .home-experience .experience-title .big {
          font-size: 1.3em; } }
    @media (min-width: 1440px) {
      .home-experience .experience-title {
        width: 620px;
        font-size: 181px; } }
  .home-experience .play-vid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .home-experience .play-vid .icon {
      display: inline-block;
      width: 40px; }
      @media (min-width: 576px) {
        .home-experience .play-vid .icon {
          width: 50px; } }
      @media (min-width: 768px) {
        .home-experience .play-vid .icon {
          width: 70px; } }
      @media (min-width: 992px) {
        .home-experience .play-vid .icon {
          width: 100px; } }
  @media (pointer: fine) {
    .home-experience .play-vid {
      transition: opacity 0.4s ease;
      opacity: 0.4; }
    .home-experience:hover .play-vid {
      transition-delay: 0.4s;
      opacity: 1; } }

.home-social-links {
  background: url(../../img/animated-crosses-colored.gif) center center repeat-y;
  background-color: #000;
  background-attachment: fixed; }

.products-breadcrumb-wrapper {
  font-family: "Oswald", sans-serif;
  color: #969696; }

.products-breadcrumb {
  background: transparent;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .products-breadcrumb {
      font-size: 1.2rem; } }
  .products-breadcrumb .breadcrumb-item {
    color: inherit; }
    .products-breadcrumb .breadcrumb-item:before {
      color: inherit; }
    .products-breadcrumb .breadcrumb-item h1 {
      font-size: inherit;
      font-family: inherit;
      text-transform: inherit;
      margin: 0;
      font-weight: inherit; }

.products-select .dropdown .btn,
.products-select .dropdown .dropdown-menu {
  text-transform: uppercase;
  font-size: 0.9rem; }

.products-select .dropdown .btn {
  color: #2b6dca;
  background: #fff; }

.products-select .dropdown .dropdown-menu {
  border-radius: 0; }

.products-select .dropdown a {
  text-decoration: none; }

.products-select .dropdown .dropdown-item {
  font-weight: 300; }
  .products-select .dropdown .dropdown-item.active, .products-select .dropdown .dropdown-item:active {
    background: none;
    color: #2b6dca; }

.filter-by-tags-form {
  max-width: 400px;
  position: relative;
  padding-bottom: 2rem; }
  @media (min-width: 768px) {
    .filter-by-tags-form {
      max-width: none;
      padding-right: 2rem;
      padding-left: 2rem;
      padding-bottom: 0; } }
  .filter-by-tags-form .input-group {
    position: static; }
    .filter-by-tags-form .input-group .input-group-text {
      background: none;
      padding-top: 4px;
      padding-bottom: 4px; }
      @media (max-width: 767.99px) {
        .filter-by-tags-form .input-group .input-group-text {
          border: none;
          padding: 0; } }
    .filter-by-tags-form .input-group .floating-checkbox {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 768px) {
        .filter-by-tags-form .input-group .floating-checkbox {
          position: relative;
          bottom: 0;
          left: 0;
          transform: none;
          background: #fff; } }
    @media (max-width: 767.99px) {
      .filter-by-tags-form .input-group input[type="search"] {
        width: 100%;
        border-radius: 0.25rem; } }
  .filter-by-tags-form [type="submit"] {
    width: 35px;
    height: 35px;
    padding: 10px 0;
    flex: 0 0 auto;
    margin-left: 10px; }
    .filter-by-tags-form [type="submit"] svg {
      display: inline-block;
      width: 33.33%;
      transform: scaleX(-1); }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.products-grid .products-text {
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  text-transform: uppercase; }

.products-grid .h100 {
  display: flex;
  min-height: calc(100vh - 210px); }
  @media (min-width: 992px) {
    .products-grid .h100 {
      min-height: calc(100vh - 460px); } }

.products-grid .products-loading {
  position: relative; }
  .products-grid .products-loading .loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: fadeIn 3s ease;
    animation-fill-mode: backwards; }
    .products-grid .products-loading .loading-text:nth-child(2) {
      animation-delay: 3s; }
    .products-grid .products-loading .loading-text:nth-child(3) {
      animation-delay: 6s; }
    .products-grid .products-loading .loading-text:nth-child(4) {
      animation-delay: 9s; }
    .products-grid .products-loading .loading-text:nth-child(5) {
      animation-delay: 12s; }
    .products-grid .products-loading .loading-text:nth-child(6) {
      animation-delay: 15s; }
    .products-grid .products-loading .loading-text:nth-child(7) {
      animation-delay: 18s; }
    .products-grid .products-loading .loading-text:nth-child(8) {
      animation-delay: 21s; }
    .products-grid .products-loading .loading-text:nth-child(9) {
      animation-delay: 24s; }
    .products-grid .products-loading .loading-text:nth-child(10) {
      animation-delay: 27s; }
    .products-grid .products-loading .loading-text:nth-child(11) {
      animation-delay: 30s; }
    .products-grid .products-loading .loading-text:nth-child(12) {
      animation-delay: 33s; }
    .products-grid .products-loading .loading-text:nth-child(13) {
      animation-delay: 36s; }
    .products-grid .products-loading .loading-text:nth-child(14) {
      animation-delay: 39s; }
    .products-grid .products-loading .loading-text:nth-child(15) {
      animation-delay: 42s; }
    .products-grid .products-loading .loading-text:nth-child(16) {
      animation-delay: 45s; }
    .products-grid .products-loading .loading-text:last-child {
      animation-fill-mode: forwards; }

.products-grid .items-wrapper {
  padding: 5px; }

.products-grid .products-item {
  position: relative;
  background-image: url("../../img/spinner.gif");
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
  margin: 5px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 50%; } }
  @media (min-width: 500px) {
    .products-grid .products-item {
      flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px); } }
  @media all and (min-width: 500px) and (-ms-high-contrast: none), (min-width: 500px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 50%; } }
  @media (min-width: 750px) {
    .products-grid .products-item {
      flex: 1 1 calc(33.3333333333% - 10px);
      max-width: calc(33.3333333333% - 10px); } }
  @media all and (min-width: 750px) and (-ms-high-contrast: none), (min-width: 750px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 33.3333333333%; } }
  @media (min-width: 1000px) {
    .products-grid .products-item {
      flex: 1 1 calc(25% - 10px);
      max-width: calc(25% - 10px); } }
  @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 25%; } }
  @media (min-width: 1250px) {
    .products-grid .products-item {
      flex: 1 1 calc(20% - 10px);
      max-width: calc(20% - 10px); } }
  @media all and (min-width: 1250px) and (-ms-high-contrast: none), (min-width: 1250px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 20%; } }
  @media (min-width: 1500px) {
    .products-grid .products-item {
      flex: 1 1 calc(16.6666666667% - 10px);
      max-width: calc(16.6666666667% - 10px); } }
  @media all and (min-width: 1500px) and (-ms-high-contrast: none), (min-width: 1500px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 16.6666666667%; } }
  @media (min-width: 1750px) {
    .products-grid .products-item {
      flex: 1 1 calc(14.2857142857% - 10px);
      max-width: calc(14.2857142857% - 10px); } }
  @media all and (min-width: 1750px) and (-ms-high-contrast: none), (min-width: 1750px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 14.2857142857%; } }
  @media (min-width: 2000px) {
    .products-grid .products-item {
      flex: 1 1 calc(12.5% - 10px);
      max-width: calc(12.5% - 10px); } }
  @media all and (min-width: 2000px) and (-ms-high-contrast: none), (min-width: 2000px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 12.5%; } }
  @media (min-width: 2250px) {
    .products-grid .products-item {
      flex: 1 1 calc(11.1111111111% - 10px);
      max-width: calc(11.1111111111% - 10px); } }
  @media all and (min-width: 2250px) and (-ms-high-contrast: none), (min-width: 2250px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 11.1111111111%; } }
  @media (min-width: 2500px) {
    .products-grid .products-item {
      flex: 1 1 calc(10% - 10px);
      max-width: calc(10% - 10px); } }
  @media all and (min-width: 2500px) and (-ms-high-contrast: none), (min-width: 2500px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 10%; } }
  @media (min-width: 2750px) {
    .products-grid .products-item {
      flex: 1 1 calc(9.0909090909% - 10px);
      max-width: calc(9.0909090909% - 10px); } }
  @media all and (min-width: 2750px) and (-ms-high-contrast: none), (min-width: 2750px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 9.0909090909%; } }
  @media (min-width: 3000px) {
    .products-grid .products-item {
      flex: 1 1 calc(8.3333333333% - 10px);
      max-width: calc(8.3333333333% - 10px); } }
  @media all and (min-width: 3000px) and (-ms-high-contrast: none), (min-width: 3000px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 8.3333333333%; } }
  @media (min-width: 3250px) {
    .products-grid .products-item {
      flex: 1 1 calc(7.6923076923% - 10px);
      max-width: calc(7.6923076923% - 10px); } }
  @media all and (min-width: 3250px) and (-ms-high-contrast: none), (min-width: 3250px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 7.6923076923%; } }
  @media (min-width: 3500px) {
    .products-grid .products-item {
      flex: 1 1 calc(7.1428571429% - 10px);
      max-width: calc(7.1428571429% - 10px); } }
  @media all and (min-width: 3500px) and (-ms-high-contrast: none), (min-width: 3500px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 7.1428571429%; } }
  @media (min-width: 3750px) {
    .products-grid .products-item {
      flex: 1 1 calc(6.6666666667% - 10px);
      max-width: calc(6.6666666667% - 10px); } }
  @media all and (min-width: 3750px) and (-ms-high-contrast: none), (min-width: 3750px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 6.6666666667%; } }
  @media (min-width: 4000px) {
    .products-grid .products-item {
      flex: 1 1 calc(6.25% - 10px);
      max-width: calc(6.25% - 10px); } }
  @media all and (min-width: 4000px) and (-ms-high-contrast: none), (min-width: 4000px) and (-ms-high-contrast: active) {
    .products-grid .products-item {
      flex: 1 1 6.25%; } }
  .products-grid .products-item:after {
    content: '';
    display: block;
    padding-bottom: calc(100% + 30px); }
  .products-grid .products-item .img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
    .products-grid .products-item .img-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto; }
    .products-grid .products-item .img-wrapper.taller img {
      width: 100%;
      height: auto; }
  .products-grid .products-item .carousel-control-icon {
    width: 30%; }
  .products-grid .products-item .carousel-control-prev {
    opacity: 1;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent); }
  .products-grid .products-item .carousel-control-next {
    opacity: 1;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent); }
    .products-grid .products-item .carousel-control-next svg {
      transform: scaleX(-1); }
  .products-grid .products-item .carousel,
  .products-grid .products-item .carousel-inner {
    display: block;
    position: static; }
  .products-grid .products-item .more {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 5px;
    right: 5px;
    background: #000;
    border-radius: 50%;
    box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.3);
    z-index: 4; }
    @media (min-width: 768px) {
      .products-grid .products-item .more {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px; } }
    .products-grid .products-item .more:before, .products-grid .products-item .more:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      transition: transform 0.4s ease; }
    .products-grid .products-item .more:before {
      width: 2px;
      height: 12px; }
      @media (min-width: 768px) {
        .products-grid .products-item .more:before {
          height: 17px; } }
    .products-grid .products-item .more:after {
      width: 12px;
      height: 2px; }
      @media (min-width: 768px) {
        .products-grid .products-item .more:after {
          width: 17px; } }
  .products-grid .products-item .products-item-fadein {
    opacity: 0;
    transition: opacity 0.4s ease; }
  .products-grid .products-item.loaded .products-item-fadein {
    opacity: 1; }
  .products-grid .products-item.loaded .more {
    display: block; }
  @media (pointer: fine) {
    .products-grid .products-item:hover .more:before, .products-grid .products-item:hover .more:after {
      transform: translate(-50%, -50%) rotate(90deg); } }

@media (max-width: 575.99px) {
  .product-top .col-image {
    padding-left: 0;
    padding-right: 0; } }

.product-top.has-gallery .img-wrapper {
  cursor: zoom-in; }

.product-top .img-wrapper {
  position: relative; }
  @media (min-width: 768px) {
    .product-top .img-wrapper {
      padding-bottom: 100%; } }
  .product-top .img-wrapper img {
    max-height: calc(100vh - 178px); }
    @media (orientation: landscape) {
      .product-top .img-wrapper img {
        min-height: 200px; } }
    @media (min-width: 768px) {
      .product-top .img-wrapper img {
        min-height: 0;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

.product-top [class^="carousel-control"].btn {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  width: 50px;
  height: 50px;
  opacity: 1;
  margin: 0 15px; }
  .product-top [class^="carousel-control"].btn svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28%; }
  .product-top [class^="carousel-control"].btn.btn-next svg {
    transform: translate(-50%, -50%) rotate(180deg); }

.product-top .thumbnails {
  top: 0;
  bottom: 0; }

.product-top .thumbnail {
  width: 100px;
  height: 100px;
  margin: 0 0 17px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer; }
  .product-top .thumbnail.active {
    border: 3px solid #d54ab7; }
  .product-top .thumbnail:before, .product-top .thumbnail:after {
    content: none; }
  @media (min-width: 1200px) {
    .product-top .thumbnail {
      width: 122px;
      height: 122px; } }
  .product-top .thumbnail:last-child {
    margin-bottom: 0; }

.product-top .more-items {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  background: #d54ab7;
  color: #fff;
  text-indent: 0; }

.product-top h1 {
  font-size: 3rem; }

@media (min-width: 992px) {
  .product-top .product-info .desc-n-btn {
    display: flex;
    flex-direction: column-reverse; } }

.product-top .product-info p {
  font-weight: 400; }
  .product-top .product-info p:last-child {
    margin-bottom: 0; }
  @media (min-width: 1200px) {
    .product-top .product-info p:not(.small) {
      font-size: 1.15rem; } }

.product-tags .product-tag {
  display: inline-block;
  background: #fff;
  color: #969696;
  border: 1px solid #969696;
  border-radius: 2px;
  font-size: .85em;
  padding: 4px 8px;
  margin: 0 3px 8px;
  font-weight: 400;
  transition: all 0.4s ease; }
  .product-tags .product-tag:after {
    content: "x";
    margin-left: 8px;
    font-size: .9em; }
  .product-tags .product-tag:hover {
    border-color: #d54ab7;
    color: #d54ab7; }

.product-related {
  background: #efefef; }
  .product-related .carousel {
    background: #fff;
    display: flex;
    max-width: 290px;
    margin: auto;
    padding: 20px 0;
    border: 7px solid #000;
    box-shadow: -7px 11px #000; }
    @media (min-width: 576px) {
      .product-related .carousel {
        max-width: none; } }
  .product-related [class^="carousel-control"]:not(.carousel-control-icon) {
    position: static;
    width: auto;
    color: #000;
    padding: 0 7px; }
    @media (pointer: coarse) {
      .product-related [class^="carousel-control"]:not(.carousel-control-icon) {
        opacity: 1; } }
  .product-related .carousel-control-icon {
    width: 12px; }
  @media (pointer: fine) {
    .product-related .carousel-inner:hover .carousel-inner-item {
      opacity: 0.6; }
    .product-related .carousel-inner .carousel-inner-item {
      transition: opacity 0.4s ease; }
      .product-related .carousel-inner .carousel-inner-item:hover {
        opacity: 1; } }

.title-wrapper h1 {
  font-size: 4.3rem; }
  .title-wrapper h1.steelfish, .title-wrapper .experience-section h1.experience-title, .experience-section .title-wrapper h1.experience-title {
    font-size: 5.6rem; }

@media (min-width: 576px) {
  .title-wrapper .subtitle {
    font-size: 1.54rem; } }

.create-items .item-img-wrapper {
  display: block;
  max-width: 211px;
  margin: auto; }

.create-items .item-img {
  position: relative;
  overflow: hidden;
  display: block;
  padding-bottom: 116%; }
  .create-items .item-img img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0; }

.create-items .item-title-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0; }
  .create-items .item-title-wrapper br {
    display: none; }
    @media (min-width: 992px) {
      .create-items .item-title-wrapper br {
        display: block; } }
  @media (min-width: 768px) {
    .create-items .item-title-wrapper {
      bottom: 20%;
      left: 15%;
      right: 15%;
      top: auto; } }

.create-items .item-title {
  font-size: 1.1rem;
  padding: 0px 4px;
  background: #2b6dca; }
  @media (min-width: 576px) {
    .create-items .item-title {
      font-size: 1.5rem; } }
  @media (min-width: 768px) {
    .create-items .item-title {
      font-size: 1.8rem; } }

.create-items .create-item {
  display: block;
  position: relative; }

.create-items.randomized .create-item.active .item-img img {
  transform: translateX(-50%); }

.create-items.randomized .create-item.active .item-title {
  background: #d54ab7; }

@media (pointer: fine) {
  .create-items:not(.randomized) .create-item:hover .item-img img {
    transform: translateX(-50%); }
  .create-items:not(.randomized) .create-item:hover .item-title {
    background: #d54ab7; } }

.master-iframe {
  display: block;
  width: 100%;
  min-height: calc(100vh - 58px);
  border: none; }
  @media (min-width: 992px) {
    .master-iframe {
      height: 700px;
      min-height: 0;
      max-height: calc(100vh - 70px); } }

.experience-section {
  overflow: hidden;
  position: relative;
  background: #fff;
  background-size: cover;
  background-position: center center;
  color: #fff; }
  .experience-section.bg-white {
    color: #000; }
  @media (min-width: 768px) {
    .experience-section {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 58px);
      padding-top: 80px;
      padding-bottom: 80px; }
      .experience-section .exp-content {
        position: relative;
        z-index: 2; } }
  @media (min-width: 992px) {
    .experience-section {
      min-height: calc(100vh - 112px); } }
  .experience-section .experience-title,
  .experience-section h1.experience-title {
    font-size: 3.9rem;
    line-height: 1.35;
    margin-bottom: 18px;
    margin-top: -42px; }
    @media (min-width: 768px) {
      .experience-section .experience-title,
      .experience-section h1.experience-title {
        margin-top: 18px;
        font-size: 5.1rem; } }
    @media (min-width: 992px) {
      .experience-section .experience-title,
      .experience-section h1.experience-title {
        font-size: 6.75rem; } }
    @media (min-width: 1200px) {
      .experience-section .experience-title,
      .experience-section h1.experience-title {
        font-size: 8.35rem; } }
    .experience-section .experience-title span[class^="bg-"],
    .experience-section .experience-title span[class*=" bg-"],
    .experience-section h1.experience-title span[class^="bg-"],
    .experience-section h1.experience-title span[class*=" bg-"] {
      display: inline-block;
      padding: 0 5px;
      line-height: 1.25; }
      @media (min-width: 768px) {
        .experience-section .experience-title span[class^="bg-"],
        .experience-section .experience-title span[class*=" bg-"],
        .experience-section h1.experience-title span[class^="bg-"],
        .experience-section h1.experience-title span[class*=" bg-"] {
          padding: 0 8px; } }
    .experience-section .experience-title .icon,
    .experience-section h1.experience-title .icon {
      fill: currentColor;
      height: 0.8em; }
  @media (min-width: 768px) {
    .experience-section p {
      font-size: 1.4rem;
      line-height: 1.43;
      font-weight: 400; } }
  .experience-section .scroll {
    position: absolute;
    z-index: 2;
    display: none;
    margin: auto;
    color: inherit;
    border: 3px solid;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    bottom: 20px; }
    @media (min-width: 768px) {
      .experience-section .scroll {
        display: block; } }
    .experience-section .scroll:before, .experience-section .scroll:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 3px;
      background: currentColor;
      top: 27px; }
    .experience-section .scroll:before {
      right: 50%;
      transform-origin: right bottom;
      transform: rotate(45deg); }
    .experience-section .scroll:after {
      left: 50%;
      transform-origin: left bottom;
      transform: rotate(-45deg); }
  .experience-section:nth-child(1) .exp-overlay {
    background-image: url("../../img/experience/1.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(1) {
      background-image: url("../../img/experience/1.jpg"); }
      .experience-section:nth-child(1) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(2) .exp-overlay {
    background-image: url("../../img/experience/2.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(2) {
      background-image: url("../../img/experience/2.jpg"); }
      .experience-section:nth-child(2) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(3) .exp-overlay {
    background-image: url("../../img/experience/3.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(3) {
      background-image: url("../../img/experience/3.jpg"); }
      .experience-section:nth-child(3) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(4) .exp-overlay {
    background-image: url("../../img/experience/4.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(4) {
      background-image: url("../../img/experience/4.jpg"); }
      .experience-section:nth-child(4) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(5) .exp-overlay {
    background-image: url("../../img/experience/5.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(5) {
      background-image: url("../../img/experience/5.jpg"); }
      .experience-section:nth-child(5) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(6) .exp-overlay {
    background-image: url("../../img/experience/6.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(6) {
      background-image: url("../../img/experience/6.jpg"); }
      .experience-section:nth-child(6) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(7) .exp-overlay {
    background-image: url("../../img/experience/7.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(7) {
      background-image: url("../../img/experience/7.jpg"); }
      .experience-section:nth-child(7) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(8) .exp-overlay {
    background-image: url("../../img/experience/8.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(8) {
      background-image: url("../../img/experience/8.jpg"); }
      .experience-section:nth-child(8) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(9) .exp-overlay {
    background-image: url("../../img/experience/9.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(9) {
      background-image: url("../../img/experience/9.jpg"); }
      .experience-section:nth-child(9) .exp-overlay {
        background-image: none; } }
  .experience-section:nth-child(10) .exp-overlay {
    background-image: url("../../img/experience/10.jpg"); }
  @media (min-width: 768px) {
    .experience-section:nth-child(10) {
      background-image: url("../../img/experience/10.jpg"); }
      .experience-section:nth-child(10) .exp-overlay {
        background-image: none; } }
  @media (min-width: 768px) {
    .experience-section .exp-animate {
      opacity: 0; }
    .experience-section .exp-left {
      transform: translateX(-70px); }
    .experience-section .exp-right {
      transform: translateX(70px); }
    .experience-section .exp-top {
      transform: translateY(-70px); }
    .experience-section .exp-bottom {
      transform: translateY(70px); }
    .experience-section.active .exp-animate {
      opacity: 1;
      transform: none;
      transition: opacity 0.4s ease, transform 0.4s ease; }
    .experience-section.active .delay-1 {
      transition-delay: 0.2s; }
    .experience-section.active .delay-2 {
      transition-delay: 0.4s; }
    .experience-section.active .delay-3 {
      transition-delay: 0.6s; }
    .experience-section.active .delay-4 {
      transition-delay: 0.8s; }
    .experience-section.active .delay-5 {
      transition-delay: 1s; }
    .experience-section.active .delay-6 {
      transition-delay: 1.2s; } }

@media (max-width: 767.99px) {
  #experience-section-11 {
    padding-top: 40px;
    padding-bottom: 40px; } }

#experience-section-11 .experience-title {
  margin-top: 0; }

#experience-section-11 .rse-link {
  color: inherit;
  font-weight: 400;
  transition: all 0.4s ease; }
  #experience-section-11 .rse-link.active {
    color: #d54ab7; }

#experience-section-11 .rse-icon {
  display: inline-block;
  border: 3px solid #d54ab7;
  border-radius: 50%;
  margin: 5px 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.4s ease; }
  #experience-section-11 .rse-icon:hover, #experience-section-11 .rse-icon.active {
    background: #d54ab7;
    padding: 15px;
    margin: 0 5px; }

#experience-section-11 p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

.exp-overlay {
  height: 40vh;
  background-size: cover;
  background-position: center center; }
  @media (orientation: landscape) {
    .exp-overlay {
      height: 40vw; } }
  @media (min-width: 768px) {
    .exp-overlay {
      position: absolute;
      height: auto;
      top: 0;
      bottom: 0;
      width: 0;
      background-color: inherit;
      opacity: 0.7;
      transition: all 0.6s ease; }
      .bg-white .exp-overlay {
        opacity: 0.8; }
      .exp-overlay.left {
        left: 0; }
      .exp-overlay.right {
        right: 0; }
      .active .exp-overlay {
        width: 100%; } }

.team-card {
  padding: 10px;
  margin-bottom: 40px;
  border: 2px solid #efefef;
  box-shadow: -3px 3px #efefef;
  position: relative;
  text-align: center; }
  @media (min-width: 992px) {
    .team-card {
      padding: 20px 20px 10px; } }
  .team-card small {
    color: #969696; }
  .team-card:before, .team-card:after {
    content: '';
    position: absolute; }
  .team-card.card-style-1:before {
    height: 6%;
    width: 22%;
    top: -4%;
    left: 50%;
    margin-left: -10%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(-4deg); }
  .team-card.card-style-2:before {
    top: -2px;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: #fff #fff #fff #efefef; }
  .team-card.card-style-2:after {
    top: 2px;
    right: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 0 0px 14px;
    border-color: transparent transparent transparent #fff; }
  .team-card.card-style-3:before {
    height: 12%;
    width: 6.5%;
    top: -7%;
    left: 20%;
    margin-left: -3%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(-4deg); }
  .team-card.card-style-4:before {
    height: 12%;
    width: 6.5%;
    top: -7%;
    left: 20%;
    margin-left: -3%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(-4deg); }
  .team-card.card-style-4:after {
    height: 12%;
    width: 6.5%;
    top: -7%;
    right: 20%;
    margin-left: 3%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(4deg); }
  .team-card.card-style-5:before {
    height: 12%;
    width: 6.5%;
    top: -7%;
    left: 20%;
    margin-left: -3%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(-4deg); }
  .team-card.card-style-5:after {
    height: 12%;
    width: 6.5%;
    top: -7%;
    right: 20%;
    margin-left: 3%;
    background: #fff;
    border: 2px solid #efefef;
    transform: rotate(4deg);
    z-index: -1; }

#raffles #full-form {
  display: none; }

#raffles.validated #full-form {
  display: block; }

#raffles p.help-block {
  padding-top: 5px;
  color: #969696;
  font-style: italic;
  font-style: 0.8em; }

#raffles button.disabled {
  background: #bdbdbd !important; }

#raffles-success {
  display: none; }
  #raffles-success .font-blue {
    color: #2b6dca; }
  #raffles-success .font-magenta {
    color: #d54ab7; }
  #raffles-success img.pointer {
    width: 40px; }
  #raffles-success p {
    color: #404040;
    text-align: center;
    font-size: 1.3em; }
  #raffles-success .raffle {
    position: relative; }
    #raffles-success .raffle #raffle-number {
      position: absolute;
      top: 45px;
      left: 30%;
      font-size: 150%;
      font-weight: bold; }
    #raffles-success .raffle #raffle-number-sm {
      position: absolute;
      top: 10px;
      left: 30%;
      font-weight: bold; }

#raffles-msg {
  display: none; }

#raffles-loading {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); }
  #raffles-loading h1 {
    text-align: center;
    padding-top: 200px;
    color: #fff; }

.press-articles h2 {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  max-width: 17rem;
  line-height: 1.3; }
  @media (min-width: 992px) {
    .press-articles h2 {
      top: 2.5rem;
      left: 2.5rem;
      font-size: 6rem;
      max-width: 30rem; } }

.press-article .description {
  font-size: 0.9rem; }

.press-article .press-article-bg {
  background: #efefef; }

.press-article .btn {
  width: 30px;
  height: 30px;
  font-size: 1.4rem;
  text-align: center;
  padding: 0;
  line-height: 1; }
  .press-article .btn span {
    display: block;
    transition: transform 0.4s ease; }

@media (pointer: fine) {
  .press-article:hover .btn span {
    transform: rotate(90deg); } }

@media (min-width: 768px) {
  .press-sidebar .press-article {
    border-bottom: 1px solid #969696;
    margin-bottom: 0; }
    .press-sidebar .press-article .press-article-bg {
      background: #fff; } }

.clients-top .face-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 242px;
  box-sizing: content-box; }

.clients-top .client-face {
  display: inline-block;
  width: 100%;
  background: url("../../img/contact/contact-face-yep.png") 0 center no-repeat;
  background-size: 200%;
  padding-top: 125%; }
  .clients-top .client-face:hover {
    background-position: 100% center; }
  .clients-top .client-face.no {
    background: url("../../img/contact/contact-face-nope.png") 0 center no-repeat;
    background-size: 200%; }
    .clients-top .client-face.no:hover {
      background-position: 100% center; }

#client-options .icon {
  display: inline-block;
  height: 1em; }

#client-options .icon,
#client-options h3 span {
  vertical-align: middle; }

.sales-box {
  border: 3px solid currentColor; }
  .sales-box .icon {
    margin-top: -35px;
    max-width: 135px; }
  .sales-box hr {
    width: 20%;
    height: 2px;
    background: currentColor; }

.sales-box-title {
  font-size: 2.4rem; }

.sales-social .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px; }
  @media (min-width: 992px) {
    .sales-social .icon-wrapper {
      width: 120px;
      height: 120px; } }

.sales-social .icon {
  height: 33.3%; }

@media (min-width: 576px) and (max-width: 991.99px) {
  .sales-social-text {
    font-size: 80%; } }

@media (max-width: 575.99px) {
  .form-smaller-title {
    font-size: 60%; } }

.large-form-toggler {
  cursor: pointer;
  padding: 10px; }
  .large-form-toggler .icon {
    height: 0.8em;
    vertical-align: middle;
    animation: bounce-right 2s ease infinite; }
  .large-form-toggler span {
    padding-left: 10px;
    vertical-align: middle; }

.large-form-content-wrapper {
  overflow: hidden; }
  .large-form-content-wrapper.bg-light-gray .large-form-content {
    padding-top: 20px;
    padding-bottom: 20px; }
  .large-form-content-wrapper .large-form-content {
    display: none; }
  .large-form-content-wrapper.active .large-form-toggler .icon {
    animation: none;
    transform: rotate(90deg); }
  .large-form-content-wrapper.active .large-form-content {
    display: flex; }
  .large-form-content-wrapper #file-upload {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    height: 0; }
  .large-form-content-wrapper .file-upload-btn {
    width: 50px;
    height: 50px; }
    .large-form-content-wrapper .file-upload-btn .icon {
      height: 25px; }

.mcb-page {
  position: relative; }

.mcb-page-content {
  position: relative;
  z-index: 2; }

.mcb-bgs-wrapper {
  position: relative; }

.mcb-bg {
  background: url("../../img/animated-crosses.gif") center center no-repeat;
  background-size: contain;
  width: 163px;
  height: 85px;
  position: absolute;
  z-index: 1; }
  .mcb-bg.bg-01 {
    top: 10px;
    left: 0; }
    @media (min-width: 768px) {
      .mcb-bg.bg-01 {
        top: 10%; } }
  .mcb-bg.bg-02 {
    top: 45%;
    left: 0;
    transform: rotate(90deg); }
  .mcb-bg.bg-03 {
    bottom: 10px;
    left: 15%;
    transform: rotate(180deg); }
    @media (min-width: 768px) {
      .mcb-bg.bg-03 {
        bottom: 10%; } }
  .mcb-bg.bg-04 {
    top: 10px;
    right: 10%;
    transform: rotate(180deg); }
    @media (min-width: 992px) {
      .mcb-bg.bg-04 {
        top: 10%; } }
  .mcb-bg.bg-05 {
    bottom: 20%;
    right: 0; }

.mcb-page-icon {
  max-width: 140px; }
  @media (min-width: 768px) {
    .mcb-page-icon {
      max-width: 245px; } }

.mcb-subtitle {
  max-width: 450px;
  margin: auto; }

.mcb-page-btn {
  width: 50px;
  height: 50px; }
  .mcb-page-btn .icon {
    width: 43%;
    transform: translateX(20%); }

@media (max-width: 991.99px) {
  body.colors-menu-open {
    overflow: hidden; } }

.inspire-btn-plus {
  width: 50px;
  height: 50px;
  font-size: 2.4rem;
  line-height: 50px;
  text-align: center;
  padding: 0; }

@media (min-width: 992px) {
  .inspire-colors {
    overflow: hidden; } }

.inspire-colors-chart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1030;
  opacity: 0;
  transform: translateX(-100vw);
  pointer-events: none;
  transition: opacity 0.4s ease;
  padding-top: 48px; }
  @media (min-width: 992px) {
    .inspire-colors-chart {
      position: relative;
      overflow: visible;
      transform: none;
      pointer-events: auto;
      opacity: 1;
      z-index: 1;
      margin: -11px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 0; } }
  .inspire-colors-chart.is-active {
    transform: none;
    pointer-events: auto;
    opacity: 1; }
  .inspire-colors-chart .category-name {
    position: fixed;
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 1.6rem;
    top: 0;
    left: 0;
    right: 0;
    text-align: left;
    padding-left: 40px;
    background: #000;
    line-height: 48px;
    z-index: 1; }
  .inspire-colors-chart ul {
    flex-wrap: wrap;
    justify-content: center;
    height: 100%; }
  .inspire-colors-chart .inspire-color {
    position: relative;
    cursor: pointer; }
    @media (max-width: 991.99px) {
      .inspire-colors-chart .inspire-color {
        padding: 15px 15px 15px 40px;
        font-size: 1.3rem;
        display: flex;
        align-items: flex-end;
        min-height: 0;
        transition: all 0.4s ease;
        border-top: 5px solid #fff; }
        .inspire-colors-chart .inspire-color.is-dark {
          color: #fff; }
        .inspire-colors-chart .inspire-color.is-active {
          min-height: calc(100% + 48px); } }
    @media (min-width: 768px) and (max-width: 991.99px) {
      .inspire-colors-chart .inspire-color {
        padding-left: 50px; } }
    @media (min-width: 992px) {
      .inspire-colors-chart .inspire-color {
        width: 98px;
        height: 98px;
        margin: 11px;
        border-radius: 50%;
        border: 4px solid #fff; } }
    @media (min-width: 992px) {
      .inspire-colors-chart .inspire-color .color-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        bottom: calc(50% + 10px);
        left: 50%;
        transform: translateX(-50%);
        height: 190px;
        width: 190px;
        cursor: auto;
        background: #fff;
        border: 4px solid #000;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease, bottom 0.4s ease;
        z-index: 1070; }
        .inspire-colors-chart .inspire-color .color-info:before {
          content: '';
          position: absolute;
          bottom: -12px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          border-top: 9px solid #000; } }
    .inspire-colors-chart .inspire-color .color-info .image {
      display: none; }
      @media (min-width: 992px) {
        .inspire-colors-chart .inspire-color .color-info .image {
          display: block;
          position: relative; }
          .inspire-colors-chart .inspire-color .color-info .image:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: url("../../img/inspire/buzoblanco.png") center center no-repeat;
            background-size: contain; } }
    @media (min-width: 992px) {
      .inspire-colors-chart .inspire-color.jaspeado .color-info .image:before {
        background-image: url("../../img/inspire/buzoblancojaspeado.png"); } }
    @media (min-width: 992px) {
      .inspire-colors-chart .inspire-color:hover .color-info {
        opacity: 1;
        bottom: 50%;
        pointer-events: auto; } }

@media (max-width: 767.99px) {
  body.fullprints-menu-open {
    overflow: hidden; } }

.inspire-fullprints {
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 25px 0; }

.fullprints-navigation {
  position: absolute;
  top: 0;
  height: calc(100% - 30px);
  z-index: 10; }
  .fullprints-navigation .icon {
    width: 10px; }
  .fullprints-navigation:focus {
    box-shadow: none; }
  .fullprints-navigation.fullprints-btn-prev {
    left: calc(-4vw - 30px); }
  .fullprints-navigation.fullprints-btn-next {
    right: calc(-4vw - 30px); }
  .fullprints-navigation.disabled {
    z-index: 1;
    opacity: 0.3; }
  @media (min-width: 576px) {
    .fullprints-navigation {
      height: calc(100% - 40px); }
      .fullprints-navigation .icon {
        width: 15px; }
      .fullprints-navigation.fullprints-btn-prev {
        left: calc(-4vw - 40px); }
      .fullprints-navigation.fullprints-btn-next {
        right: calc(-4vw - 40px); } }

.inspire-fullprint {
  position: absolute;
  cursor: pointer;
  margin: 0 -4vw;
  opacity: 0;
  pointer-events: none; }
  .inspire-fullprint.mob-prev, .inspire-fullprint.mob-next, .inspire-fullprint.mob-prev-prev, .inspire-fullprint.mob-next-next, .inspire-fullprint.mob-current {
    transition: opacity 0.4s ease;
    position: relative;
    opacity: 1;
    pointer-events: auto; }
  .inspire-fullprint.mob-prev, .inspire-fullprint.mob-current {
    z-index: 2; }
  .inspire-fullprint.mob-next {
    z-index: 1; }
  @media (min-width: 768px) {
    .inspire-fullprint {
      position: relative;
      z-index: 1 !important;
      margin: 0 -25px;
      opacity: 1;
      pointer-events: auto; }
      .inspire-fullprint:nth-of-type(4) {
        z-index: 4 !important; }
        .inspire-fullprint:nth-of-type(4) + .inspire-fullprint {
          z-index: 3 !important; }
        .inspire-fullprint:nth-of-type(4) + .inspire-fullprint + .inspire-fullprint {
          z-index: 2 !important; }
        .inspire-fullprint:nth-of-type(4) + .inspire-fullprint + .inspire-fullprint + .inspire-fullprint {
          z-index: 1 !important; } }
  .inspire-fullprint .image {
    background: #fff;
    border: 3px solid #969696;
    border-radius: 50%;
    padding: 5px;
    overflow: hidden;
    transition: all 0.4s ease; }
    .inspire-fullprint .image img {
      opacity: 0.8;
      border-radius: 50%;
      max-width: 29vw; }
      @media (min-width: 768px) {
        .inspire-fullprint .image img {
          max-width: 16vw; } }
  .inspire-fullprint .text {
    display: inline-block;
    font-size: 0.75rem;
    color: #969696;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 15px;
    line-height: 15px; }
    @media (min-width: 576px) {
      .inspire-fullprint .text {
        font-size: 1rem;
        margin-top: 20px;
        line-height: 20px; } }
    @media (min-width: 768px) {
      .inspire-fullprint .text {
        font-size: 0.85rem; } }
    @media (min-width: 992px) {
      .inspire-fullprint .text {
        font-size: 1rem; } }
  @media (min-width: 768px) {
    .inspire-fullprint.current .image {
      border-color: #000; }
      .inspire-fullprint.current .image img {
        opacity: 1; }
    .inspire-fullprint.current .text {
      color: #000; } }
  @media (min-width: 768px) and (min-width: 992px) {
    .inspire-fullprint.current .text {
      font-size: 1.2rem; } }
  .inspire-fullprint:hover .image {
    border-color: #575757; }
    .inspire-fullprint:hover .image img {
      opacity: 1; }
  .inspire-fullprint:hover .text {
    color: #000; }

.inspire-fullprints-show {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1030;
  opacity: 0;
  transform: translateX(-100vw);
  pointer-events: none;
  transition: opacity 0.4s ease;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  padding-top: 48px; }
  .inspire-fullprints-show.is-active {
    opacity: 1;
    transform: none;
    pointer-events: auto; }
  @media (min-width: 768px) {
    .inspire-fullprints-show {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      transform: none;
      pointer-events: auto;
      z-index: 1;
      padding-top: 0;
      height: 0;
      background: #efefef; }
      .inspire-fullprints-show.is-active {
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px; } }
  .inspire-fullprints-show .category-name,
  .inspire-fullprints-show .name {
    position: absolute;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 1.6rem;
    top: 0;
    left: 0;
    right: 0;
    text-align: left;
    padding-left: 40px;
    background: #000;
    line-height: 48px;
    z-index: 1; }
  .inspire-fullprints-show .category-name {
    position: fixed; }
  .inspire-fullprints-show .inspire-fullprints-show-fullprint {
    position: relative;
    height: 25%;
    border-top: 5px solid #fff;
    min-height: 125px;
    transition: all 0.4s ease; }
    .inspire-fullprints-show .inspire-fullprints-show-fullprint.is-active {
      min-height: calc(100% + 48px);
      border: none; }
      .inspire-fullprints-show .inspire-fullprints-show-fullprint.is-active .name {
        display: block; }
    .inspire-fullprints-show .inspire-fullprints-show-fullprint .img {
      background-size: cover;
      background-position: center center;
      height: 100%; }
    .inspire-fullprints-show .inspire-fullprints-show-fullprint .name {
      display: none; }
    @media (min-width: 768px) {
      .inspire-fullprints-show .inspire-fullprints-show-fullprint {
        min-height: 0;
        padding: 20px 25px;
        border: none; }
        .inspire-fullprints-show .inspire-fullprints-show-fullprint .img {
          width: 210px;
          height: 210px; }
        .inspire-fullprints-show .inspire-fullprints-show-fullprint .name {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          font-family: "Roboto", sans-serif;
          padding: 0;
          display: block;
          background: none;
          color: #000;
          text-transform: uppercase;
          text-align: center;
          font-size: 1.25rem;
          line-height: inherit;
          width: 210px;
          padding-bottom: 10px; } }

.inspire-fabric {
  padding-top: 15px;
  cursor: pointer; }
  @media (max-width: 575.99px) {
    .inspire-fabric {
      padding-left: 10px;
      padding-right: 10px; } }
  .inspire-fabric .img {
    display: inline-block;
    position: relative; }
    .inspire-fabric .img:after {
      content: '';
      position: absolute;
      top: calc(100% - 1px);
      left: 50%;
      transform: translateX(-50%);
      border-top: 7px solid #fff;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent; }
  .inspire-fabric img {
    display: block;
    border: 3px solid #fff;
    border-radius: 50%; }
  .inspire-fabric .name {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.35; }
    @media (min-width: 576px) {
      .inspire-fabric .name {
        font-size: 0.85em; } }

.fabric-modal .carousel-caption {
  position: static;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 45px;
  padding-right: 45px;
  text-align: left; }
  @media (min-width: 768px) {
    .fabric-modal .carousel-caption {
      position: absolute; } }
  .fabric-modal .carousel-caption ul {
    padding-left: 1.2rem; }

@media (max-width: 767.99px) {
  .fabric-modal .carousel-inner {
    width: 100%;
    max-width: none;
    height: auto; }
  .fabric-modal .carousel-item-content {
    height: 100vh;
    flex-direction: column; } }
  @media (max-width: 767.99px) and (orientation: landscape) {
    .fabric-modal .carousel-item-content {
      flex-direction: row; } }

@media (max-width: 767.99px) {
  .fabric-modal .carousel-caption {
    background: #000;
    height: 100%; } }

.close.inspire-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  padding: 10px 13px;
  opacity: 1;
  color: #fff;
  text-shadow: 0 1px 0 #000;
  font-size: 2rem; }

.inspire-btn-back {
  position: fixed;
  top: 50%;
  left: 0;
  width: 65px;
  height: 65px;
  transform: translate(-50%, -50%);
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  z-index: 1; }
  .inspire-btn-back .icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px; }

.legal h1 {
  line-height: 1.3; }

.error-screen {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 138px);
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center; }
  @media (min-width: 768px) {
    .error-screen {
      display: block;
      min-height: calc(100vh - 288px);
      text-align: left;
      background-position: right center;
      background-repeat: no-repeat; }
      .error-screen.error-404 {
        background-image: url("../../img/errors/meme-03.png"); }
      .error-screen.error-500 {
        background-image: url("../../img/errors/meme-02.png"); }
      .error-screen.error-503 {
        background-image: url("../../img/errors/meme-01.png");
        background-position: right 20% bottom; } }
  .error-screen h1 {
    font-size: 8rem; }
    @media (min-width: 768px) {
      .error-screen h1 {
        font-size: 12.6rem; } }
    .error-screen h1 span {
      vertical-align: middle; }
    .error-screen h1 .smaller {
      font-size: 0.4em;
      letter-spacing: 0.06em;
      margin-left: -0.3em; }
      @media (min-width: 768px) {
        .error-screen h1 .smaller {
          margin-left: -0.72em; } }
  .error-screen h3 {
    font-size: 3.1rem; }
    @media (min-width: 768px) {
      .error-screen h3 {
        font-size: 4.1em; } }
  .error-screen .error-bg-white {
    background: rgba(255, 255, 255, 0.8); }

#product-info-wrapper {
  background: #fff;
  border: 2px solid #efefef;
  text-align: left;
  max-width: 400px;
  margin: auto; }
  @media (min-width: 992px) {
    #product-info-wrapper {
      margin-top: 0; } }
  #product-info-wrapper .product-info-price {
    font-weight: bold; }
    #product-info-wrapper .product-info-price small, #product-info-wrapper .product-info-price.discount {
      font-weight: lighter; }
    #product-info-wrapper .product-info-price .full-price {
      font-weight: lighter !important; }
    #product-info-wrapper .product-info-price .off {
      background: #d54ab7;
      color: #fff;
      margin-left: 5px;
      margin-top: 2px;
      font-size: 0.8em;
      padding: 0 2px; }
  #product-info-wrapper #product-info-header {
    text-transform: uppercase;
    background: #d54ab7;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 1.3em;
    padding: 5px; }
    @media (min-width: 768px) {
      #product-info-wrapper #product-info-header {
        font-size: 1.5em; } }
    #product-info-wrapper #product-info-header * {
      vertical-align: middle; }
    #product-info-wrapper #product-info-header img {
      margin-right: 15px; }
  #product-info-wrapper .product-info-price {
    padding: 10px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef; }
    @media (min-width: 576px) {
      #product-info-wrapper .product-info-price {
        padding: 10px 15px; } }
    #product-info-wrapper .product-info-price.total {
      border-top: 2px solid #d54ab7; }
      #product-info-wrapper .product-info-price.total .price {
        font-weight: lighter; }
  #product-info-wrapper .cursor-pointer {
    cursor: pointer; }
  @media (max-width: 767px) {
    #product-info-wrapper .xs-inline-block {
      display: inline-block; }
    #product-info-wrapper .xs-block {
      display: block; } }
  #product-info-wrapper #product-info-tyc {
    border-top: 1px solid #efefef;
    padding: 15px; }
    #product-info-wrapper #product-info-tyc .checkbox {
      max-width: 300px;
      margin-right: auto;
      margin-left: auto; }
  #product-info-wrapper #product-info-btn {
    text-align: center; }
    #product-info-wrapper #product-info-btn .btn {
      font-size: 3em;
      margin-bottom: 15px;
      padding: 2px 15px; }

#ecommerce {
  color: #575757; }
  #ecommerce .btn.gray-bg {
    background: #c3c3c3; }
  #ecommerce .btn.btn-lb {
    font-family: "Steelfish", sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 0;
    box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.3);
    line-height: 1.43; }
  #ecommerce .hidden {
    display: none !important; }
  #ecommerce .phone-text {
    color: #00CC66;
    background: #fff;
    padding: 10px;
    max-width: 550px;
    margin: auto; }
    #ecommerce .phone-text img {
      max-width: 40px; }
  #ecommerce #ecommerce-header {
    background-color: #efefef;
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 45px;
    padding-bottom: 10px; }
    @media (max-width: 991.99px) {
      #ecommerce #ecommerce-header {
        padding-bottom: 0; }
        #ecommerce #ecommerce-header .container-large {
          max-width: none; }
        #ecommerce #ecommerce-header .make-me-white {
          background: #fff;
          padding-top: 10px;
          padding-bottom: 10px; } }
    #ecommerce #ecommerce-header h1 {
      position: relative;
      padding: 5px 25px;
      font-size: 3.5em;
      display: inline-block;
      margin-bottom: 20px;
      font-style: italic;
      color: #2b6dca;
      letter-spacing: 0; }
      #ecommerce #ecommerce-header h1:after {
        content: '';
        position: absolute;
        background: url("../../img/purchase/waves-big.png") 0 0 no-repeat;
        background-size: cover;
        width: 132px;
        height: 44px;
        left: -120px;
        top: 9px; }
        @media (min-width: 992px) {
          #ecommerce #ecommerce-header h1:after {
            top: 0;
            width: 142px;
            height: 64px; } }
      @media (min-width: 992px) {
        #ecommerce #ecommerce-header h1 {
          font-size: 4.5em; } }
      @media (min-width: 1200px) {
        #ecommerce #ecommerce-header h1 {
          font-size: 5em; } }
    #ecommerce #ecommerce-header .info-header {
      text-transform: uppercase;
      font-weight: bold; }
      @media (max-width: 767px) {
        #ecommerce #ecommerce-header .info-header .block-xs {
          display: block; } }
      @media (min-width: 992px) {
        #ecommerce #ecommerce-header .info-header {
          font-size: 1.3em; } }
      #ecommerce #ecommerce-header .info-header .discount {
        background: #fff;
        color: #2b6dca;
        padding: 0 2px; }
    #ecommerce #ecommerce-header #bg-liquid-header {
      background: url("../../img/purchase/bg-liquid-pink-line.png") left bottom no-repeat, url("../../img/purchase/bg-liquid-blue-small.png") right bottom no-repeat;
      position: absolute;
      bottom: -46px;
      right: 60px;
      width: 226px;
      height: 68px; }
  #ecommerce #ecommerce-content {
    padding-top: 50px; }
    #ecommerce #ecommerce-content .product-img-wrapper {
      border: 2px solid #efefef;
      background: #fff;
      margin-bottom: 5px; }
      #ecommerce #ecommerce-content .product-img-wrapper img {
        margin: 0 auto; }
      #ecommerce #ecommerce-content .product-img-wrapper #ecommerce-product-img-loader {
        display: none; }
      @media (min-width: 768px) {
        #ecommerce #ecommerce-content .product-img-wrapper #ecommerce-product-img {
          margin-bottom: 0; } }
    #ecommerce #ecommerce-content h2.product-title {
      margin-top: 0; }
    #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select {
      width: 250px;
      max-width: 100%; }
      #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle {
        border-radius: 0;
        border: 3px solid #d54ab7;
        color: #d54ab7;
        text-transform: uppercase;
        font-family: "Steelfish", sans-serif;
        font-size: 2em;
        padding: 0; }
        #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle:focus, #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle:hover {
          outline: none !important;
          background: #efefef; }
        #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle .filter-option {
          padding: 0 12px; }
        #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle .bs-caret {
          width: 40px;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background: #d54ab7;
          color: #fff; }
          #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select button.dropdown-toggle .bs-caret .caret {
            right: 13px;
            border-top: 7px dashed;
            border-top: 7px solid \9;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent; }
      #ecommerce #ecommerce-content #ecommerce-product-select-wrapper .bootstrap-select.dropup button.dropdown-toggle .bs-caret .caret {
        border-top: 0;
        border-bottom: 7px dashed;
        border-bottom: 7px solid \9; }
    #ecommerce #ecommerce-content #ecommerce-product-description {
      margin-top: 15px; }
    #ecommerce #ecommerce-content .can-do {
      position: relative;
      text-transform: uppercase;
      font-family: "Steelfish", sans-serif;
      font-size: 1.8em; }
      @media (min-width: 768px) {
        #ecommerce #ecommerce-content .can-do {
          max-width: 333px;
          padding-left: 28px; }
          #ecommerce #ecommerce-content .can-do:before {
            content: "";
            position: absolute;
            left: 0;
            width: 14px;
            top: 0;
            bottom: 0;
            background: url("../../img/purchase/changes-arrow.png") center center no-repeat;
            background-size: contain; } }
    #ecommerce #ecommerce-content #go-to-sheet {
      font-size: 0.9em;
      text-decoration: underline; }
  #ecommerce #ecommerce-faqs .ecommerce-faq {
    padding: 40px 0 15px; }
    #ecommerce #ecommerce-faqs .ecommerce-faq .faq-angle-icon {
      display: none; }
  #ecommerce #ecommerce-faqs .gray-bg {
    background: #efefef; }
  #ecommerce #ecommerce-faqs .white-text {
    color: #fff; }
  #ecommerce #ecommerce-faqs h2 {
    text-align: center;
    font-style: italic;
    text-decoration: underline;
    font-size: 2.9em; }
    @media (min-width: 768px) {
      #ecommerce #ecommerce-faqs h2 {
        font-size: 4.5em; } }
  #ecommerce #ecommerce-faqs h3 {
    display: inline-block;
    font-size: 2.2em;
    letter-spacing: 0; }
    @media (min-width: 768px) {
      #ecommerce #ecommerce-faqs h3 {
        font-size: 3em; } }
    #ecommerce #ecommerce-faqs h3 .number {
      font-size: 1.5em; }
  @media (max-width: 767px) {
    #ecommerce #ecommerce-faqs .blue-text-xs {
      color: #2b6dca !important; } }
  #ecommerce #ecommerce-faqs .answer.big-answer {
    position: relative;
    font-size: 1.8em;
    font-style: italic;
    font-family: "Steelfish", sans-serif;
    text-transform: uppercase;
    line-height: 1.3em;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      #ecommerce #ecommerce-faqs .answer.big-answer {
        background: none; } }
    @media (min-width: 768px) {
      #ecommerce #ecommerce-faqs .answer.big-answer {
        margin-left: 10px;
        display: inline-block;
        font-size: 3em;
        padding: 0 60px 0 5px;
        margin-bottom: 0; }
        #ecommerce #ecommerce-faqs .answer.big-answer:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: -50px;
          height: 100px;
          width: 100px;
          background: url("../../img/purchase/time.png") bottom left no-repeat; }
        #ecommerce #ecommerce-faqs .answer.big-answer.warranty:after {
          background-image: url("../../img/purchase/warranty.png");
          height: 106px;
          width: 106px; }
        #ecommerce #ecommerce-faqs .answer.big-answer.account:after {
          background-image: url("../../img/purchase/blueaccount.png"); }
        #ecommerce #ecommerce-faqs .answer.big-answer.process:after {
          background-image: url("../../img/purchase/process.png"); } }
  @media (min-width: 768px) {
    #ecommerce #ecommerce-faqs .answer.small-answer.margined {
      margin-left: 76px; } }
  #ecommerce #ecommerce-faqs #process-timeline .timeline-step {
    position: relative;
    display: flex;
    align-items: center; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step .process-img-wrapper {
      max-width: 50%;
      float: left;
      padding: 10px 15px 10px 0; }
      #ecommerce #ecommerce-faqs #process-timeline .timeline-step .process-img-wrapper img {
        margin: 0 0 0 auto; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step .step-txt {
      display: block;
      width: 50%;
      float: left;
      font-size: 0.85em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      padding-left: 15px;
      text-align: left;
      max-width: 200px; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:before {
      content: "";
      position: absolute;
      width: 2px;
      left: 80px;
      margin-left: -1px;
      top: 0;
      bottom: 0;
      background: #2b6dca; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 3px solid #2b6dca;
      top: 50%;
      left: 80px;
      margin-top: -7px;
      margin-left: -7px;
      background: #fff; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:first-child:before {
      top: 50%; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:first-child:after {
      border: 2px solid #d54ab7;
      width: 16px;
      height: 16px;
      margin-left: -8px;
      margin-top: -8px; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:last-child:before {
      bottom: 50%; }
    #ecommerce #ecommerce-faqs #process-timeline .timeline-step:last-child:after {
      width: 18px;
      height: 18px;
      margin-left: -9px;
      margin-top: -9px;
      background: #d54ab7; }
  @media (min-width: 768px) {
    #ecommerce #ecommerce-faqs #process-timeline {
      display: flex; }
      #ecommerce #ecommerce-faqs #process-timeline .timeline-step {
        display: block;
        width: 100%; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step .process-img-wrapper {
          max-width: none;
          width: auto;
          float: none;
          padding: 0;
          margin-bottom: 40px;
          height: 69px; }
          #ecommerce #ecommerce-faqs #process-timeline .timeline-step .process-img-wrapper img {
            margin-right: auto; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step .step-txt {
          padding: 0;
          width: auto;
          max-width: 100%;
          float: none;
          text-align: center; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step:before {
          height: 3px;
          width: auto;
          top: 88px;
          left: 0;
          right: 0;
          bottom: auto; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step:after {
          top: 89px;
          left: 50%; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step:first-child:before {
          left: 50%;
          top: 88px; }
        #ecommerce #ecommerce-faqs #process-timeline .timeline-step:last-child:before {
          right: 50%; } }
  @media (max-width: 767px) {
    #ecommerce #ecommerce-faqs .xs-faq-collapsible {
      padding-top: 20px;
      background: none;
      border-bottom: 2px solid #efefef; }
      #ecommerce #ecommerce-faqs .xs-faq-collapsible h3 {
        margin: 0;
        font-size: 2em; } }
    @media (max-width: 767px) and (max-width: 575.99px) {
      #ecommerce #ecommerce-faqs .xs-faq-collapsible h3 {
        font-size: 1.6em; } }
  @media (max-width: 767px) {
        #ecommerce #ecommerce-faqs .xs-faq-collapsible h3 .number {
          font-size: 1em; }
      #ecommerce #ecommerce-faqs .xs-faq-collapsible .faq-angle-icon {
        display: block;
        font-size: 2em;
        transition: transform 0.4s ease; } }
    @media (max-width: 767px) and (min-width: 768px) {
      #ecommerce #ecommerce-faqs .xs-faq-collapsible .faq-angle-icon {
        font-size: 3em; } }
  @media (max-width: 767px) {
      #ecommerce #ecommerce-faqs .xs-faq-collapsible .collapsed .faq-angle-icon {
        transform: scaleY(-1); } }
  @media (max-width: 767px) and (min-width: 768px) {
    #ecommerce #ecommerce-faqs .xs-faq-collapsible .answer.small-answer.margined {
      margin-left: 53px; } }
  @media (max-width: 767px) {
      #ecommerce #ecommerce-faqs .xs-faq-collapsible .answer.small-answer .margin-giver {
        height: 15px; } }
  @media (min-width: 768px) {
    #ecommerce #ecommerce-faqs .xs-faq-collapsible .collapse,
    #ecommerce #ecommerce-faqs .xs-faq-collapsible .collapsing {
      display: block !important;
      height: auto !important; }
    #ecommerce #ecommerce-faqs .xs-faq-collapsible [role="button"] {
      cursor: auto; } }
  #ecommerce #ecommerce-faqs .faq-collapsible {
    border-bottom: 2px solid #efefef;
    padding-top: 20px; }
    #ecommerce #ecommerce-faqs .faq-collapsible h3 {
      margin: 0; }
      @media (max-width: 767px) {
        #ecommerce #ecommerce-faqs .faq-collapsible h3 {
          font-size: 2em; } }
      @media (max-width: 575.99px) {
        #ecommerce #ecommerce-faqs .faq-collapsible h3 {
          font-size: 1.6em; } }
    #ecommerce #ecommerce-faqs .faq-collapsible .faq-angle-icon {
      display: block;
      font-size: 2em;
      transition: transform 0.4s ease; }
      @media (min-width: 768px) {
        #ecommerce #ecommerce-faqs .faq-collapsible .faq-angle-icon {
          font-size: 3em; } }
    #ecommerce #ecommerce-faqs .faq-collapsible .collapsed .faq-angle-icon {
      transform: scaleY(-1); }
    @media (min-width: 768px) {
      #ecommerce #ecommerce-faqs .faq-collapsible .answer.small-answer.margined {
        margin-left: 53px; } }
    #ecommerce #ecommerce-faqs .faq-collapsible .answer.small-answer .margin-giver {
      height: 15px; }
  #ecommerce #reservation-modal .modal-content {
    border-radius: 0; }
    #ecommerce #reservation-modal .modal-content .modal-header {
      background: #efefef;
      padding-top: 10px;
      padding-bottom: 10px;
      border: none; }
      #ecommerce #reservation-modal .modal-content .modal-header .close {
        margin-top: 13px;
        opacity: 1;
        color: #969696; }
    #ecommerce #reservation-modal .modal-content .modal-body label {
      font-weight: normal;
      text-align: left;
      color: #2b6dca; }
    #ecommerce #reservation-modal .modal-content .modal-body .mt5 {
      margin-top: 5px; }
    #ecommerce #reservation-modal .modal-content .modal-body .payment-methods-title {
      font-weight: bold;
      text-transform: uppercase; }
    #ecommerce #reservation-modal .modal-content .modal-body .mp-img-wrapper {
      margin-top: 15px; }
    #ecommerce #reservation-modal .modal-content .modal-footer {
      text-align: center;
      border: none; }
      #ecommerce #reservation-modal .modal-content .modal-footer .btn {
        font-size: 2em;
        padding: 2px 15px; }
  #ecommerce .custom-error-msg {
    display: none;
    margin-top: 10px;
    background: rgba(252, 33, 78, 0.2);
    border: 1px solid #FC214E;
    padding: 5px;
    text-align: center;
    color: #FC214E; }
    #ecommerce .custom-error-msg a {
      color: #FC214E;
      text-decoration: underline; }
  #ecommerce #mp-loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    line-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    font-size: 4em;
    color: #575757; }
  #ecommerce .pink-text {
    color: #d54ab7; }
  #ecommerce .pink-bg {
    background-color: #d54ab7; }
  #ecommerce .blue-text {
    color: #2b6dca; }
  #ecommerce .blue-bg {
    background-color: #2b6dca; }
  @media (max-width: 767.99px) {
    #ecommerce .blue-text-xs {
      color: #2b6dca; } }

.custom-checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-size: 13px; }
  .custom-checkbox label a {
    text-decoration: underline; }
  .custom-checkbox label:before {
    content: "";
    border-radius: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    border: 2px solid #969696;
    top: 50%;
    margin-top: -8px; }

.custom-checkbox input[type=checkbox] {
  display: none; }
  .custom-checkbox input[type=checkbox]:checked + label:before {
    content: "\2713";
    font-size: 30px;
    color: #2b6dca;
    text-indent: -3px;
    line-height: 7px; }
  .custom-checkbox input[type=checkbox]:disabled {
    background: #efefef; }

.custom-checkbox.has-error label {
  color: #FC214E; }

.custom-checkbox.has-error .custom-error-msg {
  display: block !important; }

#ecommerce.landing-tarjeta #ecommerce-header {
  min-height: calc(100vh - 150px); }
  @media (min-width: 992px) {
    #ecommerce.landing-tarjeta #ecommerce-header {
      min-height: calc(100vh - 475px); } }
  #ecommerce.landing-tarjeta #ecommerce-header h1 {
    padding-right: 0;
    padding-left: 0; }
    #ecommerce.landing-tarjeta #ecommerce-header h1:after {
      z-index: -1; }

#ecommerce.landing-tarjeta .tarjeta-form {
  margin: auto;
  max-width: 500px; }

#ecommerce.landing-tarjeta .btn[type="submit"] {
  font-size: 2.2rem;
  padding-right: 1rem; }

.app-landing-wrapper {
  min-height: calc(100vh - 150px); }
  @media (min-width: 992px) {
    .app-landing-wrapper {
      min-height: calc(100vh - 475px); } }

h1.app-landing-title {
  letter-spacing: 0;
  line-height: 1.12; }
  @media (max-width: 767.99px) {
    h1.app-landing-title {
      font-size: 3.8em; } }

.rayito {
  fill: #2b6dca;
  position: absolute;
  top: 76px;
  left: 256px;
  width: 40px; }

.app-source-logo {
  width: 140px; }

.list-wrapper {
  background-color: #969696;
  color: #fff; }
  @media (min-width: 768px) {
    .list-wrapper {
      background-color: transparent;
      color: #969696; } }
  .list-wrapper li {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    position: relative; }
    .list-wrapper li .icon {
      position: absolute;
      top: 0.85rem;
      left: 0;
      height: 1.2rem; }
  .list-wrapper h4 {
    padding-left: 1.5rem;
    letter-spacing: 0; }
  .list-wrapper p {
    margin: 0; }
  @media (min-width: 768px) {
    .list-wrapper li {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
      .list-wrapper li .icon {
        top: 0.7rem;
        height: 0.8rem; }
    .list-wrapper h4 {
      display: none; }
    .list-wrapper p {
      padding-left: 1.2rem; } }

.app-desktop-image {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 36vw; }

.app-wave-me {
  background: url("../../img/app/wave.png") 100% 90% no-repeat; }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Allstar';
  src: url("../../fonts/master/Allstar.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'BALLW___';
  src: url("../../fonts/master/BALLW___.TTF"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'FV_Almelo';
  src: url("../../fonts/master/FV_Almelo.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Cairo';
  src: url("../../fonts/master/Cairo.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'college';
  src: url("../../fonts/master/college.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Freshman';
  src: url("../../fonts/master/Freshman.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Gladifil';
  src: url("../../fonts/master/Gladifil.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'GOODGIRL';
  src: url("../../fonts/master/GOODGIRL.TTF"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'HirukoBlackAlternate';
  src: url("../../fonts/master/HirukoBlackAlternate.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Hipsteria';
  src: url("../../fonts/master/Hipsteria.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Holtwood';
  src: url("../../fonts/master/Holtwood.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'LAWSON';
  src: url("../../fonts/master/LAWSON.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'MARIDAVID';
  src: url("../../fonts/master/MARIDAVID.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Molot';
  src: url("../../fonts/master/Molot.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Oce';
  src: url("../../fonts/master/oce.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'PRICEDOW';
  src: url("../../fonts/master/PRICEDOW.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Roboto-Black';
  src: url("../../fonts/master/Roboto-Black.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Roboto-Regular';
  src: url("../../fonts/master/Roboto-Regular.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'SimpleLife';
  src: url("../../fonts/master/SimpleLife.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'Snickles';
  src: url("../../fonts/master/Snickles.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'typwrng';
  src: url("../../fonts/master/typwrng.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'vexler-slip';
  src: url("../../fonts/master/vexler-slip.ttf"); }

@font-face {
  unicode-range: U+0020-007F;
  font-family: 'typewriter';
  src: url("../../fonts/master/typewriter.ttf"); }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

#preloader {
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 58px);
  position: absolute;
  z-index: 99999;
  background: white; }
  @media (min-width: 992px) {
    #preloader {
      height: 700px;
      min-height: 0;
      max-height: calc(100vh - 70px); } }
  #preloader #status {
    width: 150px;
    height: 200px;
    background-image: url("../../img/master/loading.gif");
    background-size: 150px 150px;
    background-repeat: no-repeat;
    margin: -75px 0 0 -75px;
    position: absolute;
    top: 50%;
    left: 50%; }
    #preloader #status em {
      top: 150px;
      font-size: 1em !important;
      display: block;
      position: absolute;
      text-align: center;
      width: 100%; }

#msg-wrapper {
  position: fixed;
  z-index: 99999999;
  top: 75px;
  width: 100%;
  display: none; }
  #msg-wrapper .msg {
    float: right;
    padding: 2px 10px;
    color: #fff;
    margin-right: 5px;
    font-weight: bold; }
    #msg-wrapper .msg.green {
      background: #25D366; }
    #msg-wrapper .msg.red {
      background: #FC214E; }

.modal-master-bg {
  position: absolute;
  z-index: 99999999;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: none; }
  .modal-master-bg .master-modal {
    position: absolute;
    top: 50%;
    padding: 10px;
    left: 50%;
    margin-left: -170px;
    width: 340px;
    margin-top: -60px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid #ccc; }
    .modal-master-bg .master-modal .modal-buttons {
      text-align: center; }
      .modal-master-bg .master-modal .modal-buttons a {
        font-size: 1.2em;
        margin: 0;
        padding: 5px 10px; }

#reset-confirm-wrapper h4 {
  font: 1.3em;
  margin-bottom: 1em;
  text-align: center; }

#share-box-wrapper #share-box {
  border: none;
  width: 320px;
  margin-left: -160px;
  position: relative;
  padding-top: 10px; }
  #share-box-wrapper #share-box .close.abs {
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid black;
    color: black;
    padding: 0 5px;
    cursor: pointer; }
  #share-box-wrapper #share-box .copy-wrapper {
    clear: both;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 20px; }
    #share-box-wrapper #share-box .copy-wrapper .url-box {
      padding: 5px;
      background: #fff;
      border: 1px solid black;
      -moz-user-select: text;
      -khtml-user-select: text;
      -webkit-user-select: text;
      cursor: text;
      user-select: text;
      font-size: 0.9em;
      text-align: left;
      float: left;
      width: 268px; }
    #share-box-wrapper #share-box .copy-wrapper a.copy {
      display: block;
      float: left;
      width: 30px;
      margin: 0;
      background: #2B6DCA;
      color: #FFF;
      text-align: center;
      height: 24px;
      line-height: 24px;
      font-size: 17px;
      cursor: pointer; }
  #share-box-wrapper #share-box .dialog {
    color: #000;
    background: url("../../img/master/dialog.png") no-repeat;
    text-align: center;
    width: 239px;
    font-size: 1em;
    line-height: 1.1em;
    height: 60px;
    margin: 0;
    padding-top: 4px; }
  #share-box-wrapper #share-box .new-lb-button {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    background: #D54AB7;
    color: #fff;
    display: inline-block; }
    #share-box-wrapper #share-box .new-lb-button.blue {
      background: #2B6DCA; }
  #share-box-wrapper #share-box .close {
    color: #999;
    text-decoration: underline;
    display: inline-block; }

#loading-wrapper {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none; }
  #loading-wrapper #builder-loading {
    position: absolute;
    top: 50%;
    margin-top: -80px;
    color: #fff;
    text-align: center;
    height: 120px;
    padding: 20px;
    font-weight: bold;
    width: 100%;
    font-size: 1.5em;
    line-height: 2em; }
    #loading-wrapper #builder-loading i {
      display: block;
      font-size: 2em;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      /* IE 9 */
      -webkit-animation: spin 1s infinite linear;
      -moz-animation: spin 1s infinite linear;
      -o-animation: spin 1s infinite linear;
      animation: spin 1s infinite linear; }

#desktop-builder-menu {
  display: block;
  float: left;
  width: 50%;
  height: 100%; }

#build-wrapper {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 58px); }
  #build-wrapper #ghost-menu {
    display: none; }
  @media (min-width: 992px) {
    #build-wrapper {
      height: 700px;
      min-height: 0;
      max-height: calc(100vh - 70px); } }
  #build-wrapper.wizard {
    width: 50%;
    float: left; }
    #build-wrapper.wizard #canvas-wrapper #help-wrapper, #build-wrapper.wizard #canvas-wrapper .total-price-wrapper {
      display: none !important; }
  #build-wrapper #canvas-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible; }
    #build-wrapper #canvas-wrapper #help-wrapper {
      position: absolute;
      z-index: 9999999;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7); }
      #build-wrapper #canvas-wrapper #help-wrapper button {
        width: 130px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -65px;
        margin-top: -40px;
        font-size: 2em;
        text-align: center;
        cursor: pointer;
        z-index: 3; }
      #build-wrapper #canvas-wrapper #help-wrapper .help {
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;
        width: 100%;
        height: 100%; }
        #build-wrapper #canvas-wrapper #help-wrapper .help img {
          position: absolute;
          display: block;
          width: 60%;
          max-width: 400px; }
          #build-wrapper #canvas-wrapper #help-wrapper .help img.h01 {
            bottom: 80px !important;
            right: 33px !important; }
          #build-wrapper #canvas-wrapper #help-wrapper .help img.h02 {
            bottom: 80px !important;
            left: 44px !important; }
          #build-wrapper #canvas-wrapper #help-wrapper .help img.h03 {
            top: 44px !important;
            left: 90px !important; }
    #build-wrapper #canvas-wrapper #fullprint-assets {
      display: none; }
    #build-wrapper #canvas-wrapper #tomerge-assets {
      display: none; }
    #build-wrapper #canvas-wrapper #icons-wrapper {
      display: none; }
    #build-wrapper #canvas-wrapper > canvas, #build-wrapper #canvas-wrapper > img {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1; }
      #build-wrapper #canvas-wrapper > canvas.back, #build-wrapper #canvas-wrapper > img.back {
        display: none !important; }
    #build-wrapper #canvas-wrapper .overlay {
      z-index: 98;
      margin-left: 10%; }
    #build-wrapper #canvas-wrapper .toggle-view {
      z-index: 999;
      position: absolute;
      top: 20px;
      left: 20px;
      width: 61px;
      height: 61px;
      cursor: pointer;
      background: url("../../img/master/switch.png") 0 0 no-repeat;
      transition: 0.6s;
      transform-style: preserve-3d; }
      #build-wrapper #canvas-wrapper .toggle-view.toggle {
        background: url("../../img/master/switch2.png") 0 0 no-repeat;
        transform: rotateY(360deg); }
    #build-wrapper #canvas-wrapper .reset {
      z-index: 999999;
      position: absolute;
      bottom: 20px;
      left: 34px;
      font-size: 4em;
      color: #ccc;
      cursor: pointer; }
    #build-wrapper #canvas-wrapper .help-trigger {
      z-index: 9998;
      position: absolute;
      top: 34px;
      right: 24px;
      font-size: 4em;
      color: #ccc;
      cursor: pointer; }
      @media (min-width: 767px) {
        #build-wrapper #canvas-wrapper .help-trigger {
          display: none; } }
    #build-wrapper #canvas-wrapper #price-detail {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      display: none;
      z-index: 9999;
      position: absolute;
      bottom: 13px;
      width: 300px;
      margin-left: -151px;
      background: #fff;
      padding: 10px;
      position: absolute;
      left: 50%;
      border: 1px solid #ccc; }
      @media (min-width: 767px) {
        #build-wrapper #canvas-wrapper #price-detail {
          bottom: inherit;
          top: 18px;
          left: inherit;
          right: 20px;
          margin-left: 0; } }
      #build-wrapper #canvas-wrapper #price-detail span.close {
        font-size: 1.5em;
        color: #aaa;
        position: absolute;
        z-index: 2;
        top: 11px;
        right: 10px;
        cursor: pointer; }
      #build-wrapper #canvas-wrapper #price-detail table {
        text-shadow: none;
        width: 100%; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr.odd td {
          background: #f1f1f1; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr.subtotal td:last-child {
          background: #ccc; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr.off td {
          background: #FCEE21;
          font-weight: bold;
          border-top-color: #FCEE21;
          border-bottom-color: #FCEE21; }
          #build-wrapper #canvas-wrapper #price-detail table tbody tr.off td:first-child {
            border-right-color: #FCEE21; }
          #build-wrapper #canvas-wrapper #price-detail table tbody tr.off td:last-child {
            border-left-color: #FCEE21; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr.odd td {
          background: #f1f1f1; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr td, #build-wrapper #canvas-wrapper #price-detail table tbody tr th {
          padding: 5px; }
        #build-wrapper #canvas-wrapper #price-detail table tbody tr td.blue {
          background-color: #ff0099;
          border-color: #ff0099;
          color: white;
          font-weight: bold; }
      #build-wrapper #canvas-wrapper #price-detail .button-lb {
        font-size: 1.2em;
        display: block;
        width: 170px;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: center; }
      #build-wrapper #canvas-wrapper #price-detail small {
        display: block;
        line-height: 1em; }
    #build-wrapper #canvas-wrapper .total-price-wrapper {
      z-index: 9999;
      position: absolute;
      bottom: 17px;
      left: 50%;
      width: 200px;
      margin-left: -100px;
      height: 90px;
      position: absolute; }
      #build-wrapper #canvas-wrapper .total-price-wrapper .fa-angle-down {
        display: none; }
      @media (min-width: 767px) {
        #build-wrapper #canvas-wrapper .total-price-wrapper {
          bottom: inherit;
          top: 20px;
          left: inherit;
          right: 20px;
          margin-left: 0; }
          #build-wrapper #canvas-wrapper .total-price-wrapper .fa-angle-up {
            display: none; }
          #build-wrapper #canvas-wrapper .total-price-wrapper .fa-angle-down {
            display: inline; } }
      #build-wrapper #canvas-wrapper .total-price-wrapper .total-price {
        color: #fff;
        background: #2B6DCA;
        text-align: left;
        width: 100%;
        height: 60px;
        clear: both;
        zoom: 1;
        overflow: hidden;
        cursor: pointer; }
        #build-wrapper #canvas-wrapper .total-price-wrapper .total-price .price {
          line-height: 60px;
          font-size: 30px;
          font-weight: bold;
          width: 130px;
          padding: 0 10px;
          float: left; }
        #build-wrapper #canvas-wrapper .total-price-wrapper .total-price .open-detail {
          float: right;
          width: 50px;
          text-align: center;
          font-size: 30px;
          line-height: 60px;
          background: #ff0099; }
          #build-wrapper #canvas-wrapper .total-price-wrapper .total-price .open-detail .blink {
            animation: blinker 1s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0.0; } }
      #build-wrapper #canvas-wrapper .total-price-wrapper .price-msg {
        width: 100%;
        cursor: pointer;
        height: 30px;
        text-align: center;
        background: #FCEE21;
        color: black;
        line-height: 30px;
        font-weight: bold;
        text-transform: uppercase;
        animation: blinker 1s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0.7; } }
    #build-wrapper #canvas-wrapper .upload-wrapper {
      z-index: 9999;
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 150px;
      height: 32px; }
      #build-wrapper #canvas-wrapper .upload-wrapper button {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        cursor: pointer;
        font-size: 1em; }
        #build-wrapper #canvas-wrapper .upload-wrapper button:hover {
          color: #ff0099 !important; }
      #build-wrapper #canvas-wrapper .upload-wrapper input {
        filter: alpha(opacity=0);
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 2; }
  #build-wrapper .disabled {
    color: #999; }
  #build-wrapper #menu-partes {
    position: absolute;
    z-index: 9999; }

#builder-menu-wrapper {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: none;
  position: absolute;
  overflow: auto;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  z-index: 9999999;
  background: rgba(0, 102, 204, 0.8); }
  #builder-menu-wrapper #builder-menu {
    display: block; }
  #builder-menu-wrapper.wizard {
    width: 50%;
    left: 50%;
    z-index: 9;
    display: block !important;
    background: url("../../img/master/wizardbg.png"); }

#builder-menu {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: none;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  z-index: 3;
  padding-top: 15px;
  padding: 20px;
  width: 100%; }
  #builder-menu .step {
    display: none; }
    @media (min-width: 767px) {
      #builder-menu .step {
        width: 500px;
        margin: 0 auto; } }
    #builder-menu .step .opt-wrapper {
      overflow: visible; }
  #builder-menu .step-header {
    display: none; }
  #builder-menu.wizard {
    padding: 0; }
    #builder-menu.wizard span.close {
      display: none; }
    #builder-menu.wizard .step-header {
      display: block;
      width: 100%;
      padding: 10px;
      background: #efefef;
      font-size: 1.2em;
      color: #4D4D4D;
      cursor: pointer !important;
      border-bottom: 1px solid #B0B0B0;
      font-family: 'Roboto'; }
      #builder-menu.wizard .step-header i {
        margin-right: 5px; }
    #builder-menu.wizard .step.builder, #builder-menu.wizard .step.colors, #builder-menu.wizard .step.sas, #builder-menu.wizard .step.prints-list {
      display: none;
      padding: 10px;
      margin: 0;
      clear: both;
      zoom: 1;
      overflow: hidden;
      width: 100%; }
      #builder-menu.wizard .step.builder .opt-wrapper, #builder-menu.wizard .step.colors .opt-wrapper, #builder-menu.wizard .step.sas .opt-wrapper, #builder-menu.wizard .step.prints-list .opt-wrapper {
        float: left;
        border: 1px solid #B0B0B0;
        margin: 0 5px 5px 0;
        padding: 0 10px; }
        #builder-menu.wizard .step.builder .opt-wrapper h3, #builder-menu.wizard .step.colors .opt-wrapper h3, #builder-menu.wizard .step.sas .opt-wrapper h3, #builder-menu.wizard .step.prints-list .opt-wrapper h3 {
          font-family: 'Roboto';
          font-weight: normal;
          text-transform: none;
          font-size: 1.3em;
          text-align: center;
          color: #4D4D4D; }
        #builder-menu.wizard .step.builder .opt-wrapper .structure-modifier, #builder-menu.wizard .step.colors .opt-wrapper .structure-modifier, #builder-menu.wizard .step.sas .opt-wrapper .structure-modifier, #builder-menu.wizard .step.prints-list .opt-wrapper .structure-modifier {
          text-align: center;
          margin-left: 2px;
          margin-right: 2px;
          border-color: #B0B0B0; }
          #builder-menu.wizard .step.builder .opt-wrapper .structure-modifier.active, #builder-menu.wizard .step.colors .opt-wrapper .structure-modifier.active, #builder-menu.wizard .step.sas .opt-wrapper .structure-modifier.active, #builder-menu.wizard .step.prints-list .opt-wrapper .structure-modifier.active {
            border-color: #ff0099; }
          #builder-menu.wizard .step.builder .opt-wrapper .structure-modifier.active.extra, #builder-menu.wizard .step.builder .opt-wrapper .structure-modifier.active.sa, #builder-menu.wizard .step.colors .opt-wrapper .structure-modifier.active.extra, #builder-menu.wizard .step.colors .opt-wrapper .structure-modifier.active.sa, #builder-menu.wizard .step.sas .opt-wrapper .structure-modifier.active.extra, #builder-menu.wizard .step.sas .opt-wrapper .structure-modifier.active.sa, #builder-menu.wizard .step.prints-list .opt-wrapper .structure-modifier.active.extra, #builder-menu.wizard .step.prints-list .opt-wrapper .structure-modifier.active.sa {
            border-color: #D54AB7; }
          #builder-menu.wizard .step.builder .opt-wrapper .structure-modifier span, #builder-menu.wizard .step.colors .opt-wrapper .structure-modifier span, #builder-menu.wizard .step.sas .opt-wrapper .structure-modifier span, #builder-menu.wizard .step.prints-list .opt-wrapper .structure-modifier span {
            color: #4D4D4D;
            font-family: 'Roboto';
            font-weight: normal;
            text-transform: none; }
      #builder-menu.wizard .step.builder.color-wrapper h3, #builder-menu.wizard .step.colors.color-wrapper h3, #builder-menu.wizard .step.sas.color-wrapper h3, #builder-menu.wizard .step.prints-list.color-wrapper h3 {
        font-family: 'Roboto';
        font-weight: normal;
        text-transform: none;
        font-size: 1.3em;
        color: #4D4D4D;
        margin-bottom: 0; }
      #builder-menu.wizard .step.builder .dash, #builder-menu.wizard .step.colors .dash, #builder-menu.wizard .step.sas .dash, #builder-menu.wizard .step.prints-list .dash {
        font-size: 1.2em;
        cursor: pointer;
        line-height: 1.8em;
        position: static; }
        #builder-menu.wizard .step.builder .dash.active, #builder-menu.wizard .step.colors .dash.active, #builder-menu.wizard .step.sas .dash.active, #builder-menu.wizard .step.prints-list .dash.active {
          color: #D54AB7;
          font-weight: bold; }
        #builder-menu.wizard .step.builder .dash:hover, #builder-menu.wizard .step.colors .dash:hover, #builder-menu.wizard .step.sas .dash:hover, #builder-menu.wizard .step.prints-list .dash:hover {
          color: #000; }
    #builder-menu.wizard h3 {
      color: black; }
    #builder-menu.wizard .options .structure-modifier span {
      color: black; }
    #builder-menu.wizard #color-lists-wrapper #color-lists-menu h4 {
      color: black; }
    #builder-menu.wizard #bye-wizard {
      display: none;
      position: static; }
      #builder-menu.wizard #bye-wizard .wizard-controls {
        width: 100%;
        padding: 10px; }
        #builder-menu.wizard #bye-wizard .wizard-controls .control {
          font-size: 1.4em;
          font-weight: lighter;
          color: #4D4D4D;
          cursor: pointer;
          display: inline-block;
          padding-top: 14px; }
          #builder-menu.wizard #bye-wizard .wizard-controls .control.reset {
            margin-right: 10px; }
          #builder-menu.wizard #bye-wizard .wizard-controls .control.list {
            display: inline-block !important; }
      #builder-menu.wizard #bye-wizard .wizard-step {
        text-align: center;
        display: none; }
        #builder-menu.wizard #bye-wizard .wizard-step h3 {
          font-family: 'Roboto';
          text-transform: none;
          color: #4D4D4D; }
        #builder-menu.wizard #bye-wizard .wizard-step p {
          font-size: 1.4em;
          color: #4D4D4D;
          line-height: 1.3em;
          max-width: 500px;
          margin: 0 auto;
          margin-bottom: 20px; }
        #builder-menu.wizard #bye-wizard .wizard-step .colorpicker {
          color: #4D4D4D !important; }
        #builder-menu.wizard #bye-wizard .wizard-step .font-wrapper .font {
          color: #4D4D4D !important; }
        #builder-menu.wizard #bye-wizard .wizard-step .choices {
          margin-top: 30px; }
          @media (min-width: 767px) {
            #builder-menu.wizard #bye-wizard .wizard-step .choices {
              width: 600px;
              margin: 0 auto; } }
          #builder-menu.wizard #bye-wizard .wizard-step .choices .choice, #builder-menu.wizard #bye-wizard .wizard-step .choices .option.img {
            cursor: pointer;
            list-style-type: none;
            display: inline-block;
            width: 72px;
            height: 72px;
            margin: 0 15px 40px 15px;
            border-radius: 72px;
            position: relative;
            overflow: visible;
            border: 2px solid #B0B0B0; }
            #builder-menu.wizard #bye-wizard .wizard-step .choices .choice.hidden, #builder-menu.wizard #bye-wizard .wizard-step .choices .option.img.hidden {
              opacity: 0.3;
              cursor: default; }
            #builder-menu.wizard #bye-wizard .wizard-step .choices .choice img, #builder-menu.wizard #bye-wizard .wizard-step .choices .option.img img {
              width: 68px;
              height: 68px;
              border-radius: 68px;
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              border: none; }
            #builder-menu.wizard #bye-wizard .wizard-step .choices .choice span, #builder-menu.wizard #bye-wizard .wizard-step .choices .option.img span {
              display: block;
              width: 100%;
              text-align: center;
              font-weight: bold;
              color: #4D4D4D;
              text-transform: uppercase;
              position: absolute;
              top: 77px;
              font-size: 1em;
              line-height: 1em; }
          #builder-menu.wizard #bye-wizard .wizard-step .choices .option:not(.img) {
            position: relative;
            font-size: 1.2em;
            padding: 10px 0;
            color: #4D4D4D;
            line-height: 1em;
            font-weight: bold;
            width: 80%;
            text-align: left;
            padding-left: 23px;
            cursor: pointer; }
            #builder-menu.wizard #bye-wizard .wizard-step .choices .option:not(.img) span {
              display: inline-block;
              width: 13px;
              height: 13px;
              border: 1px solid #4D4D4D;
              position: absolute;
              top: 10px;
              left: 0; }
            #builder-menu.wizard #bye-wizard .wizard-step .choices .option:not(.img).active span {
              background: #ff0099; }
  #builder-menu span.close {
    font-size: 2em;
    font-weight: lighter;
    color: white;
    position: fixed;
    z-index: 2;
    top: 29px;
    right: 20px;
    cursor: pointer; }
  #builder-menu h3 {
    font-family: 'Lilita One';
    font-size: 2em;
    line-height: 1em;
    margin-top: 10px;
    color: white;
    font-weight: normal;
    text-transform: uppercase; }
  #builder-menu select {
    font-size: 1.2em;
    background: #fff;
    margin-bottom: 30px;
    padding: 10px;
    color: #4b4b4b;
    border: 2px solid #dedede;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    appearance: menulist-button; }
  #builder-menu .options {
    width: 100%; }
    #builder-menu .options .structure-modifier {
      cursor: pointer;
      list-style-type: none;
      display: inline-block;
      width: 60px;
      height: 60px;
      margin: 0 15px 30px 0;
      border-radius: 60px;
      position: relative;
      overflow: visible;
      border: 2px solid white; }
      #builder-menu .options .structure-modifier.active {
        border-color: #ff0099; }
      #builder-menu .options .structure-modifier.active.extra, #builder-menu .options .structure-modifier.active.sa {
        border-color: #FCEE21; }
      #builder-menu .options .structure-modifier img, #builder-menu .options .structure-modifier .noicon {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        position: absolute;
        z-index: 2;
        border: none; }
      #builder-menu .options .structure-modifier .noicon {
        border-radius: 60px; }
      #builder-menu .options .structure-modifier span {
        display: block;
        width: 62px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        position: absolute;
        top: 65px;
        left: -3px;
        font-size: 1em;
        line-height: 1em; }
  #builder-menu #color-lists-wrapper {
    position: relative; }
    #builder-menu #color-lists-wrapper #color-lists-menu {
      margin-bottom: 20px; }
      #builder-menu #color-lists-wrapper #color-lists-menu h4 {
        font-family: 'Source Sans Pro';
        color: white;
        text-transform: uppercase;
        font-size: 1.3em;
        line-height: 1.2em;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;
        padding: 5px;
        border: 1px solid white;
        margin-bottom: 5px; }
        #builder-menu #color-lists-wrapper #color-lists-menu h4.active {
          color: #ff0099;
          border: none;
          background-color: #fff; }
    #builder-menu #color-lists-wrapper .color-list {
      margin-bottom: 30px;
      display: none;
      line-height: 0.8em;
      clear: both;
      zoom: 1;
      overflow: hidden; }
      #builder-menu #color-lists-wrapper .color-list.active {
        display: block; }
      #builder-menu #color-lists-wrapper .color-list .color {
        width: 36px;
        height: 36px;
        display: block;
        float: left;
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: 1px solid #979797; }
        #builder-menu #color-lists-wrapper .color-list .color.selected {
          border: 2px solid #fff; }
        #builder-menu #color-lists-wrapper .color-list .color img {
          width: 100%;
          height: 100%; }
        #builder-menu #color-lists-wrapper .color-list .color.print {
          border: none;
          margin: 1px;
          width: 34px;
          height: 34px; }
          #builder-menu #color-lists-wrapper .color-list .color.print.selected {
            border: 2px solid #fff;
            margin: 0;
            width: 36px;
            height: 36px; }
        #builder-menu #color-lists-wrapper .color-list .color.progress {
          background: #979797;
          border: none; }
          #builder-menu #color-lists-wrapper .color-list .color.progress .bar {
            width: 0%;
            background-color: #ff0099;
            height: 100%; }
          #builder-menu #color-lists-wrapper .color-list .color.progress.hide {
            display: none; }
        #builder-menu #color-lists-wrapper .color-list .color.upload-button {
          width: 108px;
          background: #ff0099 url("../../img/master/upload.png") right 4px no-repeat;
          border: none;
          text-align: left;
          color: #fff;
          font-family: 'Lilita One';
          font-size: 1.7em;
          padding: 3px 10px;
          line-height: 32px;
          text-transform: uppercase; }
      #builder-menu #color-lists-wrapper .color-list h4.unmerged {
        margin: 10px 0;
        line-height: 1.8em;
        margin-left: 12px;
        font-size: 2em;
        font-weight: bold; }
  #builder-menu #bye-wizard {
    position: relative;
    width: 100%;
    height: 100%; }
    #builder-menu #bye-wizard .wizard-controls {
      width: 100%;
      height: 50px; }
      #builder-menu #bye-wizard .wizard-controls .control {
        font-size: 1.4em;
        font-weight: lighter;
        color: white;
        cursor: pointer;
        display: inline-block;
        padding-top: 14px; }
        #builder-menu #bye-wizard .wizard-controls .control.reset {
          margin-right: 10px; }
        #builder-menu #bye-wizard .wizard-controls .control.list {
          display: none; }
    #builder-menu #bye-wizard .wizard-step {
      text-align: center;
      display: none; }
      #builder-menu #bye-wizard .wizard-step p {
        font-size: 1.4em;
        color: #fff;
        line-height: 1.3em;
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 20px; }
      #builder-menu #bye-wizard .wizard-step .choices {
        margin-top: 30px; }
        @media (min-width: 767px) {
          #builder-menu #bye-wizard .wizard-step .choices {
            width: 600px;
            margin: 0 auto; } }
        #builder-menu #bye-wizard .wizard-step .choices .choice, #builder-menu #bye-wizard .wizard-step .choices .option.img {
          cursor: pointer;
          list-style-type: none;
          display: inline-block;
          width: 72px;
          height: 72px;
          margin: 0 15px 40px 15px;
          border-radius: 72px;
          position: relative;
          overflow: visible;
          border: 2px solid white; }
          #builder-menu #bye-wizard .wizard-step .choices .choice.hidden, #builder-menu #bye-wizard .wizard-step .choices .option.img.hidden {
            opacity: 0.3;
            cursor: default; }
          #builder-menu #bye-wizard .wizard-step .choices .choice img, #builder-menu #bye-wizard .wizard-step .choices .option.img img {
            width: 68px;
            height: 68px;
            top: 0;
            left: 0;
            border-radius: 68px;
            position: absolute;
            z-index: 2;
            border: none; }
          #builder-menu #bye-wizard .wizard-step .choices .choice span, #builder-menu #bye-wizard .wizard-step .choices .option.img span {
            display: block;
            width: 100%;
            text-align: center;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            position: absolute;
            top: 77px;
            font-size: 1em;
            line-height: 1em; }
        #builder-menu #bye-wizard .wizard-step .choices .option:not(.img) {
          font-size: 1.2em;
          padding: 10px 0;
          color: #fff;
          line-height: 1em;
          position: relative;
          font-weight: bold;
          width: 80%;
          text-align: left;
          padding-left: 23px;
          cursor: pointer; }
          #builder-menu #bye-wizard .wizard-step .choices .option:not(.img) span {
            display: inline-block;
            width: 13px;
            height: 13px;
            border: 1px solid white;
            position: absolute;
            top: 10px;
            left: 0; }
          #builder-menu #bye-wizard .wizard-step .choices .option:not(.img).active span {
            background: #ff0099; }

#font-modal-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  padding-top: 90px;
  width: 100%;
  min-height: 100%; }
  #font-modal-wrapper span.close {
    font-size: 2em;
    font-weight: lighter;
    color: white;
    position: fixed;
    z-index: 2;
    top: 28px;
    right: 20px;
    cursor: pointer; }
  #font-modal-wrapper #font-modal {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    color: white; }
    #font-modal-wrapper #font-modal .font {
      font-size: 2em;
      line-height: 2em;
      display: none;
      margin-right: 30px;
      cursor: pointer; }
      #font-modal-wrapper #font-modal .font:hover {
        color: #ff0099; }

#color-modal-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  padding-top: 80px;
  width: 100%;
  min-height: 100%; }
  #color-modal-wrapper span.close {
    font-size: 2em;
    font-weight: lighter;
    color: white;
    position: fixed;
    z-index: 2;
    top: 28px;
    right: 20px;
    cursor: pointer; }
  #color-modal-wrapper #color-modal {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    line-height: 0.8em;
    color: white; }
    #color-modal-wrapper #color-modal h3 {
      color: white;
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 30px;
      text-transform: uppercase; }
    #color-modal-wrapper #color-modal p {
      line-height: 1em; }
    #color-modal-wrapper #color-modal .color {
      width: 36px;
      height: 36px;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      padding: 0;
      border: 1px solid #979797; }
      #color-modal-wrapper #color-modal .color.selected {
        border: 2px solid #fff; }
      #color-modal-wrapper #color-modal .color img {
        width: 100%;
        height: 100%; }
      #color-modal-wrapper #color-modal .color.print {
        border: none;
        margin: 1px;
        width: 34px;
        height: 34px; }
        #color-modal-wrapper #color-modal .color.print.selected {
          border: 2px solid #fff;
          margin: 0;
          width: 36px;
          height: 36px; }

.bye-tool .button-lb {
  cursor: pointer;
  font-size: 2em;
  display: block;
  margin: 0 auto; }

.bye-tool .color-wrapper {
  line-height: 36px;
  height: 36px;
  margin-bottom: 20px; }
  .bye-tool .color-wrapper .label {
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    line-height: 34px;
    text-transform: uppercase; }
  .bye-tool .color-wrapper .color {
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: white; }
    .bye-tool .color-wrapper .color img {
      width: 100%;
      height: 100%; }
  .bye-tool .color-wrapper.c1 .color {
    background: white; }
  .bye-tool .color-wrapper.c2 .color {
    background: black; }
  .bye-tool .color-wrapper .colorpicker {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 5px;
    font-size: 20px;
    text-decoration: none; }

.bye-tool .preview-wrapper {
  width: 220px;
  height: 220px;
  line-height: 220px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 20px; }
  .bye-tool .preview-wrapper .preview {
    font-size: 4em;
    white-space: pre;
    display: inline-block;
    text-align: center;
    line-height: 1.4em; }

.bye-tool#text-tool .preview-wrapper {
  width: auto;
  height: auto;
  line-height: 36px;
  display: inline-block; }
  .bye-tool#text-tool .preview-wrapper .preview {
    vertical-align: middle;
    display: inline;
    padding: 10px; }

#text-tool {
  color: white;
  font-size: 1em; }
  #text-tool h3.title {
    margin-bottom: 10px; }
  #text-tool label {
    display: block;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 5px; }
  #text-tool input {
    padding: 10px;
    font-size: 1em;
    margin-bottom: 2px;
    width: 300px;
    margin: 0 auto; }
  #text-tool textarea {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
    padding: 10px;
    min-width: 200px;
    font-size: 16px; }
  #text-tool small {
    display: block;
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 20px; }
    #text-tool small.example {
      margin-bottom: 10px; }
    #text-tool small.count {
      margin-top: -25px;
      font-style: normal;
      font-weight: bold; }
  #text-tool select {
    display: block;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    appearance: menulist-button; }
  #text-tool .font-wrapper .label {
    display: inline-block;
    font-weight: bold; }
  #text-tool .font-wrapper .font {
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    text-decoration: none; }
  #text-tool .font-wrapper .fontpicker {
    display: inline-block;
    text-decoration: underline;
    color: #ff0099; }

.prints-wrapper {
  position: relative;
  margin: 0 auto;
  z-index: 999; }
  .prints-wrapper .wizard-trigger.dot {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    background: #2B6DCA;
    border: 2px solid white;
    color: #fff;
    border-radius: 50%;
    z-index: 999;
    text-align: center;
    font-weight: bold; }
    .prints-wrapper .wizard-trigger.dot.back {
      display: none; }
    .prints-wrapper .wizard-trigger.dot.active {
      background: #ff0099;
      opacity: 0.2; }
    .prints-wrapper .wizard-trigger.dot:hover {
      opacity: 0.7 !important; }

.mdb-wrapper:not(.desktop) {
  z-index: 10000;
  pointer-events: none;
  width: 100%;
  height: 80px;
  position: absolute;
  right: 0;
  bottom: 0; }
  .mdb-wrapper:not(.desktop).wizard {
    display: none; }
  .mdb-wrapper:not(.desktop) * {
    pointer-events: default; }
  .mdb-wrapper:not(.desktop).mdb-show {
    height: 100%; }
    .mdb-wrapper:not(.desktop).mdb-show .mdb-menu-wrapper {
      display: block; }
      .mdb-wrapper:not(.desktop).mdb-show .mdb-menu-wrapper .mdb-btn {
        margin-left: 0; }
  .mdb-wrapper:not(.desktop) .mdb-menu-wrapper {
    display: none;
    right: 0;
    bottom: 75px;
    position: absolute; }
    .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn {
      margin-left: 100%;
      position: relative;
      padding-left: 300px; }
      .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn button {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #FFF;
        margin-bottom: 12px;
        margin-right: 26px;
        padding: 0;
        border: none;
        outline: none;
        color: #2b2b2b;
        font-size: 19px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        transition: .3s;
        pointer-events: all; }
        .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn button:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn button:focus {
          background: white; }
      .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 button, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 label {
        background-color: #2B6DCA;
        color: #fff; }
        .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 button:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 button:focus, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 label:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-4 label:focus {
          background: #79a5e2; }
      .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 button, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 label {
        background-color: #ff0099;
        color: #fff; }
        .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 button:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 button:focus, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 label:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-5 label:focus {
          background: #ff66c2; }
      .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 button, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 label {
        background-color: #25D366;
        color: #fff; }
        .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 button:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 button:focus, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 label:hover, .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn.mdb-btn-6 label:focus {
          background: #77e7a1; }
      .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn label {
        position: absolute;
        right: 70px;
        top: 8px;
        font-family: 'Lilita One';
        background: #F2F2F2;
        color: #2b2b2b;
        padding: 4px;
        font-family: Lilita One;
        text-transform: uppercase;
        font-size: 18px;
        pointer-events: all;
        cursor: pointer; }
        .mdb-wrapper:not(.desktop) .mdb-menu-wrapper .mdb-btn label:hover {
          background: #bfbfbf; }
  .mdb-wrapper:not(.desktop) button.mdb-btn-trigger {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #ff0099;
    right: 16px;
    bottom: 16px;
    position: absolute;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 36px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: .3s;
    -webkit-tap-highlight-color: transparent;
    pointer-events: all; }
    .mdb-wrapper:not(.desktop) button.mdb-btn-trigger:hover, .mdb-wrapper:not(.desktop) button.mdb-btn-trigger:focus {
      background: #ff66c2; }
    .mdb-wrapper:not(.desktop) button.mdb-btn-trigger span {
      display: inline-block;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: all 250ms ease; }
    .mdb-wrapper:not(.desktop) button.mdb-btn-trigger.active span {
      -ms-transform: rotate(45deg) !important;
      -webkit-transform: rotate(45deg) !important;
      transform: rotate(45deg) !important;
      transition: all 250ms ease; }

.mdb-wrapper.desktop {
  padding: 10px; }
  .mdb-wrapper.desktop .mdb-btn {
    display: inline-block;
    line-height: 2em;
    font-size: 1.6em;
    margin-right: 20px;
    cursor: pointer;
    background: #ff0099;
    padding: 0 10px;
    border: none;
    outline: none;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: .3s;
    pointer-events: all; }
    .mdb-wrapper.desktop .mdb-btn.mdb-btn-3 {
      background: #2B6DCA; }
      .mdb-wrapper.desktop .mdb-btn.mdb-btn-3:hover, .mdb-wrapper.desktop .mdb-btn.mdb-btn-3:focus {
        background: #79a5e2; }
    .mdb-wrapper.desktop .mdb-btn:hover, .mdb-wrapper.desktop .mdb-btn:focus {
      background: #ff66c2; }

#reset-confirm-wrapper h4 {
  font: 1.3em;
  margin-bottom: 1em;
  text-align: center; }

.button.button-lb {
  font-family: 'Lilita One';
  font-size: 1em;
  line-height: 1em;
  padding: 10px;
  text-transform: uppercase; }
  .button.button-lb i {
    font-size: 0.8em;
    margin-right: 15px; }
  .button.button-lb.pink {
    background-color: #ff0099;
    border-bottom: solid 4px #A01167;
    border-right: solid 4px #c23389;
    color: white; }
    .button.button-lb.pink:hover {
      background-color: #ff11aa; }
  .button.button-lb.yellow {
    background-color: #FCEE21;
    border-bottom: solid 4px #CDBC1F;
    border-right: solid 4px #efde41;
    color: white; }
    .button.button-lb.yellow:hover {
      background-color: #ffff32; }
  .button.button-lb.green {
    background-color: #25D366;
    border-bottom: solid 4px #42B838;
    border-right: solid 4px #64da5a;
    color: white; }
    .button.button-lb.green:hover {
      background-color: #36e477; }
  .button.button-lb.blue {
    background-color: #2B6DCA;
    border-bottom: solid 4px #0858A8;
    border-right: solid 4px #2a7aca;
    color: white; }
    .button.button-lb.blue:hover {
      background-color: #3c7edb; }
  .button.button-lb.darkblue {
    background-color: #032F70;
    border-bottom: solid 4px #071935;
    border-right: solid 4px #293b57;
    color: white; }
    .button.button-lb.darkblue:hover {
      background-color: #144081; }
  .button.button-lb.gray {
    background-color: #B0B0B0;
    border-bottom: solid 4px #4D4D4D;
    border-right: solid 4px #6f6f6f;
    color: white; }
    .button.button-lb.gray:hover {
      background-color: #c1c1c1; }
    .button.button-lb.gray.active {
      background-color: #ff0099;
      border-bottom: solid 4px #A01167;
      border-right: solid 4px #c23389;
      color: white; }
      .button.button-lb.gray.active:hover {
        background-color: #ff11aa; }
  .button.button-lb.white {
    background-color: white;
    border-bottom: solid 4px white;
    border-right: solid 4px white;
    color: #7b7b7b; }
    .button.button-lb.white:hover {
      background-color: white;
      color: #999 !important; }
  .button.button-lb.red {
    background-color: #FF3636;
    border-bottom: solid 4px #D81A1A;
    border-right: solid 4px #fa3c3c;
    color: white; }
    .button.button-lb.red:hover {
      background-color: #ff4747; }

.hide {
  visibility: hidden;
  display: none; }

.sizepicker .button-lb {
  display: inline-block !important;
  margin: 0 5px 10px !important; }
