@mixin bg-boxes($color, $bg-color, $width: 3px, $space: 3px) {
    border: $width solid $color;
    position: relative;
    background: $bg-color;
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: $space;
        // left: -9px;
        left: -$width * 2 - $space;
        box-sizing: content-box;
        border: $width solid $color;
        content: '';
        background: $bg-color;
        z-index: -1;
    }
    &:after {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: $space * 2 + $width;
        // left: -15px;
        left: -$width * 3 - $space * 2 ;
        box-sizing: content-box;
        border: $width solid $color;
        content: '';
        background: $bg-color;
        z-index: -2;
    }
}


#product-info-wrapper {
    background: $white;
    border: 2px solid $light-gray;
    text-align: left;
    max-width: 400px;
    margin: auto;
    @media (min-width: 992px) {
        margin-top: 0;
    }
    .product-info-price {
        font-weight: bold;
        small, &.discount {
            font-weight: lighter;
        }
        .full-price {
            font-weight: lighter !important;
        }
        .off {
            background: $magenta;
            color: $white;
            margin-left: 5px;
            margin-top: 2px;
            font-size: 0.8em;
            padding: 0 2px;
        }
    }
    #product-info-header {
        text-transform: uppercase;
        background: $magenta;
        color: $white;
        font-weight: bold;
        text-align: center;
        font-size: 1.3em;
        padding: 5px;
        @media (min-width: 768px) {
            font-size: 1.5em;
        }
        * { vertical-align: middle; }
        img { margin-right: 15px; }
    }
    .product-info-price {
        padding: 10px;
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        @include media-breakpoint-up(sm) { padding: 10px 15px; }
        &.total {
            border-top: 2px solid $magenta;
            .price {
                font-weight: lighter;
            }
        }
    }
    .cursor-pointer { cursor: pointer; }
    @media (max-width: 767px) {
        .xs-inline-block { display: inline-block; }
        .xs-block { display: block; }
    }
    #product-info-tyc {
        border-top: 1px solid $light-gray;
        padding: 15px;
        .checkbox {
            max-width: 300px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    #product-info-btn {
        text-align: center;
        .btn {
            font-size: 3em;
            margin-bottom: 15px;
            padding: 2px 15px;
        }
    }
}

#ecommerce {
    color: $dark-gray;
    .btn.gray-bg { background: #c3c3c3; }
    .btn.btn-lb {
        font-family: $steelfish;
        color: $white;
        text-transform: uppercase;
        font-style: italic;
        letter-spacing: 0;
        box-shadow: -3px 3px 1px rgba($black,.3);
        line-height: 1.43;
    }
    .hidden { display: none !important; }
    .phone-text {
        color: #00CC66;
        background: $white;
        padding: 10px;
        max-width: 550px;
        margin: auto;
        img {
            max-width: 40px;
        }
    }
    #ecommerce-header {
        background-color: $light-gray;
        text-align: center;
        position: relative;
        z-index: 1;
        padding-top: 45px;
        padding-bottom: 10px;
        @include media-breakpoint-down(md) {
            padding-bottom: 0;
            .container-large { max-width: none; }
            .make-me-white {
                background: $white;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        h1 {
            position: relative;
            padding: 5px 25px;
            font-size: 3.5em;
            display: inline-block;
            margin-bottom: 20px;
            font-style: italic;
            color: $blue;
            letter-spacing: 0;
            &:after {
                content: '';
                position: absolute;
                background: url('../../img/purchase/waves-big.png') 0 0 no-repeat;
                background-size: cover;
                width: 132px;
                height: 44px;
                left: -120px;
                top: 9px;
                @include media-breakpoint-up(lg) {
                    top: 0;
                    width: 142px;
                    height: 64px;
                }
            }
            @media (min-width: 992px) {
                font-size: 4.5em;
            }
            @media (min-width: 1200px) {
                font-size: 5em;
            }
        }
        .info-header {
            text-transform: uppercase;
            font-weight: bold;
            @media (max-width: 767px) {
                .block-xs { display: block; }
            }
            @media (min-width: 992px) {
                font-size: 1.3em;
            }
            .discount {
                background: $white;
                color: $blue;
                padding: 0 2px;
            }
        }
        #bg-liquid-header {
            background: url('../../img/purchase/bg-liquid-pink-line.png') left bottom no-repeat,
                        url('../../img/purchase/bg-liquid-blue-small.png') right bottom no-repeat;
            position: absolute;
            bottom: -46px;
            right: 60px;
            width: 226px;
            height: 68px;
        }
    }
    #ecommerce-content {
        padding-top: 50px;
        .product-img-wrapper {
            border: 2px solid $light-gray;
            background: $white;
            margin-bottom: 5px;
            img {
                margin: 0 auto;
            }
            #ecommerce-product-img-loader { display: none; }
            #ecommerce-product-img {
                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
        h2.product-title {
            margin-top: 0;
        }
        #ecommerce-product-select-wrapper {
            .bootstrap-select {
                width: 250px;
                max-width: 100%;
                button.dropdown-toggle {
                    border-radius: 0;
                    border: 3px solid $magenta;
                    color: $magenta;
                    text-transform: uppercase;
                    font-family: $steelfish;
                    font-size: 2em;
                    padding: 0;
                    &:focus,
                    &:hover {
                        outline: none !important;
                        background: $light-gray;
                    }
                    .filter-option {
                        padding: 0 12px;
                    }
                    .bs-caret {
                        width: 40px;
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: $magenta;
                        color: $white;
                        .caret {
                            right: 13px;
                            border-top: 7px dashed;
                            border-top: 7px solid \9;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                        }
                    }
                }
                &.dropup {
                    button.dropdown-toggle {
                        .bs-caret {
                            .caret {
                                border-top: 0;
                                border-bottom: 7px dashed;
                                border-bottom: 7px solid \9;
                            }
                        }
                    }
                }
            }
        }
        #ecommerce-product-description { margin-top: 15px; }
        .can-do {
            position: relative;
            text-transform: uppercase;
            font-family: $steelfish;
            font-size: 1.8em;
            @media (min-width: 768px) {
                max-width: 333px;
                padding-left: 28px;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 14px;
                    top: 0;
                    bottom: 0;
                    background: url('../../img/purchase/changes-arrow.png') center center no-repeat;
                    background-size: contain;
                }
            }
        }
        #go-to-sheet {
            font-size: 0.9em;
            text-decoration: underline;
        }
    }
    #ecommerce-faqs {
        .ecommerce-faq {
            padding: 40px 0 15px;
            .faq-angle-icon { display: none; }
        }
        .gray-bg { background: $light-gray; }
        .white-text { color: $white; }
        h2 {
            text-align: center;
            font-style: italic;
            text-decoration: underline;
            font-size: 2.9em;
            @media (min-width: 768px) {
                font-size: 4.5em;
            }
        }
        h3 {
            display: inline-block;
            font-size: 2.2em;
            letter-spacing: 0;
            @media (min-width: 768px) {
                font-size: 3em;
            }
            .number {
                font-size: 1.5em;
            }
        }
        @media (max-width: 767px) {
            .blue-text-xs { color: $blue !important; }
        }
        .answer {
            &.big-answer {
                position: relative;
                font-size: 1.8em;
                font-style: italic;
                font-family: $steelfish;
                text-transform: uppercase;
                line-height: 1.3em;
                margin-bottom: 10px;
                @media (max-width: 767px) {
                    background: none;
                }
                @media (min-width: 768px) {
                    margin-left: 10px;
                    display: inline-block;
                    font-size: 3em;
                    padding: 0 60px 0 5px;
                    margin-bottom: 0;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        right: -50px;
                        height: 100px;
                        width: 100px;
                        background: url('../../img/purchase/time.png') bottom left no-repeat;
                    }
                    &.warranty:after {
                        background-image: url('../../img/purchase/warranty.png');
                        height: 106px;
                        width: 106px;
                    }
                    &.account:after { background-image: url('../../img/purchase/blueaccount.png'); }
                    &.process:after { background-image: url('../../img/purchase/process.png'); }
                }
            }
            &.small-answer {
                &.margined {
                    @media (min-width: 768px) {
                        margin-left: 76px;
                    }
                }
            }
        }
        #process-timeline {
            .timeline-step {
                position: relative;
                display: flex;
                align-items: center;
                .process-img-wrapper {
                    max-width: 50%;
                    float: left;
                    padding: 10px 15px 10px 0;
                    img {
                        margin: 0 0 0 auto;
                    }
                }
                .step-txt {
                    display: block;
                    width: 50%;
                    float: left;
                    font-size: 0.85em;
                    font-weight: bold;
                    text-align: center;
                    text-transform: uppercase;
                    padding-left: 15px;
                    text-align: left;
                    max-width: 200px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    width: 2px;
                    left: 80px;
                    margin-left: -1px;
                    top: 0;
                    bottom: 0;
                    background: $blue;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 3px solid $blue;
                    top: 50%;
                    left: 80px;
                    margin-top: -7px;
                    margin-left: -7px;
                    background: $white;
                }
                &:first-child {
                    &:before {
                        top: 50%;
                    }
                    &:after {
                        border: 2px solid $magenta;
                        width: 16px;
                        height: 16px;
                        margin-left: -8px;
                        margin-top: -8px;
                    }
                }
                &:last-child {
                    &:before {
                        bottom: 50%;
                    }
                    &:after {
                        width: 18px;
                        height: 18px;
                        margin-left: -9px;
                        margin-top: -9px;
                        background: $magenta;
                    }
                }
            }
        }
        @media (min-width: 768px) {
            #process-timeline {
                display: flex;
                .timeline-step {
                    display: block;
                    width: 100%;
                    .process-img-wrapper {
                        max-width: none;
                        width: auto;
                        float: none;
                        padding: 0;
                        margin-bottom: 40px;
                        height: 69px;
                        img {
                            margin-right: auto;
                        }
                    }
                    .step-txt {
                        padding: 0;
                        width: auto;
                        max-width: 100%;
                        float: none;
                        text-align: center;
                    }
                    &:before {
                        height: 3px;
                        width: auto;
                        top: 88px;
                        left: 0;
                        right: 0;
                        bottom: auto;
                    }
                    &:after {
                        top: 89px;
                        left: 50%;
                    }
                    &:first-child:before {
                        left: 50%;
                        top: 88px;
                    }
                    &:last-child {
                        &:before {
                            right: 50%;
                        }
                    }
                }
            }
        }
        .xs-faq-collapsible {
            @media (max-width: 767px) {
                padding-top: 20px;
                background: none;
                border-bottom: 2px solid $light-gray;
                h3 {
                    margin: 0;
                    font-size: 2em;
                    @include media-breakpoint-down(xs) {
                        font-size: 1.6em;
                    }
                    .number {
                        font-size: 1em;
                    }
                }
                .faq-angle-icon {
                    display: block;
                    font-size: 2em;
                    transition: transform 0.4s ease;
                    @media (min-width: 768px) {
                        font-size: 3em;
                    }
                }
                .collapsed {
                    .faq-angle-icon {
                        transform: scaleY(-1);
                    }
                }
                .answer {
                    &.small-answer {
                        @include media-breakpoint-up(md) {
                            &.margined { margin-left: 53px; }
                        }
                        .margin-giver {
                            height: 15px;
                        }
                    }
                }
            }
            @media (min-width: 768px) {
                .collapse,
                .collapsing {
                    display: block !important;
                    height: auto !important;
                }
                [role="button"] {
                    cursor: auto;
                }
            }
        }
        .faq-collapsible {
            border-bottom: 2px solid $light-gray;
            padding-top: 20px;
            h3 {
                margin: 0;
                @media (max-width: 767px) {
                    font-size: 2em;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 1.6em;
                }
            }
            .faq-angle-icon {
                display: block;
                font-size: 2em;
                transition: transform 0.4s ease;
                @media (min-width: 768px) {
                    font-size: 3em;
                }
            }
            .collapsed {
                .faq-angle-icon {
                    transform: scaleY(-1);
                }
            }
            .answer {
                &.small-answer {
                    @include media-breakpoint-up(md) {
                        &.margined { margin-left: 53px; }
                    }
                    .margin-giver {
                        height: 15px;
                    }
                }
            }
        }
    }
    #reservation-modal {
        .modal-content {
            border-radius: 0;
            .modal-header {
                background: $light-gray;
                padding-top: 10px;
                padding-bottom: 10px;
                border: none;
                .close {
                    margin-top: 13px;
                    opacity: 1;
                    color: $gray;
                }
            }
            .modal-body {
                label {
                    font-weight: normal;
                    text-align: left;
                    color: $blue;
                }
                .mt5 { margin-top: 5px; }
                .payment-methods-title {
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .mp-img-wrapper {
                    margin-top: 15px;
                }
            }
            .modal-footer {
                text-align: center;
                border: none;
                .btn {
                    font-size: 2em;
                    padding: 2px 15px;
                }
            }
        }
    }
    .custom-error-msg {
        display: none;
        margin-top: 10px;
        background: rgba(252, 33, 78, 0.2);
        border: 1px solid $red;
        padding: 5px;
        text-align: center;
        color: $red;
        a {
            color: $red;
            text-decoration: underline;
        }
    }
    #mp-loader {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        line-height: 100vh;
        background: rgba($black, 0.5);
        z-index: 999999;
        font-size: 4em;
        color: $dark-gray;
    }
 
    .pink-text { color: $magenta; }
    .pink-bg { background-color: $magenta; }
    .blue-text { color: $blue; }
    .blue-bg { background-color: $blue; }
    @include media-breakpoint-down(sm) {
        .blue-text-xs { color: $blue; }
    }
}
.custom-checkbox {
    label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        margin-right: 15px;
        font-size: 13px;
        a { text-decoration: underline; }
        &:before {
            content: "";
            border-radius: 3px;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            position: absolute;
            left: 0;
            bottom: 1px;
            border: 2px solid $gray;
            top: 50%;
            margin-top: -8px
        }
    }
    input[type=checkbox] {
        display: none;
        &:checked + label:before {
            content: "\2713";
            font-size: 30px;
            color: $blue;
            text-indent: -3px;
            line-height: 7px;
        }
        &:disabled {
            background: $light-gray;
        }
    }
    &.has-error {
        label {
            color: $red;
        }
        .custom-error-msg {
            display: block !important;
        }
    }
}