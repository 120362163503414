.mcb-page {
    position: relative;
}

.mcb-page-content {
    position: relative;
    z-index: 2;
}

.mcb-bgs-wrapper {
    position: relative;
}

.mcb-bg {
    background: url('../../img/animated-crosses.gif') center center no-repeat;
    background-size: contain;
    width: 163px;
    height: 85px;
    position: absolute;
    z-index: 1;

    &.bg-01 {
        top: 10px;
        left: 0;
        @include media-breakpoint-up(md) { top: 10%; }
    }
    &.bg-02 {
        top: 45%;
        left: 0;
        transform: rotate(90deg);
    }
    &.bg-03 {
        bottom: 10px;
        left: 15%;
        transform: rotate(180deg);
        @include media-breakpoint-up(md) { bottom: 10%; }
    }
    &.bg-04 {
        top: 10px;
        right: 10%;
        transform: rotate(180deg);
        @include media-breakpoint-up(lg) { top: 10%; }
    }
    &.bg-05 {
        bottom: 20%;
        right: 0;
    }
}

.mcb-page-icon {
    max-width: 140px;

    @include media-breakpoint-up(md) { max-width: 245px; }
}

.mcb-subtitle {
    max-width: 450px;
    margin: auto;
}

.mcb-page-btn {
    width: 50px;
    height: 50px;

    .icon {
        width: 43%;
        transform: translateX(20%);
    }
}