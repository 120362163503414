.hero {
    background-position: center center;
    background-size: cover;

    @for $i from 1 through 3 {
        &.hero-img-0#{$i} {
            background-image: url('../../img/hero/littleblue-buzos-0#{$i}-sm.jpg');

            @include media-breakpoint-up(md) {
                background-image: url('../../img/hero/littleblue-buzos-0#{$i}.jpg');
            }
        }
    }

    .hero-row { min-height: 30vw; }

    h1 {
        margin: 0;
        padding: 4px;
        font-size: 7.3vw;
        animation: heroFadeLeft 1.5s ease 3s;
        animation-fill-mode: forwards;

        @media (pointer: fine) {
            animation: none;

            &.fadeout { animation: heroFadeLeft 1.5s ease forwards; }
        }

        @include media-breakpoint-up(sm) {
            font-size: 5.3vw;
        }

        @include media-breakpoint-up(md) {
            font-size: 3.5em;
        }

        @include media-breakpoint-up(lg) {
            font-size: 4.4em;
        }
    }

    .btn {
        padding: 0;
        width: 150px;
        max-width: 100%;
        text-shadow: 1px -1px 10px black;

        @include media-breakpoint-up(md) { width: 160px; }

        .icon-wrapper {
            transition: background 0.4s ease;
        }

        &:hover {
            .icon-wrapper {
                background: $dark-gray !important;
            }
        }

        @include media-breakpoint-up(lg) {
            width: auto;
            background: $black;
            padding: 0.7rem 0.9rem;
            font-size: 1.65rem;

            * {
                vertical-align: middle;
                text-shadow: none;
                box-shadow: none;
            }

            .icon-wrapper {
                display: inline-block !important;
                font-size: 1em;
                width: auto;
                height: auto;
                margin: 0 !important;

                .icon {
                    display: inline-block;
                    width: auto;
                    height: 1em;
                    margin-right: 5px;
                }
            }

            .icon-wrapper,
            &:hover .icon-wrapper { background: none !important; }

            .btn-text {
                background: none !important;
            }

            &:hover {
                background: $dark-gray;
            }
        }
    }
}