.modal-carousel {
    .modal-dialog {
        max-width: none;
        margin: 0;
        height: 100%;
        overflow-y: scroll;
    }

    .modal-content,
    .modal-body { height: 100%; }
    .modal-content { pointer-events: none; }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 85px;
        height: 85px;
        line-height: 85px;
        text-align: center;
        pointer-events: auto;
        text-shadow: 0 1px 0 $black;
        z-index: 4;
        color: $white;
        font-size: 3rem;
        opacity: 1;
    }

    .carousel {
        height: 100%;
        display: flex;

        [class^="carousel-control"],
        img,
        .carousel-indicators,
        .carousel-item-content { pointer-events: auto; }
    }

    .carousel-indicators {
        background: linear-gradient(to top, rgba($black, 0.65), transparent);
        bottom: 0;

        li { cursor: pointer; }
    }

    .carousel-inner {
        width: 720px;
        max-width: 88vh;
        height: calc(100vh - 3.5rem);
        margin: auto;
    }

    [class^="carousel-control"]:not(.carousel-control-icon) {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: rgba($black, 0.4);
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;

        @include media-breakpoint-up(sm) {
            width: 50px;
            height: 50px;
        }

        @include media-breakpoint-up(lg) {
            width: 75px;
            height: 75px;
        }
        // max-height: calc(100vh - 3.5rem);

        svg {
            width: 28%;
        }
    }
    .carousel-control-prev { left: 10px; }
    .carousel-control-next {
        right: 10px;

        svg { transform: rotate(180deg); }
    }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev { display: flex; }

    .carousel-item {
        height: 100%;

        .mh-100,
        img {
            max-height: 100%;
        }
    }
}