.press-articles {
    h2 {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        max-width: 17rem;
        line-height: 1.3;

        @include media-breakpoint-up(lg) {
            top: 2.5rem;
            left: 2.5rem;
            font-size: 6rem;
            max-width: 30rem;
        }
    }
}

.press-article {
    .description { font-size: 0.9rem; }
    .press-article-bg { background: $light-gray; }

    .btn {
        width: 30px;
        height: 30px;
        font-size: 1.4rem;
        text-align: center;
        padding: 0;
        line-height: 1;

        span {
            display: block;
            transition: transform 0.4s ease;
        }
    }

    @media (pointer: fine) {
        &:hover .btn span { transform: rotate(90deg); }
    }
}

.press-sidebar {
    @include media-breakpoint-up(md) {
        .press-article {
            border-bottom: 1px solid $gray;
            margin-bottom: 0;

            .press-article-bg { background: $white; }
        }
    }
}