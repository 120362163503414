.form-message-wrapper {
    color: $white;
    font-weight: 700;

    .modal-body {
        padding: 30px 10px;

        p:last-child { margin-bottom: 0; }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 6px 11px;
        color: inherit;
        opacity: 1;
    }
}

input.form-control,
select.form-control:not([size]):not([multiple]) {
    height: 48px;
    padding: 0 15px;

    &.form-control-sm { height: 28px; }
}

textarea.form-control {
    padding: 15px;
    resize: none;
}

.submit-ok[type="submit"] {
    box-shadow: -4px -2px 2px rgba($dark-gray, 0.3);
    width: 80px;
    height: 80px;

    @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
    }

    @include media-breakpoint-up(lg) {
        width: auto;
        height: auto;
        border-radius: 0 !important;
        font-size: 2rem;

        * { vertical-align: middle; }
    }

    svg {
        position: absolute;
        width: 50%;
        top: 50%;
        left: calc(50% + 3px);
        transform: translate(-50%, -50%);
    }

    &.spin-me {
        @include media-breakpoint-down(md) {
            background-color: transparent !important;
        }

        svg { display: none;}
        .lb-spinner { display: inline-block; }
    }
}