@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.products-grid {
    .products-text {
        font-family: $headings-font-family;
        font-size: 2rem;
        text-transform: uppercase;
    }

    .h100 {
        display: flex;
        min-height: calc(100vh - 210px);

        @include media-breakpoint-up(lg) {
            min-height: calc(100vh - 460px);
        }
    }

    .products-loading {
        position: relative;

        .loading-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            animation: fadeIn 3s ease;
            animation-fill-mode: backwards;

            @for $i from 2 through 16 {

                &:nth-child(#{$i}) {
                    animation-delay: 3s * ($i - 1);
                }
            }

            &:last-child { animation-fill-mode: forwards; }
        }
    }

    .items-wrapper { padding: 5px; }

    .products-item {
        position: relative;
        background-image: url('../../img/spinner.gif');
        background-position: center center;
        background-size: 50%;
        background-repeat: no-repeat;
        overflow: hidden;
        flex: 1 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex: 1 1 50%;
        }
        margin: 5px;

        @for $i from 2 through 16 {
            @media (min-width: 250px * $i) {
                flex: 1 1 calc(#{100% / $i} - 10px);
                max-width: calc(#{100% / $i} - 10px);
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: 1 1 #{100% / $i};
                }
            }
        }

        &:after {
            content: '';
            display: block;
            padding-bottom: calc(100% + 30px);
        }

        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: auto;
            }

            &.taller {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .carousel-control-icon { width: 30%; }

        .carousel-control-prev {
            opacity: 1;
            background: linear-gradient(to right, rgba($black, 0.4), transparent);
        }

        .carousel-control-next {
            opacity: 1;
            background: linear-gradient(to left, rgba($black, 0.4), transparent);

            svg { transform: scaleX(-1); }
        }

        .carousel,
        .carousel-inner {
            display: block;
            position: static;
        }

        .more {
            display: none;
            position: absolute;
            width: 30px;
            height: 30px;
            bottom: 5px;
            right: 5px;
            background: $black;
            border-radius: 50%;
            box-shadow: -2px -2px 3px rgba($black, 0.3);
            z-index: 4;

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
                bottom: 20px;
                right: 20px;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: $white;
                transition: transform 0.4s ease;
            }

            &:before {
                width: 2px;
                height: 12px;

                @include media-breakpoint-up(md) {
                    height: 17px;
                }
            }

            &:after {
                width: 12px;
                height: 2px;

                @include media-breakpoint-up(md) {
                    width: 17px;
                }
            }
        }

        .products-item-fadein {
            opacity: 0;
            transition: opacity 0.4s ease;
        }

        &.loaded {
            .products-item-fadein { opacity: 1; }
            .more { display: block; }
        }

        @media (pointer: fine) {

            &:hover {

                .more {

                    &:before,
                    &:after {
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }
            }
        }
    }
}