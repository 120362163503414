.clients-top {

    .face-wrapper {
        display: inline-block;
        width: 100%;
        max-width: 242px;
        box-sizing: content-box;
    }

    .client-face {
        display: inline-block;
        width: 100%;
        background: url('../../img/contact/contact-face-yep.png') 0 center no-repeat;
        background-size: 200%;
        padding-top: 125%;

        &:hover {
            background-position: 100% center;
        }

        &.no {
            background: url('../../img/contact/contact-face-nope.png') 0 center no-repeat;
            background-size: 200%;

            &:hover {
                background-position: 100% center;
            }
        }
    }
}