.error-screen {
    display: flex;
    align-items: center;
    min-height: calc(100vh - #{map-get($navbar-height, xs) + 80});
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: block;
        min-height: calc(100vh - #{map-get($navbar-height, xs) + 230});
        text-align: left;
        background-position: right center;
        background-repeat: no-repeat;

        &.error-404 {
            background-image: url('../../img/errors/meme-03.png');
        }
        &.error-500 {
            background-image: url('../../img/errors/meme-02.png');
        }
        &.error-503 {
            background-image: url('../../img/errors/meme-01.png');
            background-position: right 20% bottom;
        }
    }

    h1 {
        font-size: 8rem;

        @include media-breakpoint-up(md) { font-size: 12.6rem; }

        span { vertical-align: middle; }
        .smaller {
            font-size: 0.4em;
            letter-spacing: 0.06em;
            margin-left: -0.3em;

            @include media-breakpoint-up(md) { margin-left: -0.72em; }
        }
    }

    h3 {
        font-size: 3.1rem;

        @include media-breakpoint-up(md) { font-size: 4.1em; }
    }

    .error-bg-white { background: rgba($white, 0.8); }
}