.sales-box {
    border: 3px solid currentColor;

    .icon {
        margin-top: -35px;
        max-width: 135px;
    }

    hr {
        width: 20%;
        height: 2px;
        background: currentColor;
    }
}

.sales-box-title {
    font-size: 2.4rem;
}

.sales-social {

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;

        @include media-breakpoint-up(lg) {
            width: 120px;
            height: 120px;
        }
    }

    .icon { height: 33.3%; }
}

.sales-social-text {
    @include media-breakpoint-between(sm, md) { font-size: 80%; }
}