.product-top {

    .col-image {
        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.has-gallery .img-wrapper { cursor: zoom-in; }

    .img-wrapper {
        position: relative;

        @include media-breakpoint-up(md) {
            padding-bottom: 100%;
        }

        img {
            max-height: calc(100vh - #{map-get($navbar-height, xs) + 120px});

            @media (orientation: landscape) {
                min-height: 200px;
            }

            @include media-breakpoint-up(md) {
                min-height: 0;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    [class^="carousel-control"].btn {
        background: rgba($black, 0.4);
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
        width: 50px;
        height: 50px;
        opacity: 1;
        margin: 0 15px;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28%;
        }

        &.btn-next svg { transform: translate(-50%, -50%) rotate(180deg); }
    }

    .thumbnails {
        top: 0;
        bottom: 0;
    }

    .thumbnail {
        width: 100px;
        height: 100px;
        margin: 0 0 17px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        &.active { border: 3px solid $magenta; }

        &:before,
        &:after {
            content: none;
        }

        @include media-breakpoint-up(xl) {
            width: 122px;
            height: 122px;
        }

        &:last-child { margin-bottom: 0; }
    }

    .more-items {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4rem;
        background: $magenta;
        color: $white;
        text-indent: 0;
    }

    h1 { font-size: 3rem; }

    .product-info {
        .desc-n-btn {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        p {
            font-weight: 400;

            &:last-child { margin-bottom: 0; }

            @include media-breakpoint-up(xl) {

                &:not(.small) { font-size: 1.15rem; }
            }
        }
    }
}